const styles = {
  grid: {
    display: "flex", 
    flexDirection: {xs: 'column', sm: 'row'}
  },

  gridBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
  },
  boxHero: {
    height: '100%',
    width: {sx: '100%', sm: '90%', md: '80%', lg: '80%', xl: '75%'},
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  box: {
    width: {sx: '100%', sm: '90%', md: '80%', lg: '80%', xl: '75%'},
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto'
  },

  titleText: {
    color: '#FFFFFF',
    letterSpacing: '0px',
    fontSize: 58,
    textAlign: 'left',
    lineHeight: 1.2,
    fontFamily: 'Geologica Bold',
    mx: {xs: 2, sm: 0}
  },

  subtitleText: {
    fontFamily: 'Geologica Regular !important',
    color: '#FFFFFF',
    fontSize: 22,
    letterSpacing: '0px',
    lineHeight: '30px',
    textAlign: 'left',
    marginTop: '25px',
    marginBottom: {xs: 3, md: 0},
    mx: {xs: 2, sm: 0}
  },

  img: {
    width: '50%',
    margin: '10px auto',
    justifyContent: 'center',
    display: 'flex'
  },

  boxMap: {
    width: {xs: '95%', sm: '85%', md: '88%', lg: '85%', xl: '75%'},
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: 'auto',
    marginLeft: {sx: 0, md: 2, lg: 10}
  },

  card: {
    alignItems: 'center',
    marginBottom: '.85rem',
    border: 2,
    borderColor: '#459FA7',
    padding: 0,
    boxShadow: 4,
    display: 'flex',
    borderRadius: '35px',
    width: '100%',
    minHeight: '100px',
  },

  cardText: {
    fontWeight: 'bold',
    fontSize: { xs: 22, md: 18 },
    textAlign: 'left',
    lineHeight: 1.2,
    width: '100%',
  },

  imageCard: {
    minHeight: 52, 
    minWidth: 52,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '1rem',
    marginRight: '.50rem'
  },

  cardContent: {
    padding: '5px',
    "&:last-child": {
      paddingBottom: '5px'
    }
  },

  boxHelper: {
    width: {xs: '95%', sm: '90%'},
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    mx: 'auto',
    mt: 0,
    mb: 2
  },

  helperCard: {
    alignItems: 'center',
    marginBottom: '.85rem',
    padding: 2,
    display: 'flex',
    borderRadius: '25px',
    width: '100%',
    minHeight: '100px',
    backgroundColor: 'rgb(192,228,217,0.21)',
    boxShadow: 0,
  },

  cardTextHelper: {
    display: 'flex',
    width: {xs: '80%', sm: '100%'},
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: '15px',
    borderStyle: 'dotted',
    corderColor: 'red',
    justifyContent: 'flex-end',
  },

  imageIcon: {
    minHeight: 54, 
    minWidth: 35
  },

  iconCard: {
    position: 'relative',
    top: {xs: '-47px', sm: '-130px'},
    left: '-290px',
    width: {xs: '90%', sm: '90%', md: '85%', lg: '75%'},
  },

  imgMap: {
    display: 'block',
    margin: 'auto',
  },

  cedulaTitle: {
    color: '#C0E4D9',
    letterSpacing: '0px',
    fontWeight: 'bold',
    fontSize: 48,
    textAlign: 'center',
    width: '100%',
    margin: 'auto'
  }, 

  containerVideo: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '400px',
    marginBottom: '50px',
  },

  imgVideo: {
    width: '100%',
    margin: 'auto',
  },

  hrStyle: {
    border: 0, 
    height: 0, 
    borderTop: '1px solid rgba(0, 0, 0, 0.1)', 
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
  },

  circle: {
    height: '8px', 
    width: '8px', 
    borderRadius: '50%',
    display: 'inline-block', 
    marginLeft: '5px'
  },

  circleWithIcon: {
    position: 'relative', 
    borderRadius: '50%', 
    width: '16px', 
    height: '16px', 
    display: 'inline-block', 
    marginRight: '10px'
  },

  iconSize: {
    width: '70%'
  }
}

export default styles;
