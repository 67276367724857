import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const CloseIconSelect = ({dataCondition, dataFuntion}) => {
    return (
        <IconButton 
            sx={{ 
                display: dataCondition ? "" : "none", 
                marginRight: 2,
                padding: .5
            }} 
            onClick={dataFuntion}
        >
            <CloseIcon fontSize="small" color={"inherit"} />
        </IconButton>
    )
}

export default CloseIconSelect;