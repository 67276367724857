const styles = {
    dialog: {
      width: {xs: '100%', sm: '80%'},
      textAlign: 'center',
      letterSpacing: '0px',
      fontFamily: 'Geologica Bold',
      fontSize: 25,
      color: '#1C5866', 
      lineHeight: '35px',
      marginTop: {xs: '3rem', sm: '1rem'},
      mx: {xs: 0, sm: 'auto'},
      px: {xs: 2, sm: 'auto'},
      '@media (minWidth: 600px)': {
        fontSize: {xs: 25, sm: 28},
      }
    },

    content: {
      py: 0,
      pl: {xs: 1, sm: 9},
      width: {xs: '100%', sm: '93%'},
      color: '#3B4348',
      fontSize: 14,
    },

    item: {
      fontFamily: 'Geologica Regular',
      textAlign: 'left',
      fontSize: 14,
      '@media (minWidth: 600px)': {
        fontSize: 14,
      }
    },

    lastItem: {
      fontFamily: 'Geologica Regular'
    },
  }
  
  export default styles;
  