import React from "react";

import ContextProvider from "./context.provider";
import ThemeProvider from "./theme.provider";
import RouterProvider from "./router.provider"
import AppProvider from "./app.provider"
import LProvider from "./localization.provider"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GlobalContextProvider } from 'Context/GlobalContext'
import { StatisticsPageContextProvider } from 'Context/StatisticsPageContext';
import { CedulasPageContextProvider } from "Context/CedulasPageContext";

import { Suspense } from "react";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default function Providers() {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
            <GlobalContextProvider>
            <StatisticsPageContextProvider>
            <CedulasPageContextProvider>
            <ContextProvider>
            <ThemeProvider>  
            <LProvider>
            <AppProvider>
                <RouterProvider />
            </AppProvider>
            </LProvider>    
            </ThemeProvider>
            </ContextProvider>
            </CedulasPageContextProvider>
            </StatisticsPageContextProvider>
            </GlobalContextProvider>
            </GoogleReCaptchaProvider>
        </Suspense>
    );
}