import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import BaseButton from '../../BaseButton';
import styles from './styles.js';
import { useTemplateContext } from 'Context/AppContext'

const ModalBienvenida = () => {
  const { viewModal, changeModal } = useTemplateContext()
  const [modalOpen, setModalOpen] = React.useState(viewModal);

  function handleClose() {
    setModalOpen(false);
    changeModal();
  }

  return (
    <>
      {viewModal && (
      <Dialog fullWidth maxWidth={'md'} open={modalOpen} onClose={handleClose} scroll='paper' PaperProps={{ sx: { borderRadius: "1.25rem"}}}>
        <DialogTitle sx={styles.dialog}>
          Te damos la bienvenida a la versión pública del Registro Estatal de Personas Desaparecidas
        </DialogTitle>
                <DialogContent sx={styles.content}>
                  <List sx={{color: '#3B4348', pl: 3}}>
                    <ListItemText>
                      <li style={styles.item}>Con fundamento en el numeral 3 del artículo 82 de la Ley de Personas Desaparecidas del Estado de Jalisco, y de conformidad con lo dispuesto en la Ley General de Víctimas, la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados, la Ley General de Transparencia y Acceso a la Información Pública, la Ley de Transparencia y Acceso a la Información Pública del Estado de Jalisco y sus Municipios, la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados del Estado de Jalisco, y los Principios Rectores para la Búsqueda de Personas Desaparecidas emitidos por el Comité de las Naciones Unidas contra la Desaparición Forzada, en este sitio puedes consultar la versión pública de la información que contiene el Registro Estatal de Personas Desaparecidas, a cargo de la Comisión de Búsqueda de Personas Desaparecidas del Estado de Jalisco.</li>
                    </ListItemText>
                    <ListItemText sx={{mb: 1, mt: 3, }}>
                      <li style={styles.item}><b>En este sitio encontrarás:</b></li>
                    </ListItemText>
                  </List>
                  <List sx={{ listStyleType: 'disc', color: '#3B4348', pl: 3}}>
                    <ListItemText><li style={styles.item}><b>Información estadística</b> sobre la desaparición y localización de personas en Jalisco.</li></ListItemText>
                    <ListItemText><li style={styles.item}>Las <b>cédulas de búsqueda</b> emitidas por la Comisión de Búsqueda de Personas del Estado de Jalisco.</li></ListItemText>
                    <ListItemText><li style={styles.item}><b>Documentos</b> básicos sobre la desaparición de personas.</li></ListItemText>
                    <ListItemText><li style={styles.item}><b>Un buzón</b> para enviar a las autoridades información sobre personas que han sido reportadas como desaparecidas.</li></ListItemText>
                  </List>
                  <List sx={{color: '#3B4348', pl: 3}}>
                    <ListItemText>
                      <li style={styles.item}><font style={{textDecoration: 'underline red', marginBottom: 10}} color="red">La información de este sitio se actualiza de manera mensual, los días 10 de cada mes.</font><b></b></li>
                      <li style={styles.item}>Al ingresar a este sitio, las personas usuarias aceptan que lo utilizarán para fines lícitos y con apego a las disposiciones legales aplicables, y reconocen estar formalmente informadas de que, en caso de hacer un uso no autorizado del mismo o dañar de alguna manera su operación, se aplicarán las sanciones contempladas en la legislación vigente aplicable.</li>
                    </ListItemText>
                  </List>
                </DialogContent>
                <DialogActions sx={{justifyContent: "center", mt: {xs: 1, sm: 2 }, mb: {xs: 4, sm: 3 }, px: {xs: 2, md: 0} }}>
                  <BaseButton onClick={handleClose}>{`Aceptar`}</BaseButton>
                </DialogActions>
      </Dialog>
      )}
    </>
  );
};

export default ModalBienvenida;