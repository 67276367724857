const styles = {
  grid: {
    display: "flex", 
    justifyContent: "center",
    flexDirection: {xs: 'column', sm: 'row'}
  },

  gridBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
  },
  boxHero: {
    height: '100%',
    width: {sx: '100%', sm: '90%', md: '80%', lg: '80%', xl: '75%'},
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  box: {
    width: {sx: '100%', sm: '90%', md: '80%', lg: '80%', xl: '75%'},
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto'
  },

  titleText: {
    color: '#FFFFFF',
    letterSpacing: '0px',
    fontSize: 58,
    lineHeight: 1.2,
    fontFamily: 'Geologica Bold',
    mx: {xs: 2, sm: 0}
  },

  subtitleText: {
    fontFamily: 'Geologica Regular !important',
    color: '#FFFFFF',
    fontSize: 22,
    letterSpacing: '0px',
    lineHeight: '30px',
    textAlign: 'left',
    marginTop: '25px',
    marginBottom: {xs: 3, md: 0},
    mx: {xs: 2, sm: 0}
  },

  img: {
    width: '50%',
    margin: '10px auto',
    justifyContent: 'center',
    display: 'flex'
  },
  cardContainer: {
    display: "flex", justifyContent: "center",
    flexWrap: {xs: "wrap", md: "nowrap"},
  },

  card: {
    alignItems: 'center',
    marginBottom: '.85rem',
    border: 2,
    borderColor: '#459FA7',
    padding: 0,
    boxShadow: 4,
    display: 'flex',
    borderRadius: '35px',
    width: {xs: '100%', md: '40%'},
    minHeight: '80px',
    marginInline: 1
  },

  cardText: {
    fontWeight: 'bold',
    fontSize: { xs: 22, md: 18 },
    lineHeight: 1.2,
    width: '100%',
  },

  imageCard: {
    minHeight: 52, 
    minWidth: 52,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '1rem',
    marginRight: '.50rem'
  }

}

export default styles;
