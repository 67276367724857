import styled from "@emotion/styled";
import Button from '@mui/material/Button';

const CssButton = styled(Button)(({ theme, minwidth }) => ({
  boxShadow: `0px 3px 6px #00000029`,
  borderRadius: `2rem`,
  opacity: 1,
  font: `normal normal bold 20px Poppins`,
  padding: `6px 10px`,
  minHeight: 50,
  minWidth: minwidth === null ? 142 : minwidth,
  textTransform: `none`,
  lineHeight: `16px`,
  margin: `3px`,

  backgroundColor: '#DE5E60',
  borderColor: '#FF0000',
  color: '#ffffff',

  [theme.breakpoints.down('sm')]: {
    minHeight: 68,
    minWidth: 250,
    font: `normal normal bold 24px Poppins`,

    "&:hover": {
      backgroundColor: '#d94345',
      borderColor: '#FF0000',
      boxShadow: `0px 3px 6px #00000029`,
    },
  },

  [theme.breakpoints.up('sm')]: {
    "&:hover": {
      backgroundColor: '#d94345',
      borderColor: '#FF0000',
      boxShadow: `0px 3px 6px #00000029`,
      minWidth: minwidth === null ? 150 : (minwidth += 5),
      minHeight: 55,
      margin: `0px 0px 1px 0px`,
      },
    },

  "&:active": {
    backgroundColor: '#d94345',
    borderColor: '#FF0000',
    boxShadow: `0px 3px 6px #00000029`,
  },
  "&:focus": {
    backgroundColor: '#d94345',
    borderColor: '#FF0000',
    boxShadow: `0px 3px 6px #00000029`,
  },
}));

const BaseButton = ({ children, startIcon, endIcon, onClick, loading, disabled, minwidth = null}) => {
  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <CssButton variant='contained' startIcon={startIcon} endIcon={endIcon} onClick={onClick} onMouseDown={handleMouseDown} loading={loading} disabled={disabled} minwidth={minwidth}>
      {children}
    </CssButton>
  );
};

export default BaseButton;
