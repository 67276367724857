import { api } from "./api";

export const postMailBox = (data) => {
    return api
      .post("/version_publica/repd-version-publica-buzon-ciudadano/", data )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(
          "Error: " + error.message
        );
      });
  };