// React
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Components
import NotFoundPage from 'Pages/NotFoundPage';
import ErrorPage from 'Pages/ErrorPage';
import Estadisticas from "Pages/Estadisticas";
import Cedula from "Pages/Cedula";
import About from "Pages/About"
import Home from "Pages/Home";
import Layout from "Pages/Layout";

// Routes
const routes = [
    {
        path: "/",
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Home />,
                errorElement: <ErrorPage />
            },
            {
                path: "/estadisticas",
                element: <Estadisticas />,
                errorElement: <ErrorPage />,
            },
            {
                path: "/cedulas-de-busqueda",
                element: <Cedula />,
                errorElement: <ErrorPage />,
            },
            {
                path: "/cedulas-de-busqueda/:id_cedula_busqueda",
                element: <Cedula />,
                errorElement: <ErrorPage />,
            },
            {
                path: "/conoce-mas",
                element: <About />,
                errorElement: <ErrorPage />,
            },
            {
                path: "*",
                element: <NotFoundPage />,
                errorElement: <ErrorPage />,
            }
        ]
    }
];

// Router
const router = createBrowserRouter(routes);

// Provider
const AppRouterProvider = () => {
    return <RouterProvider router={router}></RouterProvider>;
}

export default AppRouterProvider;
