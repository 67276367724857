import React, { createContext, useContext, useState } from 'react';
import { fetchStateMunicipalityWithCedula } from 'Services/apiCedulas';

const CedulasPageContext = createContext(null);

const CedulasPageContextProvider = ({ children }) => {  
    const [isLoadingCedulas, setLoadingCedulas] = useState({})  
    const [stateMunicipalityWithCedulas, setStateMunicipalityWithCedulas] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [selectedMunicipality, setSelectedMunicipality] = useState("");

    const perPage = 12;

    const statesWithoutNull = Object.keys(stateMunicipalityWithCedulas || {});
    const statesWithCedulas = statesWithoutNull.filter((estado) => estado !== "null");
    const selectedStateID = 
        selectedState 
            ? stateMunicipalityWithCedulas[selectedState].estado_id 
            : null;

    const municipalititesAbstract = stateMunicipalityWithCedulas[selectedState] || {};
    const municipalitiesWithoutNull = Object.keys(municipalititesAbstract.municipios || {});
    const municipalitiesWithCedulas = municipalitiesWithoutNull.filter((municipio) => municipio !== "null");
    const selectedMunicipalityID = 
        selectedMunicipality 
            ? municipalititesAbstract.municipios[selectedMunicipality]
            : null

    const handleLoadingCedulas = (seccion, state) => {
        setLoadingCedulas((prevFormData) => ({
            ...prevFormData, 
            ...{ [seccion]: state } 
        }))
    }

    const handleStateMunicipalityWithCedulas = () => {
        handleLoadingCedulas('state', true)
        fetchStateMunicipalityWithCedula()
        .then((states) => setStateMunicipalityWithCedulas(states.data))
        .catch((error) => console.error("fetchState: ", error))
        .finally(() => handleLoadingCedulas('state', false))
    }
    
    const handleSelectedState = (state) => setSelectedState(state)

    const handleSelectedMunicipality = (municipality) => setSelectedMunicipality(municipality)

    const cleanStateCedulasContext = () => {
        setStateMunicipalityWithCedulas([])
        setLoadingCedulas({})
        setSelectedState("")
        setSelectedMunicipality(null)
    }

    const forContextData = {
        cleanStateCedulasContext, perPage,
        isLoadingCedulas, handleLoadingCedulas,
        stateMunicipalityWithCedulas, handleStateMunicipalityWithCedulas,
        selectedStateID, statesWithCedulas, selectedState, handleSelectedState,
        selectedMunicipalityID, municipalitiesWithCedulas, selectedMunicipality, handleSelectedMunicipality,
    }

    return (
        <CedulasPageContext.Provider value={forContextData} children={children} />
    )

}

CedulasPageContext.displayName = 'CedulasPageContext';

const useCedulasContext = () => {
    const context = useContext(CedulasPageContext);
    if (!context) {
        throw new Error('useCedulasContext debe usarse dentro de un CedulasPageContextProvider');
    }
    return context;
};

export { CedulasPageContextProvider, CedulasPageContext, useCedulasContext };
