const styles = {
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mb: 10
    },
    box: {
        width: {sx: '100%', sm: '90%', md: '80%', lg: '80%', xl: '75%'},
        display: 'flex',
        flexWrap: 'wrap',
        margin: 'auto'
    },
    titleText: {
        width: {xs: '100%', md: '75%'},
        color: '#1C5866',
        textAlign: 'center',
        fontSize: 58,
        lineHeight: 1.2,
        fontFamily: 'Geologica Bold',
        mx: {xs: 2, md: 0},
        mb: {xs: 8, md: 4}  
    },
    subtitleText: {
        color: '#1C5866',
        mb: 4,
        mx: {xs: 2, sm: 0}
    },
    text: {
        fontFamily: 'Geologica Regular !important',
        fontSize: 22,
        letterSpacing: '0px',
        lineHeight: '30px',
        textAlign: 'left',
        marginBottom: {xs: 2, md: 0},
        mx: {xs: 2, sm: 0}
    },
    gridBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', 
    },
}

export default styles;