const styles = {

  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: '25px',
    px: {xs: '2rem', sm: '4.5rem'},
    py: {xs: '2rem', sm: '3rem'},
    width: '35rem',
    "@media (max-width: 767px)": {
      width: 'auto',
      margin: 'auto'
    },
    position: 'relative'
  },
  title: {
    color: '#1C5866',
    fontWeight: 'bold',
    fontSize: {xs: 40, sm: 43},
    letterSpacing: '0px',
    lineHeight: 1,
    marginBottom: '12px'
  },
  subTitle: {
    color: '#1C5866',
    fontWeight: 'bold',
    letterSpacing: '0px',
    fontSize: 16
  },
  text: {
    color: '#1C5866',
    fontWeight: 'regular',
    letterSpacing: '0px',
    fontFamily: 'Geologica Regular !important',
    marginTop: '10px',
    marginBottom: 3
  },
  input: {
    background: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    borderRadius: '32px !important',
    borderBottom: '0px',
    fontWeight: 'regular',
  },
  recaptchaContainer: {
    display: 'flex',
    justifyContainer: 'center'
},
  button: {
    borderRadius: 50, 
    paddingInline: '35px',
    margin: 'auto',
    mt: 4,
    display: 'flex',
    backgroundColor: '#DE5E60',
    textTransform: 'capitalize !important'
  },
  formControl: {
    width: '100%',
    border: '1px solid #EFEFEF',
    borderRadius: '32px !important',
    minWidth: 120,
    color: '#5A7C72', 
    fontFamily: 'Geologica Regular !important',
    ' & .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px !important'
    },
    '& .MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
      px: 1
    },
    mb: 1.5
  }
}

export default styles;
