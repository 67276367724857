const styles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    alertContainer: {
        top: '98px',
        left: '611px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        opacity: 1,
        backdropFilter: 'blur(10px)',
        borderRadius: '32px',
        padding: '100px 150px'
    },
    itemContainer: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'
    },
    text: {
        color: '#1C5866',
        fontWeight: 'regular',
        letterSpacing: '0px',
        fontFamily: 'Geologica Regular !important',
        marginTop: '10px',
        width: '350px',
        textAlign: 'center'
    },
    img: {
        margin: 'auto'
    }
}
export default styles;
