import React, { useEffect, useRef } from "react";
import { axisBottom, axisLeft, scaleBand, scaleLinear, select } from "d3";
import { Box, Typography } from "@mui/material";
import styles from "./styles";
import * as d3 from 'd3';
import { coloresGrafica } from "../../../utils/constants";
import { colorMaxGender } from "../../../utils/constants";

function AxisBottom({ scale, transform }) {
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            const xAxis = axisBottom(scale);
            xAxis.tickFormat((d) => {
                return d;
            });

            select(ref.current).call(xAxis);
        }
    }, [scale]);

    return <g ref={ref} transform={transform} style={{ fontSize: '13px', color: 'grey', display: 'block' }}/>;
}

function AxisLeft({ scale }) {
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            select(ref.current).call(axisLeft(scale));
        }
    }, [scale]);

    return <g ref={ref} />;
}

function Bars({ data, height, scaleX, scaleY, maxGender }) {

    return (
        <>
            {data.map(({ label, values}, i ) => (
                <g key={`bar-group-${i}`} className={`bar-group-${i}`}>
                    {values.map((value, index) => (
                        <rect
                            key={`bar-${label}-${index}`} id={index}
                            x={scaleX(label) + (scaleX.bandwidth() * (index / 10))}
                            y={scaleY(value)}
                            width={scaleX.bandwidth() / values.length - 10}
                            height={height - scaleY(value)}
                            fill={maxGender ? colorMaxGender[index].color : coloresGrafica[index].color}
                        />
                    ))}
                </g>
            ))}
        </>
    );
}

function Legend({ colors }) {
    return (
        <Box sx={styles.legend}>
            {colors.map((color, index) => (
                <Box key={index} sx={styles.item}>
                    <Box sx={{ ...styles.colorBox, backgroundColor: color.color }} />
                    <span style={styles.textColor}>{color.label}</span>
                </Box>
            ))}
        </Box>
    );
}

function BarChart({ title = null, subTitle = null, MaxGender = false, legend, data, labels, text, endLegend = false, xLegend = null, yLegend = null, endLegendMissing = false}) {
    const svgRef = useRef()
    const elements = data.length;
    const isMobile = window.innerWidth <= 768;
    const margin = { top: 10, right: isMobile ? 50 : 100, bottom: 20, left: 50 };
    const width = 1200 + (180 * elements);
    const height = 500 - margin.top - margin.bottom;
    const dataLabels = labels || data;

    const scaleX = scaleBand()
        .domain(dataLabels.map(({ label }) => label))
        .range([0, width])
        .paddingInner(0)
        .paddingOuter(.1)
        .align(0.4)
    const scaleY = scaleLinear()
        .domain([0, Math.max(...data.flatMap(({ values }) => values))])
        .nice()
        .range([height, 0]);

    const drawToolTip = (d, tooltipMap, val = 0) => {
        tooltipMap.html(`
                    <div style='font-size: 12px; line-height: 15px;'>
                    <table>
                    <tr>
                      <td><span style='height: 18px; width: 18px; background-color: ${colorMaxGender[val].color}; border-radius: 50%; display: inline-block;'></span></td>
                      <td><span>${d?.values?.[val] || 0}</span><br><span>${d?.legend?.[val] || ""}</span></td>
                    </tr>
                    </table>
                    </div>
                    `);
    return tooltipMap };

    useEffect(() => {
        const svg = select(svgRef.current);

        let tooltipMap = d3.select('.tooltip')
            .append("div")
            .attr("id", "tooltip")
            .style("position", "absolute")
            .style("visibility", "hidden")
            .style("background-color", "white")
            .style("border-radius", "5px")
            .style("box-shadow", "1px 1px 1px 1px #eeeff5")
            .style("padding", "4px");

        let groups = `.bar-group`;
        data.forEach((e, i) => {
            groups += `, .bar-group-${i}`
        });

        svg.selectAll(groups)
        .data(data);

        colorMaxGender.forEach((color, i) => {
            d3.selectAll(groups).select(`rect[id='${i}']`)
            .on('mouseover', (e, d) => {
                tooltipMap.style("visibility", "visible")
                drawToolTip(d, tooltipMap, i);
            })
            .on('mousemove', function(e, d) {
                tooltipMap.style('left', (e.pageX+10) + 'px').style('top', (e.pageY+5) + 'px')
            })
            .on('mouseout', (e, d) => {
                tooltipMap.style("visibility", "hidden")
            });
        })

    }, [data, width, height])

    return (
        <>
        <Box sx={styles.container}>
            {title != null && (
                <Typography sx={styles.title}>{title}</Typography>
            )}
            {subTitle != null && (
                <Typography sx={{...styles.title, fontSize: '18px' }}>{subTitle}</Typography>
            )}
            {text != null && (
                <Typography sx={{...styles.title, fontSize: '12px', mb: 5 }}>{text}</Typography>
            )}
            <Legend colors={legend} />
            <Box sx={styles.svgContainer}>
            {yLegend && (
                <Box sx={{display: 'flex', width: '1%', position: 'absolute', height: '100%'}}>
                <Typography sx={styles.legendy}>{yLegend}</Typography>
             </Box>
            )}
            <div id={'tooltip'} className={'tooltip'}></div>
                <svg width={"51px"} height={height + margin.top + margin.bottom} style={{position: "absolute", backgroundColor: '#FAFAFA'}} >
                    <g transform={`translate(${margin.left}, ${margin.top})`} style={{ color: "#717177", overflowX: "hidden", paddingBottom: "50px"}}>
                        <AxisLeft scale={scaleY} />
                    </g>
                </svg>
                <svg
                    ref={svgRef}
                    width={isMobile ? width : width}
                    height={height + margin.top + margin.bottom}
                >
                    <g
                        transform={`translate(${margin.left}, ${margin.top})`}
                        style={{ color: "#717177", overflowX: "hidden", paddingBottom: "50px"}}
                    >
                        <AxisBottom scale={scaleX} transform={`translate(-20, ${height})`} />
                        <Bars data={data} height={height} scaleX={scaleX} scaleY={scaleY} legend={legend} maxGender={MaxGender} />
                    </g>
                </svg>
            </Box>
            {xLegend && (
                <>
                    <Box>
                        <Typography sx={styles.legendx}>{xLegend}</Typography>
                    </Box>
                </>
            )}
            {endLegend && (
                <>
                    <Typography sx={{ color: "#3B4348", fontSize: "10px", textAlign: "justify", mt: 2, fontFamily: 'Geologica Bold !important'}}>
                        * La Ley General en Materia de Desaparición Forzada de Personas, Desaparición Cometida por Particulares y del Sistema Nacional de Búsqueda de Personas contempla los siguientes criterios de clasificación de Personas Localizadas: Persona localizada que no fue víctima de ningún delito, Persona localizada víctima de un delito materia de esta Ley y Persona localizada víctima de un delito diverso.<br></br>
                        Por lo que el presente gráfico considera la siguiente información:<br></br>
                        Personas localizadas víctimas de delito: contempla a aquellas que fueron víctimas de un delito contemplado en la ley, así como a quienes fueron víctimas de delitos diversos.<br></br>
                        Personas localizadas sin ser víctimas de delito: contempla a aquellas que no fueron víctimas de ningún delito.
                    </Typography>
                </>
            )}
            {endLegendMissing && (
                <>
                    <Typography sx={{ color: "#3B4348", fontSize: "10px", textAlign: "justify", mt: 2, fontFamily: 'Geologica Bold !important'}}>
                        {`${endLegendMissing}`}
                    </Typography>
                </>
            )}
        </Box>
        </>
    );
}

export default BarChart;
