import React, { useEffect } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import ResponsiveAppBar from "Components/moleculas/AppBar/AppBar";
import Footer from "Components/moleculas/Footer/Footer";
import MobileMenu from "Components/moleculas/MobileMenu";
import { useTemplateContext } from 'Context/AppContext'
import { isMobile } from 'Utils/constants';

const Home = () => {
    const location = useLocation()
    const [searchParams] = useSearchParams();
    const { loadStates, loadMunicipalities, loadTypesOfSex } = useTemplateContext()
    const { mobileMenuOpen } = useTemplateContext()

    useEffect(() => {
        window.scrollTo(0, 0);
        let state = 14;
        if (searchParams.get('estado')) {
            state = searchParams.get('estado');
        }

        loadStates().then((states) => {
            loadMunicipalities(state)
        })
        
        loadTypesOfSex()
    }, [location.pathname, loadStates, loadMunicipalities, loadTypesOfSex, searchParams])

    return (<>
        <CssBaseline />
        {
            isMobile &&
            mobileMenuOpen &&
            <MobileMenu />
        }
        <ResponsiveAppBar />
        <Outlet />
        <Footer/>
    </>)
}

export default Home