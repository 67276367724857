const styles = {

  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '1rem',
    margin: 'auto',
    justifyContent: 'space-evenly',
    position: 'relative',
    "@media (max-width: 767px)": {
      margin: 'auto',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
    }
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#FFFFFF',
    borderRadius: '32px',
    padding: '1rem 2rem',
    justifyContent: 'center'
  },
  inputContainer: {
    minWidth: 270,
    justifyContent: 'center',
    margin: '8px'
  },
  formControl: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '2px solid #EFEFEF',
    borderRadius: '32px',
    opacity: 1,
    minWidth: 120,
    color: '#5A7C72', fontFamily: 'Geologica Regular !important',
    ' & .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px !important'
    }
  },
  input: {
    background: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    border: '2px solid #FFFFFF',
    borderBottom: '0px',
    marginTop: '-3px',
    "& .MuiInputLabel-root": {
      display: "flex",
      alignItems: "center",
    }
  },
  button: {
    borderRadius: 55,
    display: 'flex',
    margin: 'auto',
    backgroundColor: '#DE5E60',
    ':hover': {
      backgroundColor: '#DE5E60',
      opacity: 2
    },
    "@media (max-width: 767px)": {
      margin: '10px',
      padding: '1rem',
    }
  },
  cleanFilters: {
    borderRadius: 55,
    display: 'flex',
    margin: 'auto 15px',
    backgroundColor: '#E9EDEF',
    color: '#889296',
    textTransform: 'capitalize !important',
    ':hover': {
      backgroundColor: '#E9EDEF',
      opacity: 2
    },
    "@media (max-width: 767px)": {
      padding: '1rem 3rem',
    }
  },

  img: {
    width: '14%',
    marginRight: '6px',
    marginTop: '2px'
  }
}

export default styles;
