// React
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Providers from './providers';
import ReactGA from "react-ga4";

const isDev = process.env.REACT_APP_ENVIRONMENT_MODE === "development";
const trackingID = process.env.REACT_APP_TRACKING_ID;
const root = createRoot(document.getElementById('root'));

isDev && console.log('¡Tú estás viendo esto, porque estás en modo de desarrollo!');

ReactGA.initialize(trackingID, { 
    testMode: isDev,
    gaOptions: {
        cookieFlags: isDev ? 'SameSite=None;Secure' : 'Lax'
    }
});

root.render( <> {
    !isDev 
        ?   <Providers /> 
        :   <React.StrictMode>
                <Providers />
            </React.StrictMode>
} </> );

reportWebVitals();
