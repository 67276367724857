const styles = {
    customAppBar: {
      backgroundColor: 'primary.main',
      justifyContent: 'center',
      zIndex: 100
    },
    container: {
      flexDirection: 'row',
      //flexWrap: 'wrap'
    },
    img: {
      marginRight: 'auto',
      maxHeight: '41px',
      width: 'auto',
      maxWidth: '100%',
    },
    text: {
      display: 'block',
      fontWeight: 'bold',
      letterSpacing: '0px',
      opacity: 1,
      transition: 'color 0.3s ease-in-out',
      cursor: 'pointer',
      color: 'white',
      marginRight: '30px',
      textDecoration: 'none'
    }
  
  }
  
  export default styles;
  