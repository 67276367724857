import React from "react";
import { useNavigate } from 'react-router-dom';

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import ModalBienvenida from "../components/moleculas/Home/ModalBienvenida/ModalBienvenida";
import Banner from "../components/moleculas/Home/Banner";
import Map from "../components/moleculas/Home/Map";
import CedulaBanner from "../components/moleculas/Home/CedulaBanner";
import ConoceMasBanner from "../components/moleculas/Home/ConoceMasBanner";
import PersonsFinder from "../components/organisms/PersonsFinder/PersonsFinder";
import GoToComponent from "../components/moleculas/GoToComponent/GoToComponent";

const Home = () => {
    const navigate = useNavigate();
    
    const redirectWithSearchPeople = (params) => {
        const filteredParams = Object.keys(params).reduce((object, key) => {
            if (params[key]) {
                object[key] = params[key];
            }
            return object;
        }, {});
        const queryString = new URLSearchParams(filteredParams).toString();
        window.scrollTo(0, 0)
        navigate(`/cedulas-de-busqueda?${queryString}`);
    }

    return (<Box componen="div" sx={{ overflowX: "hidden", flexDirection: 'column', display: 'flex' }}>
        <Banner />
        <Map />
        <CedulaBanner />
        <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}} >
            <PersonsFinder onPress={redirectWithSearchPeople} />
        </Container>
        <ConoceMasBanner />
        <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mb: 5}}>
            <GoToComponent title={"¿Qué hago si alguna persona de mi familia o con quien tengo una relación cercana está desaparecida? Acude a:"}/>
        </Container>
        <ModalBienvenida />
    </Box>
    )
}

export default Home;