const styles = {

  grid: {
    display: "flex", 
    flexDirection: {xs: 'column', sm: 'row'}
  },

  gridBox: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },

  box: {
    width: {xs: '85%', md: '80%', lg: '58%'},
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: '230px',
    my: {xs: 3, sm: 0, md: 3, lg: 0}
  },

  phoneIcon: {
    fontSize: 50, 
    color: 'white', 
    textAlign: 'left', 
    alignItems: 'flex-start', 
    marginTop: 1.25
  },

  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  box1: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: '230px',
    textAlign: {xs: 'center', sm: 'left'},
    display: 'grid'
  },
  
  boxText: {
    display: 'flex',
    fontFamily: 'Geologica Regular !important',
    color: 'white',
    letterSpacing: '0px',
    lineHeight: '30px',
    fontSize: {xs: 18, sm: 22},
    textAlign: 'left',
    alignItems: 'flex-end'
  },
  phoneText: {
    color: 'white',
    fontSize: 38,
    letterSpacing: '0px',
    fontWeight: 'bold',
    fontFamily: 'Geologica Bold',
    marginTop: 1,
    marginLeft: 2,
  },


  footerContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '230px',
    background: '#3B4348',
    opacity: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerContainer2: {
    position: 'relative',
    left: '0px',
    height: '230px',
    background: '#3B4348',
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  searchLogo:{
    width: '90%',
    margin: 'auto'
  },
  img: {
    marginTop: '20px',
    marginBottom: '20px',
  }

}

export default styles;
