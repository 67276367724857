import axios from "axios";

const apiUrl = process.env.REACT_APP_BACKEND_API_HOST;

export const api = axios.create({
  baseURL: `${apiUrl}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=utf-8",
  },
});


