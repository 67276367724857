import { api } from "./api";

export const getTypesOfSex = () => {
  return api
    .get("/catalogos/sexo/")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(
        "Error en la solicitud de tipos de sexo: " + error.message
      );
    });
};

export const getStates = (limit = 50) => {
  const queryString = limit ? `?limit=${limit}` : "";

  return api
    .get(`catalogos/estado/${queryString}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(
        "Error en la solicitud de tipos de estado: " + error.message
      );
    });
};

export const getMunicipalities = (estadoId = 14, limit = 600) => {
  const queryParams = {};
  let queryString = "";

  if (limit) {
    queryParams["limit"] = limit;
  }

  if (estadoId) {
    queryParams["filtro"] = estadoId;
  }

  if (Object.keys(queryParams).length > 0) {
    queryString = `?${new URLSearchParams(queryParams).toString()}`;
  }

  return api
    .get(`/catalogos/municipio/${queryString}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(
        "Error en la solicitud de tipos de municipio: " + error.message
      );
    });
};
