import React from 'react';
import styles from './styles.js';
import { COMISION_BUSQUEDA, FISCALIA, LOGO_JAL, PERSONAS_DESAPARECIDAS_LOGO, TE_ESTAMOS_BUSCANDO_LOGO } from '../../../assets';
import { Box, Typography, Container, Grid } from '@mui/material';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';

const Footer = () => {
    return (
        <Container disableGutters maxWidth="false">
            <Grid container spacing={0} sx={styles.grid}>
                <Grid item xs={12} md={6} sx={{...styles.gridBox, backgroundColor: '#1C5866', py: {xs: 0, md: 2, lg: 2, xl: 0}}}>
                    <Box sx={{...styles.box, ml: {xs: 2, sm: 2} }}>
                        <Typography sx={styles.boxText}>También puedes comunicarte a la Comisión de Búsqueda de Personas del Estado de Jalisco:</Typography>
                        <PhoneInTalkOutlinedIcon sx={styles.phoneIcon} />
                        <Typography sx={styles.phoneText}>33 3145 6314</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{...styles.gridBox, backgroundColor: '#D1484B'}}>
                    <Box style={styles.box}>
                        <img src={TE_ESTAMOS_BUSCANDO_LOGO} style={styles.searchLogo} alt='Estamos buscando Logo' />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{...styles.gridBox, backgroundColor: '#3B4348'}}>
                    <Box 
                        sx={{ 
                            ...styles.box, 
                            justifyContent: { xs: 'center', md: 'space-evenly' },
                            width: {xs: '85%', sm: '80%', md: '80%', lg: '65%'}, 
                            my: {xs: 3, sm: 0},
                            flexDirection: {xs: 'column', md: 'row'},

                        }}>
                        <img src={PERSONAS_DESAPARECIDAS_LOGO} style={styles.img} alt='Personas Desaparecidas Logo' />
                        <img src={COMISION_BUSQUEDA} style={styles.img} alt='Comision busqueda Logo'/>
                        <img src={FISCALIA} style={styles.img} alt='Fiscalia Logo'/>
                        <img src={LOGO_JAL} style={styles.img} alt='Jalisco Logo'/>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Footer;
