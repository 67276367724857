import { Typography, Grid, Pagination } from '@mui/material';
import { useGlobalContext } from '../../../context/GlobalContext';

const PaginationFinder = ({ totalValues, page, handleChange, perPage }) => {
    const { isSmallScreen } = useGlobalContext();
    const count = Math.ceil(totalValues?.count / perPage);
    const moreThanOnePage = totalValues?.count > 0 

    return (
        <Grid container sx={{ width: '100%'}}>
            <Grid item xs={12} md={6}>
                <Typography 
                    sx={{ 
                        alignItems: "center", 
                        justifyContent: isSmallScreen ? 'center' : 'start',
                        color: "#3B4348", 
                        fontSize: "15px", 
                        height: "100%",
                        display: moreThanOnePage ? 'flex' : 'none',
                        ml: isSmallScreen ? 0 : 5
                    }}
                >
                    {`*Búsqueda ordenada por fecha de desaparición`}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Pagination 
                    count={moreThanOnePage ? count : 0} 
                    page={page} 
                    onChange={handleChange} 
                    size="large" 
                    color="primary" 
                    sx={{
                        button:{color: '#5A7C72'}, 
                        display: moreThanOnePage ? 'flex' : 'none', 
                        justifyContent: isSmallScreen ? 'center' : 'end', 
                        my: 2,
                        mr: isSmallScreen ? 0 : 8
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default PaginationFinder;
