import React from 'react';
import styles from './styles.js';
import {
    Box,
    Typography
} from '@mui/material';

const TextList = ({ title, data, backgroundColor }) => {

    return (
        <Box sx={{ backgroundColor: backgroundColor || '#FFFF', ...styles.container }}>
            <Box >
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Typography sx={styles.title}>{title}</Typography>
                </Box>
                <Box>
                    {data.map((item, index) => (
                     <Box key={index} sx={{display: 'flex', color: '#1C5866', marginBottom: '30px' }}>
                        <Box>
                            <Box sx={styles.index}>{index + 1}</Box>
                        </Box>
                        <Typography sx={styles.text}>{item.text}</Typography>
                     </Box>   
                    ))}
                </Box>

            </Box>
        </Box>
    );
};

export default TextList;
