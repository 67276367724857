import React from 'react';
import styles from './styles.js';
import { Box, Typography, Container, Grid } from '@mui/material';
import { BANNER_INICIO_LOGO } from '../../../assets/index.js';

const Banner = () => {
    return (
        <Box component="main" sx={{backgroundColor: '#27626F'}}>
            <Container maxWidth={'xl'} sx={{ px: 0, pt: {xs: 10, sm: 22},  pb: {xs: 5, sm: 10}}}>
                <Grid container spacing={0} sx={styles.grid}>
                    <Grid item xs={12} md={6} sx={styles.gridBox}>
                        <Box sx={{...styles.boxHero}}>
                            <Box component="div">
                                <Typography sx={{...styles.titleText, lineHeight: {xs: '75px', sm: '65px' }}}>En Jalisco <br></br><font color="#E86E70">estamos buscando</font></Typography>
                                <Typography sx={styles.subtitleText}>En nuestro estado, toda persona cuya ubicación y paradero se desconocen es considerada como una persona desaparecida, independientemente de que su ausencia se relacione o no con la comisión de un delito</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={styles.gridBox}>
                        <Box sx={styles.boxHero}>
                            <img src={BANNER_INICIO_LOGO} alt="Banner inicio"/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Banner;
