import React from "react";

import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";
import { useTemplateContext } from 'Context/AppContext'

import styles from "./styles.js";

const pages = [
    { title: 'Inicio', path: '/' },
    { title: 'Estadísticas', path: '/estadisticas' },
    { title: 'Cédulas de búsqueda', path: '/cedulas-de-busqueda' },
    { title: 'Conoce más', path: '/conoce-mas' },
];

const MobileMenu = () => {
    const { setMobileMenuOpen } = useTemplateContext()
    return (
        <Box sx={styles.container}>
            {pages.map((page) => (
                <Link key={`mobilemenu_${page.title}`} style={styles.menuElement} to={page.path} 
                    onClick={() => {
                        setMobileMenuOpen(false)
                        window.scrollTo(0, 0)
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.color = '#DE5E60';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = 'white';
                    }}
                >{page.title}</Link>
            ))}
        </Box>
    )
}

export default MobileMenu