import axios from "axios";

const apiUrl = process.env.REACT_APP_BACKEND_API_HOST;

const axiosPublic = axios.create({
  baseURL: `${apiUrl}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=utf-8",
  },
});

export const getSexesLocatedByMunicipality = async () =>
  await axiosPublic.get(`version_publica/grafica_municipios_sexos_localizados/`);

export const getLocalizedAgeRange = async () =>
  await axiosPublic.get(`version_publica/grafica_rangoedad_localizados/`);

export const getLocatedByYearSexAndCondition = async () =>
  await axiosPublic.get(`version_publica/grafica_anios_condicion_localizados/`);

export const getLocatedByCrimeVictim = async () =>
  await axiosPublic.get(`version_publica/grafica_aniosreporte_victimadelito_localizados/`);

export const getAgeRangeClassification = async () =>
  await axiosPublic.get(`version_publica/grafica_rangoedad_usoviolencia_desaparecidos/`);

export const getSexesMissingByMunicipality = async () =>
  await axiosPublic.get(`version_publica/grafica_municipios_sexos_desaparecidos/`);

export const getYearRangeMissingClassification = async () =>
  await axiosPublic.get(`version_publica/grafica_aniosreporte_usoviolencia_desaparecidos/`);