const styles = {

  container: {
    width: '100%',
    justifyContent: 'space-evenly',
    backgroundColor: '#FFFFFF',
    minHeight: '200px',
    alignItems: 'center',
    py: '3rem',
    px: '1rem',
    flexWrap: 'wrap',
    boxShadow: '0px 3px 18px #3435401A',
    borderRadius: '25px',
    top: '-110px',
    position: 'relative',
    "@media (max-width: 767px)": {
      margin: '2rem auto'
    }
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: {xs: 'column', md: 'row'},
  },
  legendContainer:{
    width: '100%',
    px: 0,
    mt: -8,
    mb: 2,
    textAlign: 'left'
  },
  redTitle: {
    textAlign: 'center',
    color: '#DE5E60',
    opacity: 1,
    fontSize: 30,
    marginBottom: '18px'
  },
  listItemCircleStyle: {
    width: '12px',
    height: '12px',
    minWidth: '12px',
    background: '#1C5866',
    borderRadius: '50%',
    marginRight: '10px',
  },
  listText:{
    fontFamily: 'Geologica Regular !important',
    color: "#1C5866"
  },
  boldTextStyle: {
    fontWeight: 'bold',
  },
  inputContainer: {
    minWidth: 270,
    justifyContent: 'center',
    margin: '8px'
  },
  formControl: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '2px solid #EFEFEF',
    borderRadius: '32px !important',
    opacity: 1,
    minWidth: 120,
    color: '#5A7C72', fontFamily: 'Geologica Regular !important',
    ' & .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px !important'
    }
  },
  input: {
    background: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    border: '2px solid #FFFFFF',
    borderBottom: '0px',
  },
  button: {
    height: '42px',
    borderRadius: 55,
    color: '#FFFFFF',
    mb: 1,
    px: 2,
    minHeight: '40px',
    minWidth: { xs: '120px', md: '52px' },
    display: 'flex',
    backgroundColor: '#DE5E60',
    textTransform: 'capitalize !important',
    ':hover': {
      backgroundColor: '#DE5E60',
      opacity: 2,
    }
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto 0 100px',
    justifyContent: 'center'
  },
  notFoundAlert: {
    color: 'danger.main',
    bgcolor: 'danger.light',
    borderRadius: '10px',
    width: '100%',
    textAlign: 'center',
    py: 2,
    px: 4,
    my: 4
  }
}

export default styles;
