const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'primary.main',
        zIndex: 101,
        position: 'fixed',
        top: 50,
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14)'
    },
    menuElement: {
        margin: '12px 0px 20px 20px',
        display: 'block',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        letterSpacing: '0px',
        opacity: 1,
        transition: 'color 0.3s ease-in-out',
        cursor: 'pointer',
        color: 'white',
        marginRight: '20px',
        textDecoration: 'none'
    }
}

export default styles;