const styles = {
    modal: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    alertContainer: {
        top: '20px',
        left: '50%',
        width: '100%',
        maxWidth: '700px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        backdropFilter: 'blur(10px)',
        borderRadius: '16px',
        py: 2,
        px: 4,
        mx: 2,
        mt: 4
    },
    itemContainer: {
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100%'
    },
    text: {
        color: '#1C5866',
        fontWeight: 'regular',
        letterSpacing: '0px',
        fontFamily: 'Geologica Regular !important',
        textAlign: { xs: 'left', md: 'center' },
        ml: { xs: 3, md: 4 }
    },
    img: {
        margin: 0,
        width: '40px'
    }
}
export default styles;
