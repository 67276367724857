import React, { useState } from 'react';
import styles from './styles.js';
import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';
import { SEARCH_ICON } from '../../../assets/index.js';
import { isMobile } from '../../../utils/constants.js';

const StatisticsFilter = ({state = null, municipalities = null, sexTypes = null, setStatus, loading}) => {
    const PickerContainer = styled(FormControl)(() => (styles.formControl));
    const [estado, setEstado] = useState('');
    const search = () => {
        setStatus(estado);
    };

    const cleanInputs = () => {
        setStatus('');
        setEstado('');
    }

    return (
        <Box sx={styles.container}>
            <Box sx={styles.box}>
                <PickerContainer sx={{ m: 1, minWidth:  isMobile ? 300 : 200 }} size="small">
                    <InputLabel id="status-input">Estatus</InputLabel>
                    <Select id="status-select" value={estado} label="Tipo de protocolo" onChange={(event) => setEstado(event.target.value)}>
                        <MenuItem value={'localizado'}>Localizado</MenuItem>
                        <MenuItem value={'desaparecido'}>Desaparecido</MenuItem>
                    </Select>
                </PickerContainer>
                <IconButton sx={styles.button} onClick={search}>
                    {loading 
                    ? (<CircularProgress size={20} color="inherit" /> ) 
                    : (<img src={SEARCH_ICON} width={20} alt={'Icono de busqueda'}></img>) }
                </IconButton>
                <Button variant="contained" size="medium" sx={styles.cleanFilters} onClick={cleanInputs}>
                    Limpiar filtros
                </Button>
            </Box>
        </Box>
    );
};

export default StatisticsFilter;
