import React, { createContext, useContext, useState } from 'react';
import { fetchCardData } from '../services/apiStatistics';

const StatisticsPageContext = createContext(null);

const StatisticsPageContextProvider = ({ children }) => {    
    const [isLoadingStatistics, setLoadingStatistics] = useState({
        page: false,
        cards: false
    })
    const [cardData, setCardData] = useState({})

    const handleLoadingStatistics = (seccion, state) => 
        setLoadingStatistics((prevFormData) => ({...prevFormData, ...{ [seccion]: state } }))

    const handleSetCardData = (data) => {
        setCardData(data)
    }

    const handleGetCardData = () => {
        handleLoadingStatistics('cards', true)

        fetchCardData().then((result) => {
            handleSetCardData(result.data)
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            handleLoadingStatistics('cards', false)
        })

    }

    const cleanStateStatisticsContext = () => {
        handleSetCardData({})
        setLoadingStatistics({})
        setCardData({})
    }

    const forContextData = {
        cleanStateStatisticsContext,
        isLoadingStatistics, handleLoadingStatistics,
        cardData, handleGetCardData, handleSetCardData
    }

    return (
        <StatisticsPageContext.Provider value={forContextData} children={children} />
    )

}

StatisticsPageContext.displayName = 'StatisticsPageContext';

const useStatisticsContext = () => {
    const context = useContext(StatisticsPageContext);
    if (!context) {
        throw new Error('useStatisticsContext debe usarse dentro de un StatisticsPageContextProvider');
    }
    return context;
};

export { StatisticsPageContextProvider, StatisticsPageContext, useStatisticsContext };