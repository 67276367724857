import React, { createContext, useContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from "dayjs";

const GlobalContext = createContext(null);

const GlobalContextProvider = ({ children }) => {  
    const conditionDay = dayjs().date() < 10 ? 2 : 1;  
    const updateDate = dayjs().subtract(conditionDay, 'month').endOf('month').locale("es-mx");
    const fullDateFormat = `${updateDate.format('DD')} de ${updateDate.format('MMMM')} de ${updateDate.format('YYYY')}`
    const isSmallScreen = useMediaQuery("(max-width: 895px)");
    const apiUrl = process.env.REACT_APP_BACKEND_API_HOST;

    const cleanStateGlobalContext = () => {

    }

    const forContextData = {
        cleanStateGlobalContext,
        updateDate, fullDateFormat,
        isSmallScreen, 
        apiUrl
    }

    return (
        <GlobalContext.Provider value={forContextData} children={children} />
    )

}

GlobalContext.displayName = 'GlobalContext';

const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalContext debe usarse dentro de un GlobalPageContextProvider');
    }
    return context;
};

export { GlobalContextProvider, GlobalContext, useGlobalContext };
