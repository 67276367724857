import {Box, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {FAMILIAR_DESAPARECIDO_IMG, IMG_QUE_ES_2, REGISTRO_DESAPARECIDOS_IMG } from "../assets";
import { MISSING_PERSONS_LAW } from "../utils/constants";
import TextList from "../components/moleculas/TextList/TextList";
import GoToComponent from "../components/moleculas/GoToComponent/GoToComponent";
import BannerLeyes from "../components/moleculas/About/LeyesBanner";
import styles from "./AboutStyles";

const About = () => {

    return (
        <Box >
            <Container maxWidth="xl" sx={{ px: 0, pt: 16,  pb: {xs: 5, md: 10}}}>
              <Box sx={styles.titleBox}>
                <Typography sx={styles.titleText}>Conoce más</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box sx={{...styles.box, height: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <Typography variant="h3" sx={styles.subtitleText}>
                      ¿Cuándo se considera que una persona está desaparecida?
                    </Typography>
                    <Typography color="primary.main" fontWeight="normal" sx={styles.text}>
                      La Ley de Personas Desaparecidas del Estado de Jalisco establece que una persona desaparecida es aquella 
                      <span style={{ fontWeight: 'bold' }}> cuya ubicación y paradero se desconocen, independientemente de que su ausencia se relacione o no con la comisión de un delito </span>
                      (Artículo 4.XXVIII)
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={styles.box}>
                    <img style={{ width: '100%' }} src={REGISTRO_DESAPARECIDOS_IMG} alt="Familiar desaparecido" />
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <div style={{ backgroundColor: "#FAFAFA" }}>
              <Container maxWidth="xl" sx={{ px: 1, pt: {xs: 5, sm: 10},  pb: {xs: 5, sm: 10}}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{...styles.box, height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                      <img style={{ maxHeight: '400px' }} src={IMG_QUE_ES_2} alt="Familiar desaparecido" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{...styles.box}}>
                      <Typography variant="h3" sx={styles.subtitleText}>
                        ¿Qué es el Registro Estatal de Personas Desaparecidas?
                      </Typography>
                      <Typography color="primary.main" fontWeight="normal" sx={styles.text}>
                        <span style={{ fontWeight: 'bold' }}>El Registro Estatal de Personas Desaparecidas contiene información de todas las personas que han sido reportadas como desaparecidas en el estado: </span> 
                        tanto de aquellas de las que se sigue sin conocer su paradero como de las que ya han sido localizadas.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </div>
            <Container maxWidth="xl" sx={{ px: 0, pt: {xs: 5, sm: 10},  pb: {xs: 5, sm: 10}}}>
              <TextList
                title={"De acuerdo con la Ley de Personas Desaparecidas del Estado de Jalisco:"}
                data={MISSING_PERSONS_LAW}
              />
            </Container>
            <div style={{ backgroundColor: "#FAFAFA" }}>
              <Container maxWidth="xl" sx={{ px: 1, pt: {xs: 5, sm: 10},  pb: {xs: 5, sm: 10}}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{...styles.box, height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                      <Typography variant="h3" sx={styles.subtitleText}>
                        ¿Qué hago si alguna persona de mi familia o con quien tengo una relación cercana está desaparecida?
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{...styles.box, height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                      <img style={{ maxHeight: '400px' }} src={FAMILIAR_DESAPARECIDO_IMG} alt="Familiar desaparecido" />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </div>
            <Box component="div" sx={{ pt: 5, pb: 5 }}>
              <GoToComponent/>
            </Box>
            <Box component="div" sx={{ backgroundColor: "#FAFAFA" }}>
              <Container maxWidth="xl" sx={{ px: 0, pt: {xs: 5, sm: 10},  pb: {xs: 5, sm: 10}}}>
                <BannerLeyes />
              </Container>
            </Box>
        </Box>
    )
}

export default About
