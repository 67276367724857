import React, { useEffect } from 'react';
import styles from './styles.js';
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Grid
} from '@mui/material';
import {
    COMISION_BUSQUEDA_GRAY,
    COMPARTIR,
    DESCARGAR,
    REPORTAR,
    MARCA_AGUA,
} from '../../../assets/index.js';
import { isMobile } from '../../../utils/constants.js';
import QRCode from 'qrcode'
import { downloadPdf } from './MissingPersonPdf.js';
import dayjs from "dayjs";

const getTagData = (status) => {
    switch (status) {
        case "CON VIDA": 
            return { styles: styles.tagConVida, text: "LOCALIZADA CON VIDA" }
        case "SIN VIDA":
            return { styles: styles.tagSinVida, text: "LOCALIZADA SIN VIDA" }
        default:
            return { styles: styles.tagDesaparecido, text: "PERSONA DESAPARECIDA" }
    }
}

const MissingPersonCard = ({ personItem, onPressShare, onPressReport }) => {
    const [qrCodeString, setQrCodeString] = React.useState('');

    const {
        condicion_localizacion,
        municipio,
        estado,
        nombre_completo,
        edad_momento_desaparicion,
        genero,
        sexo,
        complexion,
        estatura,
        tez,
        cabello,
        ojos_color,
        fecha_desaparicion,
        ruta_foto,
        descripcion_sena_particular,
        descripcion_vestimenta,
        id_cedula_busqueda
    } = personItem;

    const formatDate = (inputDate) => {
        if (inputDate === null || inputDate === undefined || inputDate  === 'None' ) {
            return 'SIN DATO';
        }
        dayjs.locale("es");
        const date = dayjs(inputDate, 'YYYY-MM-DD');
        const formattedDate = `${date.format('DD')} de ${date.format('MMMM')} del ${date.format('YYYY')}`;
        return formattedDate;
    };

    const muniEsta = `
        ${municipio !== 'None' ? `${municipio}, ` : ''} 
        ${estado !== 'None' ? estado : ''}
    `

    const verifyAge = (age) => {
        if (age === null || age === undefined || age < 0 || age  === 'None' ) {
            return 'SIN DATO';
        } else {
            return `${age} AÑOS`;
        }
    }

    const hasInfoField = (info) => 
        info && info !== 'None' ? info : "SIN DATO";

    const tagData = getTagData(condicion_localizacion);

    useEffect(() => {
        QRCode.toDataURL(`${window.location.protocol}//${window.location.host}/cedulas-de-busqueda?id_cedula_busqueda=${id_cedula_busqueda}`)
            .then(url => {
                setQrCodeString(url);
            })
    }, [id_cedula_busqueda]);

    return (
        <Box sx={styles.tableStyles}>
            <img src={MARCA_AGUA} style={styles.watermark} alt={'Marca de agua imagen'} />
            <Box sx={{ ...styles.tag, ...(tagData.styles ?? {}) }}>
                <Typography sx={styles.tagText}>
                    {tagData.text}
                </Typography>
            </Box>
            {qrCodeString && <img src={qrCodeString} style={styles.qrCode} alt={'QR Code'} />}
            <Box sx={{ p: 1 }}>
                <Box sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Box sx={{ display: {xs: 'row', md: 'flex'} }}>
                        <img src={ruta_foto} width={155} height={170} style={styles.imgPerson} alt={'Imagen'} />
                        <Box sx={styles.nameBox}>
                            <Box sx={styles.row} spacing={2}>
                                <Typography sx={{ ...styles.label, mt: '2px' }}>NOMBRE:</Typography>
                                <Typography sx={{ ...styles.valueTextName, fontSize: '10px' }}>{nombre_completo}</Typography>
                            </Box>
                            <Box sx={styles.row} spacing={2}>
                                <Typography sx={{ ...styles.label, mt: '1px' }}>
                                    EDAD AL MOMENTO DE DESAPARICIÓN:
                                </Typography>
                                <Typography sx={{ ...styles.valueTextName, fontSize: '10px' }}>
                                    {verifyAge(edad_momento_desaparicion)}
                                </Typography>
                            </Box>
                            <TableContainer component={Paper} sx={styles.table}>
                                <Table sx={styles.table}>
                                    <TableBody>
                                        <TableRow sx={styles.tableRow}>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.label}>SEXO:</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.valueText}>{hasInfoField(sexo)}</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.label}>GENERO:</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.valueText}>{hasInfoField(genero)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={styles.tableRow}>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.label}>COMPLEXIÓN:</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.valueText}>{hasInfoField(complexion)}</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.label}>ESTATURA:</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.valueText}>{hasInfoField(estatura)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={styles.tableRow}>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.label}>TEZ:</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.valueText}>{hasInfoField(tez)}</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.label}>CABELLO:</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.valueText}>{hasInfoField(cabello)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={styles.tableRow}>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.label}>OJOS COLOR:</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.valueText}>{hasInfoField(ojos_color)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer component={Paper} sx={styles.table}>
                                <Table sx={styles.table}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={styles.celStyleW1}>
                                                <Typography sx={styles.label}>FECHA DESAPARICIÓN:</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.valueText}>{formatDate(fecha_desaparicion)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={styles.celStyleW1}>
                                                <Typography sx={styles.label}>LUGAR DE DESAPARICIÓN:</Typography>
                                            </TableCell>
                                            <TableCell sx={styles.celStyle}>
                                                <Typography sx={styles.valueText}>{hasInfoField(muniEsta)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        {descripcion_vestimenta && descripcion_vestimenta.map((item, index) => (
                                            <TableRow key={index} sx={styles.tableRow}>
                                                <TableCell sx={styles.celStyleW1}>
                                                    <Typography sx={styles.label}>vestimenta:</Typography>
                                                </TableCell>
                                                <TableCell sx={styles.celStyle}>
                                                    <Typography sx={{ ...styles.valueText, fontWeight: 'normal' }}>{hasInfoField(item.descripcion)}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {descripcion_sena_particular && descripcion_sena_particular.map((item, index) => (
                                            <TableRow key={index} sx={styles.tableRow}>
                                                <TableCell sx={styles.celStyleW1}>
                                                    <Typography sx={styles.label}>SEÑAS PARTICULARES:</Typography>
                                                </TableCell>
                                                <TableCell sx={styles.celStyle}>
                                                    <Typography sx={{ ...styles.valueText, fontWeight: 'normal' }}>{hasInfoField(item.descripcion)}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={styles.footerCard}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={4.37} xl={4} sx={styles.card1}>
                                <Typography sx={styles.titleWhite}>PERSONA DESAPARECIDA</Typography>
                                <Typography sx={styles.subTitle1}><span style={{ fontFamily: "Geologica Bold" }}>¿</span>La has visto?</Typography>
                                <Typography sx={styles.subTitle2}>Ayúdanos a encontrarla</Typography>
                                {!isMobile && <Typography sx={styles.whitePlaceholder}>* Está cédula es publicada con la autorización por escrito de los familiares.</Typography>}
                            </Grid>
                            <Grid item xs={12} md={4} sx={styles.card2}>
                                <Typography sx={styles.text}>
                                    <span>Reporta cualquier información sobre la persona:</span>
                                    <span style={styles.phoneText}> 33 3145 6314 </span>
                                </Typography>
                                <Typography sx={styles.emailText}>comisiondebusqueda@jalisco.gob.mx</Typography>
                            </Grid>
                            <Grid item xs={12} md='auto' xl='auto' sx={styles.card3}>
                                <img src={COMISION_BUSQUEDA_GRAY} style={{display: 'block'}} alt={'Comision Busqueda Logo'} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.actionContainer}>
                <Button onClick={() => onPressReport(nombre_completo, id_cedula_busqueda, sexo)}>
                    <Box sx={styles.actionItem}>
                        <img src={REPORTAR} style={styles.actionImg} alt={'Reportar icono'} ></img>
                        {!isMobile && <Typography sx={styles.actionText}>Reportar</Typography>}
                    </Box>
                </Button>
                <Button onClick={() => downloadPdf(personItem, qrCodeString)}>
                    <Box sx={styles.actionItem}>
                        <img src={DESCARGAR} style={styles.actionImg} alt={'Descargar icono'} ></img>
                        {!isMobile && <Typography sx={styles.actionText}>Descargar</Typography>}
                    </Box>
                </Button>
                <Button onClick={() => onPressShare(id_cedula_busqueda)}>
                    <Box sx={styles.actionItem}>
                        <img src={COMPARTIR} style={styles.actionImg} alt={'Compartir icono'} ></img>
                        {!isMobile && <Typography sx={styles.actionText}>Compartir</Typography>}
                    </Box>
                </Button>
            </Box>
        </Box>
    );
};

export default MissingPersonCard;
