const styles = {

  container: {
    px: 2
  },
  cardContainer: {
    display: 'flex',
    justifyContent: "center",
    pb: 5,
    "@media (max-width: 767px)": {
      flexWrap: 'wrap'
    }
  },
  card: {
    maxWidth: '550px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 48px #0000001A',
    borderRadius: '24px',
    mx: 2,
    padding: '27px',
    "@media (max-width: 767px)": {
      width: '100%',
      mb: '20px'
    }
  },
  title: {
    color: '#1C5866',
    fontSize: '24px',
    opacity: 1,
    marginBottom: '10px',
    textAlign: 'center'
  },
  subTitle: {
    textAlign: 'left',
    color: '#1C5866',
    fontSize: '24px',
    marginBottom: '30px'
  },
  lightText: {
    textAlign: 'left',
    color: '#1C5866 !important',
    fontSize: '22px',
    fontFamily: 'Geologica Regular !important'
  },
  text: {
    textAlign: 'left',
    color: '#1C5866 !important',
    fontSize: '18px'
  },
  index: {
    backgroundColor: '#C0E4D9',
    borderRadius: 100,
    height: 60,
    width: 60,
    alignSelf: 'center',
    color: '#1C5866',
    fontWeight: 'bold',
    fontSize: '28px',
    textAlign: 'center',
    margin: 'auto',
    marginRight: '20px'
  },
  email: {
    textAlign: 'center',
    color: '#1C5866',
    fontSize: '20px',
    textDecoration: 'underline',
    wordWrap: 'anywhere'
  },
  underlineText: {
    mt: 1,
    textAlign: 'center',
    color: '#1C5866 !important',
    fontSize: '18px',
    textDecoration: 'underline',
    wordWrap: 'anywhere'
  }
}

export default styles;
