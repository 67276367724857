const styles = {
  container: {
    px: 2
  },

  grid: {
    display: "flex", 
    flexDirection: {xs: 'column', sm: 'row'}
  },

  gridBox: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    flexWrap: 'wrap'
  },

  titleText: {
    color: '#1C5866',
    letterSpacing: '0px',
    fontSize: 58,
    textAlign: 'center',
    lineHeight: '65px',
    fontFamily: 'Geologica Bold',
    mx: {xs: 2, sm: 0},
    mt: 3,
    mb: 7,
  },

  card: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.85rem',
    paddingX: 2,
    paddingY: 1,
    boxShadow: 8,
    borderRadius: '15px',
    width: "100%",
    minHeight: '100px',
    height: '100%',
  },

  cardText: {
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'left',
    lineHeight: '25px',
    width: '97%',
    color: '#1C5866',
    textDecoration: 'none',
  },

  imageCard: {
    minHeight: 108, 
    minWidth: 94,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '1rem'
  },

  cardContent: {
    padding: '5px',
    "&:last-child": {
      paddingBottom: '5px'
    }
  },
}

export default styles;
