export const isMobile = window.innerWidth <= 768;
export const isMobileMap = window.innerWidth <= 600;

export const COLOR_MAPPING = {
  'mujer con vida': '#DE5E60',
  'mujer sin vida': '#EFB3B4',
  'hombre con vida': '#1C5866',
  'hombre sin vida': '#C0E4D9',
  'se ignora con vida': 'gray',
  'se ignora sin vida': 'yellow',
  'intersexual con vida': 'green',
  'intersexual sin vida': 'orange',
};

export const colorGrafica4 = [
  '#DE5E60', // mujer
  '#1C5866', // hombre
  '#FDC503', // intersexual
  '#7B8991', // se_ignora
];

// Modificar estos valores repercute en los colores y el Tooltip de las grafica
export const coloresGrafica = [
  {color: '#DE5E60', label: 'Mujeres'},
  {color: '#1C5866', label: 'Hombres'},
  {color: '#FDC503', label: 'Intersexuales'},
  {color: '#7B8991', label: 'Se ignora'},
];

export const colorMaxGender = [
  {color: '#DE5E60', label: 'Mujeres', legend1: 'Mujeres con vida'},
  {color: '#EFB3B4', label: 'Mujeres sin Vida', legend1: 'Mujeres sin vida'},
  {color: '#1C5866', label: 'Hombres', legend1: 'Hombres con vida'},
  {color: '#C0E4D9', label: 'Hombres sin vida', legend1: 'Hombres sin vida'},
  {color: '#FDC503', label: 'Intersexuales', legend1: 'Intersexuales con vida'},
  {color: '#FDED22', label: 'Intersexuales sin vida', legend1: 'Intersexuales sin vida'},
  {color: '#7B8991', label: 'Se ignora', legend1: 'Se ignora con vida'},
  {color: '#D1D7D9', label: 'Se ignora sin vida', legend1: 'Se ignora sin vida'}
];

export const TooltipLocalizados = [
  'Mujeres localizadas con vida',
  'Mujeres localizadas sin vida',
  'Hombres localizados con vida',
  'Hombres localizados sin vida',
  'Intersexuales localizades con vida',
  'Intersexuales localizades sin vida',
  'Se ignora localizades con vida',
  'Se ignora localizades sin vida',
]

export const TooltipLocalizadosDelito = [
  'Mujeres localizadas víctimas de delito',
  'Mujeres localizadas sin ser víctimas de delito',
  'Hombres localizados víctimas de delito',
  'Hombres localizados sin ser víctimas de delito',
  'Intersexuales localizades víctimas de delito',
  'Intersexuales localizades sin ser víctimas de delito',
  'Se ignora localizades víctimas de delito',
  'Se ignora localizades sin ser víctimas de delito',
]

export const TooltipDesaparecidos = [
  'Mujeres desaparecidas con violencia',
  'Mujeres desaparecidas sin violencia',
  'Hombres desaparecidos con violencia',
  'Hombres desaparecidos sin violencia',
  'Intersexuales desaparecides con violencia',
  'Intersexuales desaparecides sin violencia',
  'Se ignora desaparecides con violencia',
  'Se ignora desaparecides sin violencia',
]

export const TYPES = {
  MUJER: {
    "CON VIDA": 0,
    "SIN VIDA": 0,
  },
  HOMBRE: {
    "CON VIDA": 0,
    "SIN VIDA": 0,
  },
  "SE IGNORA": {
    "CON VIDA": 0,
    "SIN VIDA": 0,
  },
  INTERSEXUAL: {
    "CON VIDA": 0,
    "SIN VIDA": 0,
  },
};

export const LEGEND = [
  { label: 'Mujeres localizadas con vida', color: "#DE5E60" },
  { label: "Mujeres localizadas sin vida", color: "#EFB3B4" },
  { label: "Hombres localizados con vida", color: "#1C5866" },
  { label: "Hombres localizados sin vida", color: "#C0E4D9" },
  { label: 'Intersexual localizades con vida', color: "#FDC503" },
  { label: "Intersexual localizades sin vida", color: "#FDED22" },
  { label: "Se ignora localizades con vida", color: "#7B8991" },
  { label: "Se ignora localizades sin vida", color: "#D1D7D9" },
];

export const LEGEND_BY_CRIME = [
  { label: "Mujeres localizadas víctimas de delito", color: "#DE5E60" },
  { label: "Mujeres localizadas sin ser víctimas de delito", color: "#EFB3B4" },
  { label: "Hombres localizados víctimas de delito", color: "#1C5866" },
  { label: "Hombres localizados sin ser víctimas de delito", color: "#C0E4D9" },
  { label: "Intersexuales localizades víctimas de delito", color: "#FDC503" },
  { label: "Intersexuales localizades sin ser víctimas de delito", color: "#FDED22" },
  { label: "Se ignora localizades víctimas de delito", color: "#7B8991" },
  { label: "Se ignora localizades sin ser víctimas delito", color: "#D1D7D9" },
];

export const LEGEND_MISSING_CRIME = [
  { label: 'Mujeres desaparecidas con violencia', color: "#DE5E60" },
  { label: "Mujeres desaparecidas sin violencia", color: "#EFB3B4" },
  { label: "Hombres desaparecidos con violencia", color: "#1C5866" },
  { label: "Hombres desaparecidos sin violencia", color: "#C0E4D9" },
  { label: 'Intersexual desaparecides con violencia', color: "#FDC503" },
  { label: "Intersexual desaparecides sin violencia", color: "#FDED22" },
  { label: "Se ignora desaparecides con violencia", color: "#7B8991" },
  { label: "Se ignora desaparecides sin violencia", color: "#D1D7D9" },
];

export const YEAR_LEGEND = [
  { label: "2018", values: [7, 1, 8, 2, 3, 1, 8, 1], color: "#DE5E60" },
  { label: "2019", values: [4, 1, 6, 2, 3, 1, 6, 1], color: "#EFB3B4" },
  { label: "2020", values: [1, 1, 5, 2, 3, 1, 5, 0], color: "#1C5866" },
  { label: "2021", values: [2, 1, 7, 2, 3, 1, 5, 2], color: "#C0E4D9" },
  { label: "2022", values: [4, 1, 2, 2, 3, 1, 2, 3], color: "grey" },
  { label: "2023", values: [2, 1, 1, 2, 3, 1, 4, 1], color: "green" },
];

export const LEGEND_OF_WOMEN_AND_MEN = [
  { label: 'Mujeres', color: "#DE5E60" },
  { label: "Hombres", color: "#1C5866" }
];

export const LEGEND_BY_GENDER = [
    { label: 'Mujeres', color: "#DE5E60" },
    { label: "Hombres", color: "#1C5866" },
    { label: "Intersexuales", color: "#fdc503" },
    { label: "Se ignora", color: "#7b8991" },
  ];

export const PIE_DATA = [
  {
    label: 'Mujeres',
    value: 60,
    color: '#DE5E60'
  }, {
    label: 'Hombres',
    value: 40,
    color: '#1C5866'
  },
];

export const BY_MUNICIPALITY = [
  { label: "Aguascalientes", value1: 130, value2: 160, value3: 170, value4: 50 },
  { label: "Baja California", value1: 650, value2: 470, value3: 0, value4: 50 },
  { label: "Baja California Sur", value1: 100, value2: 100, value3: 100, value4: 100 },
  { label: "Campeche", value1: 190, value2: 50 },
  { label: "Chiapas", value1: 560, value2: 320 },
  { label: "Chihuahua", value1: 180, value2: 230 },
  { label: "Coahuila", value1: 130, value2: 800 },
  { label: "Colima", value1: 760, value2: 210 },
  { label: "Durango", value1: 480, value2: 150 },
  { label: "A", value1: 130, value2: 950, value3: 210, value4: 10 },
  { label: "B", value1: 130, value2: 550, value3: 50, value4: 110  },
  { label: "Ixtlahuacán de los Membrillos", value1: 130, value2: 650, value3: 110, value4: 140  },
  { label: "Rompiendo Limite de Caracteres", value1: 130, value2: 850, value3: 125, value4: 90  },
  // Agrega más estados según sea necesario
];

export const BY_MUNICIPALITY2 = [
  { label: "Aguascalientes", values: [2, 1, 3, 4] },
  { label: "Baja California", values: [4, 3, 0, 1] },
  { label: "Baja California Sur", values: [2, 1, 2, 3] },
  { label: "Campeche", values: [1, 1, 0, 2] },
  { label: "Chiapas", values: [2, 0, 1, 1] },
  { label: "Chihuahua", values: [4, 7, 2, 1] },
  { label: "Coahuila", values: [2, 0, 8, 1] },
  { label: "Colima", values: [3, 3, 1, 2] },
  { label: "Durango", values: [3, 5, 1, 0] },
  // Agrega más estados según sea necesario
];

export const LOCATION_CONDITION_PER_YEAR = [
  { label: '2018', values: [1100, 1400, 900, 500, 1100, 1400, 900, 500] },
  { label: '2019', values: [1250, 1550, 850, 510, 1250, 1550, 850, 510] },
  { label: '2020', values: [1000, 1300, 950, 700, 1000, 1300, 950, 700] },
  { label: '2021', values: [1120, 1450, 920, 250, 1120, 1450, 920, 250] },
  { label: '2022', values: [1180, 1480, 980, 1200, 1180, 1480, 980, 1200] },
  { label: '2023', values: [1210, 1520, 930, 1050, 1210, 1520, 930, 1050] },
  { label: '30-34', values: [1080, 1420, 900, 1100, 1080, 1420, 900, 1100] },
  { label: '35-39', values: [1150, 1350, 880, 1200, 1150, 1350, 880, 1200] },
  { label: '40-44', values: [1235, 1925, 1205, 1045, 1235, 1925, 1205, 1045] }
];

export const LOCATION_CONDITION_BY_AGE_RANGE = [
  { label: '0-4', values: [1, 1, 2, 0,3,2,1,0] },
  { label: '5-9', values: [1, 1, 0, 2] },
  { label: '10-14', values: [0, 0, 0, 0] }, // No hay datos para este rango de edad
  { label: '15-19', values: [0, 1, 1, 0] },
  { label: '20-24', values: [0, 0, 0, 0] }, // No hay datos para este rango de edad
  { label: '25-29', values: [1, 1, 0, 1] },
  { label: '30-34', values: [0, 0, 0, 0] }, // No hay datos para este rango de edad
  { label: '35-39', values: [0, 0, 0, 0] }, // No hay datos para este rango de edad
  { label: '40-44', values: [0, 1, 1, 0] },
  { label: '45-49', values: [0, 0, 0, 0] }, // No hay datos para este rango de edad
  { label: '50-54', values: [0, 0, 0, 0] }, // No hay datos para este rango de edad
  { label: '55-59', values: [0, 0, 1, 0] },
  { label: '60-64', values: [0, 1, 1, 0] },
  { label: '65-69', values: [0, 0, 0, 0] }, // No hay datos para este rango de edad
  { label: '70-74', values: [1, 0, 0, 0] },
  { label: '75-79', values: [0, 0, 0, 0] }, // No hay datos para este rango de edad
  { label: '80-84', values: [1, 0, 1, 0] },
  { label: '85-89', values: [0, 0, 0, 0] }, // No hay datos para este rango de edad
  { label: '90-94', values: [0, 0, 0, 0] }, // No hay datos para este rango de edad
  { label: '95-99', values: [0, 0, 0, 0] }  // No hay datos para este rango de edad
];


  export const POTENTIAL_CRIME_VICTIMS = [
    { label: '0-4', values: [1100, 1400, 900, 2000, 1500] },
    { label: '5-9', values: [1250, 1550, 850, 2150] },
    { label: '10-14', values: [1000, 1300, 950, 2100] },
    { label: '15-19', values: [1120, 1450, 920, 2250] },
    { label: '20-24', values: [1180, 1480, 980, 2200] },
    { label: '25-29', values: [1210, 1520, 930, 2050] },
    { label: '30-34', values: [1080, 1420, 900, 2100] },
    { label: '35-39', values: [1150, 1350, 880, 2200] },
    { label: '40-44', values: [1235, 1925, 1205, 1045] }
  ];

  export const LOCATED_PEOPLE = [
    { label: 'Con vida', values: [100, 200, 110, 220] },
    { label: 'Sin vida', values: [200, 100, 220, 110] },
  ];

  export const MISSING_PERSONS_LAW = [
    { text: 'Está integrado por sistemas de captura, clasificación, actualización y disposición de los datos y documentos, que concentra la información de los registros de personas desaparecidas del estado de Jalisco. (artículo 4. XXVIII),' },
    { text: 'Corresponde a la Comisión de Búsqueda de Personas del Estado de Jalisco administrar y coordinar su operación. (artículo 82.1)'},
    { text: 'Puede ser consultado en su versión pública a través de la página electrónica que para tal efecto establezca la Comisión de Búsqueda, de conformidad con lo que determine el protocolo respectivo y las disposiciones jurídicas aplicables en materia de transparencia, protección de datos personales y la Ley General de Víctimas. (artículo 82.3)'},
    { text: 'Contiene información sobre las personas que reportan la desaparición (nombre, sexo, edad, relación con la persona reportada como desaparecida, CURP y datos de contacto), sobre las personas que son reportadas como desaparecidas (nombre completo; sexo y género; edad; nacionalidad; escolaridad; ocupación al momento de la desaparición; pertenencia grupal o étnica; características por las que se le puede reconocer y que permitan su identificación como color de pelo y ojos, tatuajes, cicatrices y perforaciones, entre otras; información sobre sus actividades cotidianas y sobre los dispositivos electrónicos que usa y las redes sociales información sobre los hechos relacionados con la desaparición) y sobre las personas servidoras públicas que recibieron el reporte, denuncia o noticia sobre la desaparición y de aquellas que son responsables de coordinar las acciones de búsqueda e investigación. (artículo 84.1)'},
  ];

  export const LAWS_AND_PROTOCOLS_LINK = [
  {
    title: 'Ley General en Materia de Desaparición Forzada de Personas, Desaparición Cometida por Particulares y del Sistema Nacional de Búsqueda de Personas',
    link: 'https://www.diputados.gob.mx/LeyesBiblio/pdf/LGMDFP.pdf',
  },
  {
    title: 'Ley de Personas Desaparecidas del Estado de Jalisco',
    link: 'https://congresoweb.congresojal.gob.mx/bibliotecavirtual/legislacion/Leyes/Ley%20de%20Personas%20Desaparecidas%20del%20Estado%20de%20Jalisco-160321.doc',
  },
  {
    title: 'Ley General de Víctimas',
    link: 'https://www.diputados.gob.mx/LeyesBiblio/pdf/LGMDFP.pdf',
  },
  {
    title: 'Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados',
    link: 'https://www.diputados.gob.mx/LeyesBiblio/pdf/LGPDPPSO.pdf',
  },
  {
    title: 'Ley General de Transparencia y Acceso a la Información Pública',
    link: 'https://www.diputados.gob.mx/LeyesBiblio/pdf/LGTAIP.pdf',
  },
  {
    title: 'Ley Federal de Transparencia y Acceso a la Información Pública',
    link: 'https://www.diputados.gob.mx/LeyesBiblio/pdf/LFTAIP.pdf',
  },
  {
    title: 'Ley Federal de Declaración Especial de Ausencia para Personas Desaparecidas',
    link: 'https://www.diputados.gob.mx/LeyesBiblio/pdf/LFDEAPD_200521.pdf',
  },
  {
    title: 'Ley para la Declaración Especial de Ausencia por Desaparición de Personas del Estado de Jalisco',
    link: 'https://www.diputados.gob.mx/LeyesBiblio/pdf/LFDEAPD_200521.pdf',
  },
  {
    title: 'Principios Rectores para la Búsqueda de Personas Desaparecidas del Comité de las Naciones Unidas contra la Desaparición Forzada',
    link: 'https://www.ohchr.org/sites/default/files/Documents/HRBodies/CED/PrincipiosRectores_DigitalisedVersion_SP.pdf',
  },
  {
    title: 'Protocolo Homologado para la Búsqueda de Personas Desaparecidas y No Localizadas',
    link: 'https://www.dof.gob.mx/nota_detalle.php?codigo=5601905&fecha=06/10/2020#gsc.tab=0',
  },
];


export const municipiosDeJalisco = [
    'GUADALAJARA',
    'ZAPOPAN',
    'SAN PEDRO TLAQUEPAQUE',
    'TLAJOMULCO DE ZUÑIGA',
    'TONALA',
    'EL SALTO',
    'PUERTO VALLARTA',
    'LAGOS DE MORENO',
    'TEPATITLAN DE MORELOS',
    'CHAPALA',
    'IXTLAHUACAN DE LOS MEMBRILLOS',
    'ZAPOTLAN EL GRANDE',
    'JUANACATLAN',
    'TALA',
    'ZAPOTLANEJO',
    'ARANDAS',
    'AMECA',
    'TEQUILA',
    'OCOTLAN',
    'JOCOTEPEC',
    'AUTLAN DE NAVARRO',
    'CIHUATLAN',
    'PONCITLAN',
    'SAN JUAN DE LOS LAGOS',
    'ENCARNACION DE DIAZ',
    'MAZAMITLA',
    'ACATLAN DE JUAREZ',
    'LA BARCA',
    'TEOCALTICHE',
    'GOMEZ FARIAS',
    'COCULA',
    'VILLA CORONA',
    'COLOTLAN',
    'ZACOALCO DE TORRES',
    'ATOTONILCO EL ALTO',
    'JALOSTOTITLAN',
    'SAYULA',
    'AHUALULCO DE MERCADO',
    'SAN MIGUEL EL ALTO',
    'ETZATLAN',
    'AMATITAN',
    'ZAPOTILTIC',
    'EL ARENAL',
    'IXTLAHUACAN DEL RIO',
    'YAHUALICA DE GONZALEZ GALLO',
    'AYOTLAN',
    'LA HUERTA',
    'JAMAY',
    'MAGDALENA',
    'SAN IGNACIO CERRO GORDO',
    'TECOLOTLAN',
    'SAN MARTIN HIDALGO',
    'EL GRULLO',
    'TAMAZULA DE GORDIANO',
    'TUXPAN',
    'TOTOTLAN',
    'OJUELOS DE JALISCO',
    'VILLA PURIFICACION',
    'CASIMIRO CASTILLO',
    'TOMATLAN',
    'ACATIC',
    'TEUCHITLAN',
    'MEZQUITIC',
    'TUXCUECA',
    'CUAUTITLAN DE GARCIA BARRAGAN',
    'TALPA DE ALLENDE',
    'TUXCACUESCO',
    'DEGOLLADO',
    'SAN CRISTOBAL DE LA BARRANCA',
    'UNION DE SAN ANTONIO',
    'HUEJUCAR',
    'SAN MARCOS',
    'AYUTLA',
    'TOLIMAN',
    'SAN GABRIEL',
    'HUEJUQUILLA EL ALTO',
    'UNION DE TULA',
    'QUITUPAN',
    'VILLA GUERRERO',
    'CUQUIO',
    'ATENGO',
    'ZAPOTLAN DEL REY',
    'JESUS MARIA',
    'SAN JUANITO DE ESCOBEDO',
    'TAPALPA',
    'MASCOTA',
    'VALLE DE JUAREZ',
    'BOLAÑOS',
    'TECALITLAN',
    'TEOCUITATLAN DE CORONA',
    'SAN SEBASTIAN DEL OESTE',
    'AMACUECA',
    'CABO CORRIENTES',
    'TIZAPAN EL ALTO',
    'TONILA',
    'GUACHINANGO',
    'ATOYAC',
    'LA MANZANILLA DE LA PAZ',
    'TENAMAXTLAN',
    'ZAPOTITLAN DE VADILLO',
    'CONCEPCION DE BUENOS AIRES',
    'SAN DIEGO DE ALEJANDRIA',
    'TECHALUTA DE MONTENEGRO',
    'SAN MARTIN DE BOLAÑOS',
    'MIXTLAN',
    'CAÑADAS DE OBREGON',
    'TOTATICHE',
    'EJUTLA',
    'JUCHITLAN',
    'SANTA MARIA DE LOS ANGELES',
    'CHIQUILISTLAN',
    'VILLA HIDALGO',
    'VALLE DE GUADALUPE',
    'ATEMAJAC DE BRIZUELA',
    'PIHUAMO',
    'HOSTOTIPAQUILLO',
    'SAN JULIAN',
    'ATENGUILLO'
  ];
