import React, { useEffect, useState, useCallback } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { Box, Typography, Container, Grid } from "@mui/material";
import { useGlobalContext } from "../context/GlobalContext"; 
import { useStatisticsContext } from "../context/StatisticsPageContext";
import StatisticsFilter from "../components/organisms/StatisticsFilter/StatisticsFilter";
import BarChart from "../components/moleculas/BarChart/BarChart";
import HorizontalBarChart from "../components/moleculas/HorizontalBarChart/HorizontalBarChart";
import VerticalBarChart from "../components/moleculas/VerticalBarChart/VerticalBarChart";
import { 
  LEGEND, LEGEND_BY_CRIME, LEGEND_MISSING_CRIME, 
  LEGEND_BY_GENDER, isMobile, municipiosDeJalisco,  
} from "../utils/constants";
import { 
  getSexesMissingByMunicipality, getAgeRangeClassification, getLocalizedAgeRange, 
  getLocatedByCrimeVictim, getLocatedByYearSexAndCondition, getSexesLocatedByMunicipality, 
  getYearRangeMissingClassification 
} from "../api/graphics";
import { ICO_DOWNLOAD } from "../assets";
import { TooltipLocalizados, TooltipLocalizadosDelito, TooltipDesaparecidos } from "../utils/constants";
import styles from "./EstadisticasStyles";
import StatisticsCards from "../components/moleculas/StatisticsCards/StatisticsCards";

const Estadisticas = () => {
  const { fullDateFormat, updateDate, apiUrl } = useGlobalContext();
  const { handleGetCardData } = useStatisticsContext();

  const [municipioData, setMunicipioData] = useState([]);
  const [municipioMissingData, setMunicipioMissingData] = useState([]);
  const [anioData, setAnioData] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [crimeData, setCrimeData] = useState([]);
  const [ageMissingData, setAgeMissingData] = useState([]);
  const [crimeMissingData, setCrimeMissingData] = useState([]);
  const [downloadBtnSmall, setDownloadBtnSmall] = useState(isMobile);
  const [downloadBtnAnimatedFirstTime, setDownloadBtnAnimatedFirstTime] = useState(false);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [day, setDay] = useState(null);
  const [onlyDay, setOnlyDay] = useState(null);
  const [updateDay, setUpdateDay] = useState(null);

  const getByMunicipality = useCallback(async () => {
    setLoading(true);
    getSexesLocatedByMunicipality()
      .then((result) => {
        const data = result.data.resultados;
  
        let municipioData = [];
        let otrosData = {
          label: 'Otros',
          value1: 0,
          value2: 0,
          value3: 0,
          value4: 0,
          total: 0,
        };
  
        data?.forEach((m) => {
          let municipio = m.municipio_localizacion;
          let hombre = 0;
          let mujer = 0;
          let intersexual = 0;
          let seIgnora = 0;
  
          m.desglose_sexo.forEach((sexo) => {
            const { sexo_persona, total } = sexo;
            if (sexo_persona === 'HOMBRE') {
              hombre = total;
            }
            if (sexo_persona === 'MUJER') {
              mujer = total;
            }
            if (sexo_persona === 'INTERSEXUAL') {
              intersexual = total;
            }
            if (sexo_persona === 'SE IGNORA') {
              seIgnora = total;
            }
          });
  
          const totalPersonas = mujer + hombre + intersexual + seIgnora;
  
          if (esMunicipioDeJalisco(municipio)) {
            municipioData.push({
              label: municipio,
              value1: mujer,
              value2: hombre,
              value3: intersexual,
              value4: seIgnora,
              total: totalPersonas,
            });
          } else {
            otrosData.value1 += mujer;
            otrosData.value2 += hombre;
            otrosData.value3 += intersexual;
            otrosData.value4 += seIgnora;
            otrosData.total += totalPersonas;
          }
  
          hombre = 0;
          mujer = 0;
          intersexual = 0;
          seIgnora = 0;
        });
  
        municipioData.sort((a, b) => b.total - a.total);
  
        if (otrosData.total > 0) {
          municipioData.push(otrosData);
        }
  
        setMunicipioData(municipioData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener datos de la API:', error);
        setLoading(false);
      });
  }, []);
  
  const esMunicipioDeJalisco = (municipio) => {
    return municipiosDeJalisco.includes(municipio);
  };

  const getByCondition = async () => {
    setLoading(true);
    getLocatedByYearSexAndCondition()
    .then((result) => {
      const data = result.data;

      const genderMap = {
        'HOMBRE': new Map(),
        'MUJER': new Map(),
        'INTERSEXUAL': new Map(),
        'SE IGNORA': new Map()
      };
      
      const formatData = [];
      const yearsData = [];
      
      Object.keys(data).forEach((genre) => {
        yearsData.push(...Object.keys(data[genre]));
      
        if (genderMap[genre]) {
          yearsData.forEach((year) => {
            let conVida = 0;
            let sinVida = 0;

            data[genre][year].forEach((condicion) => {
              if (condicion.condicion_desc === "CON VIDA") {
                conVida = condicion.total
              }
              if (condicion.condicion_desc === "SIN VIDA") {
                sinVida = condicion.total
              }
            })
            genderMap[genre].set(year, [conVida, sinVida]);
          });
        }
      });

    const uniqueData = [...new Set(yearsData)].sort((a, b) => b - a);

    uniqueData.forEach((year) => {
      let val = [];
      const values = val.concat(
        (genderMap['MUJER'].get(year) || [0, 0]),
        (genderMap['HOMBRE'].get(year) || [0, 0]),
        (genderMap['INTERSEXUAL'].get(year) || [0, 0]),
        (genderMap['SE IGNORA'].get(year) || [0, 0])
      );

      formatData.push({
        label: `${year}`,
        values: values,
        legend: TooltipLocalizados
      });
    });
      setAnioData(formatData);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error al obtener datos de la API:', error);
      setLoading(false);
    });
  };

  const getByAgeRange = async () => {
    setLoading(true);
    getLocalizedAgeRange()
      .then((result) => {
        let data = result.data;

        const genderMap = {
          'HOMBRE': new Map(),
          'MUJER': new Map(),
          'INTERSEXUAL': new Map(),
          'SE IGNORA': new Map()
        };

        const formatData = [];
        const ageData = [];

        data?.forEach((option) => {
          let genero = option.sexo;

          if (genderMap[genero]) {
            option.desglose_rango_edad.forEach((edad) => {
              let rangoEdad = edad.rango_edad;
              ageData.push(rangoEdad);
              let totalvida = 0;
              let totalsinvida = 0;

              edad.desglose_condicion_localizacion.forEach((condicion) => {

                if (condicion.cat_condicion_localizacion_id === "CON VIDA") {
                  totalvida = condicion.total;
                }

                if (condicion.cat_condicion_localizacion_id === "SIN VIDA") {
                  totalsinvida = condicion.total;
                } 
              })
              genderMap[genero].set(rangoEdad, [(totalvida || 0), (totalsinvida || 0)]);
            })
          }
        })

        const uniqueAge = [...new Set(ageData)];

        uniqueAge.forEach((age) => {
          let val = [];
          const values = val.concat(
            (genderMap['MUJER'].get(age) || [0, 0]),
            (genderMap['HOMBRE'].get(age) || [0, 0]),
            (genderMap['INTERSEXUAL'].get(age) || [0, 0]),
            (genderMap['SE IGNORA'].get(age) || [0, 0])
          )

          formatData.push({
            label: `${age}`,
            values: values,
            legend: TooltipLocalizados
          });
        });
        setAgeData(formatData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener datos de la API:', error);
        setLoading(false);
      });
  }

  const getByCrime = async () => {
    setLoading(true);
    getLocatedByCrimeVictim()
    .then((result) => {
      const data = result.data;

      const genderMap = {
        'HOMBRE': new Map(),
        'MUJER': new Map(),
        'INTERSEXUAL': new Map(),
        'SE IGNORA': new Map()
      };
      
      const formatData = [];
      const yearsData = [];
      
      Object.keys(data).forEach((genre) => {
        yearsData.push(...Object.keys(data[genre]));
      
        if (genderMap[genre]) {
          yearsData.forEach((year) => {
            let noVictima = 0;
            let victima = 0;

            data[genre][year].forEach((condicion) => {
              if (condicion.clasificacion_desc === "SIN SER VICTIMA DE DELITO") {
                noVictima = condicion.total
              }
              if (condicion.clasificacion_desc === "VICTIMA DE DELITO CONTEMPLADO EN LA LEY GENERAL") {
                victima = condicion.total
              }
              if (condicion.clasificacion_desc === "VICTIMA DE DELITO DIVERSO") {
                victima += condicion.total
              }
            })

            genderMap[genre].set(year, [victima, noVictima]);
          });
        }
      });

      const uniqueData = [...new Set(yearsData)].sort((a, b) => b - a);
      
      uniqueData.forEach((year) => {
        let val = [];
        const values = val.concat(
          (genderMap['MUJER'].get(year) || [0, 0]),
          (genderMap['HOMBRE'].get(year) || [0, 0]),
          (genderMap['INTERSEXUAL'].get(year) || [0, 0]),
          (genderMap['SE IGNORA'].get(year) || [0, 0])
        );
      
        formatData.push({
          label: `${year}`,
          values: values,
          legend: TooltipLocalizadosDelito
        });
      });
      setCrimeData(formatData);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error al obtener datos:", error);
      setLoading(false);
    });
  }

  const getByMissingMunicipality = async () => {
    setLoading(true);
    getSexesMissingByMunicipality()
      .then((result) => {
        const data = result.data.resultados;

        let formatData = [];
        let municipio = '';
        let hombre = 0;
        let mujer = 0;
        let intersexual = 0;
        let seIgnora = 0;

        data?.forEach((m) => {
          municipio = m.municipio_desaparicion;
          m.desglose_sexo.forEach((sexo) => {
            const { sexo_persona, total } = sexo;
            if (sexo_persona === 'HOMBRE') {
              hombre = total
            }
            if (sexo_persona === 'MUJER') {
              mujer = total
            }
            if (sexo_persona === 'INTERSEXUAL') {
              intersexual = total
            }
            if (sexo_persona === 'SE IGNORA') {
              seIgnora = total
            }
          })
          formatData.push({label: `${municipio}`, value1: mujer, value2: hombre, value3: intersexual, value4: seIgnora})
          hombre = 0;
          mujer = 0;
          intersexual = 0;
          seIgnora = 0;
        })
        setMunicipioMissingData(formatData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener datos de la API:', error);
        setLoading(false);
      });
  };

  const getByAgeRangeMissing = async () => {
    setLoading(true);
    getAgeRangeClassification()
    .then((result) => {
      let data = result.data;

      const genderMap = {
        'HOMBRE': new Map(),
        'MUJER': new Map(),
        'INTERSEXUAL': new Map(),
        'SE IGNORA': new Map()
      };

      const formatData = [];
      const ageData = [];

      data?.forEach((option) => {
        let genero = option.sexo;

        if (genderMap[genero]) {
          option.desglose_rango_edad.forEach((edad) => {
            let rangoEdad = edad.rango_edad;
            ageData.push(rangoEdad);
            let conViolencia = 0;
            let sinViolencia = 0;

            edad.desglose_uso_violencia.forEach((condicion) => {

              if (condicion.uso_violencia_desaparicion === "SI") {
                conViolencia = condicion.total;
              }

              if (condicion.uso_violencia_desaparicion === "SE IGNORA") {
                sinViolencia += condicion.total;
              }

              if (condicion.uso_violencia_desaparicion === "NO") {
                sinViolencia += condicion.total;
              }
            })
            genderMap[genero].set(rangoEdad, [(conViolencia || 0), (sinViolencia || 0)]);
          })
        }
      })

      const uniqueAge = [...new Set(ageData)];

      uniqueAge.forEach((age) => {
        let val = [];
        const values = val.concat(
          (genderMap['MUJER'].get(age) || [0, 0]),
          (genderMap['HOMBRE'].get(age) || [0, 0]),
          (genderMap['INTERSEXUAL'].get(age) || [0, 0]),
          (genderMap['SE IGNORA'].get(age) || [0, 0])
        )

        formatData.push({
          label: `${age}`,
          values: values,
          legend: TooltipDesaparecidos
        });
      });
        setAgeMissingData(formatData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener datos de la API:', error);
        setLoading(false);
      });
  }

  const getByCrimeMissing = async () => {
    setLoading(true);
    getYearRangeMissingClassification()
    .then((result) => {
      const data = result.data;

      const genderMap = {
        'HOMBRE': new Map(),
        'MUJER': new Map(),
        'INTERSEXUAL': new Map(),
        'SE IGNORA': new Map()
      };
      
      const formatData = [];
      const yearsData = [];
      
      Object.keys(data).forEach((genre) => {
        yearsData.push(...Object.keys(data[genre]));
      
        if (genderMap[genre]) {
          yearsData.forEach((year) => {
            let conViolencia = 0;
            let sinViolencia = 0;
            data[genre][year].forEach((yeardata) => {
              if (yeardata.uso_violencia_desaparicion === "NO") {
                sinViolencia = yeardata.total;
              }
              if (yeardata.uso_violencia_desaparicion === "SE IGNORA") {
                sinViolencia += yeardata.total;
              }
              if (yeardata.uso_violencia_desaparicion === "SI") {
                conViolencia = yeardata.total;
              }
            })
            genderMap[genre].set(year, [conViolencia, sinViolencia]);
          });
        }
      });

      const uniqueData = [...new Set(yearsData)].sort((a, b) => b - a);
      
      uniqueData.forEach((year) => {
        let val = [];
        const values = val.concat(
          (genderMap['MUJER'].get(year) || [0, 0]),
          (genderMap['HOMBRE'].get(year) || [0, 0]),
          (genderMap['INTERSEXUAL'].get(year) || [0, 0]),
          (genderMap['SE IGNORA'].get(year) || [0, 0])
        );
      
        formatData.push({
          label: `${year}`,
          values: values,
          legend: TooltipDesaparecidos
        });
      });
      setCrimeMissingData(formatData);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error al obtener datos:", error);
      setLoading(false);
    });
  }

  useEffect(() => {
    getByMunicipality();
    getByCondition();
    getByAgeRange();
    getByCrime();
    getByMissingMunicipality();
    getByAgeRangeMissing();
    getByCrimeMissing(); 

    setDay(`Sólo se contabilizan las localizaciones confirmadas del 1 de diciembre de 2018 al ${fullDateFormat}`);
    setOnlyDay(fullDateFormat)
    setUpdateDay(updateDate.format('DD/MM/YYYY'))

    window.addEventListener('scroll', () => {
      if(!isMobile){ 
        if (window.scrollY > 200) {
          setDownloadBtnSmall(true);
          setDownloadBtnAnimatedFirstTime(true);
        } else {
          setDownloadBtnSmall(false);
        }
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByMunicipality]);

  const GraphLocatedComponent = () => {
    let component;
    if (isMobile) {
      component = <VerticalBarChart
      title={"Personas localizadas"}
      subTitle={"Por municipio y sexo"}
      legend={LEGEND_BY_GENDER}
      data={municipioData}
      text={day}
      xLegend={"Municipios"}
      yLegend={"Personas localizadas"}
      />
    } else {
      component = <HorizontalBarChart
      title={"Personas localizadas"}
      subTitle={"Por municipio y sexo"}
      legend={LEGEND_BY_GENDER}
      data={municipioData}
      text={day}
      xLegend={"Personas localizadas"}
      yLegend={"Municipios"}
    />
    }
    return component;
  }

  const GraphMissingComponent = () => {
    let component;
    if (isMobile) {
      component = <VerticalBarChart
      title={"Personas desaparecidas"}
      subTitle={"Por municipio y sexo"}
      legend={LEGEND_BY_GENDER}
      data={municipioMissingData}
      text={`Al ${onlyDay}`}
      xLegend={"Municipios"}
      yLegend={"Personas localizadas"}
      />
    } else {
      component = <HorizontalBarChart
      title={"Personas desaparecidas"}
      subTitle={"Por municipio y sexo"}
      legend={LEGEND_BY_GENDER}
      data={municipioMissingData}
      text={`Al ${onlyDay}`}
      xLegend={"Personas desaparecidas"}
      yLegend={"Municipios"}
      />
    }
    return component;
  }

  const handleClickDownload = () => {
    ReactGA.event({
      category: 'Estadisticas',
      action: 'Descargo excel datos abiertos',
      label: 'Descargo el excel de los registros de jalisco'
    });
  }

  useEffect(() => {
    handleGetCardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="main" sx={{ backgroundColor: "#FAFAFA" }}>
      <Container maxWidth={false} sx={{ px: 0, pb: {xs: 5, sm: 10}, pt: {xs: 8, sm: 11}}}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center"}}>
              <Typography sx={{ color: "#1C5866", fontSize: "50px"}}>
              Estadísticas
              </Typography>
              <Typography sx={{ color: "#3B4348", fontSize: "15px" }}>
              {`*Información actualizada al ${updateDay}`}
              </Typography>
                <Box sx={{ position: "fixed", right: {xs: "10px", md: "100px"}, bottom:"100px", overflow: 'hidden', top: {md: "150px"}, zIndex: 2, height: '3rem' }}>
                  <Link 
                    to={`${apiUrl}version_publica/exportrebdboptimizado/xls/`}
                    onClick={handleClickDownload}
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    download
                  >
                    <Box
                      sx={{
                        ...(downloadBtnSmall ? 
                              styles.animatedButtonSmall : 
                              (downloadBtnAnimatedFirstTime ? styles.animatedButton : {})
                        ),
                        py: 1,
                        fontWeight: 'bold',
                        borderRadius: 50, 
                        px: '35px',
                        margin: 'auto',
                        display: 'flex',
                        backgroundColor: '#C0E4D9',
                        color: '#1C5866',
                        textTransform: 'capitalize !important',
                        "&:hover": {
                            backgroundColor: "#C0E4D9"
                        }
                      }}
                    >
                      <Box component={'img'} 
                        src={ICO_DOWNLOAD}
                        alt="Descargar datos"
                        sx={{
                          width: '20px', 
                          height: '20px', 
                          marginRight: '30px',
                          ...(downloadBtnSmall ? 
                                styles.animatedButtonIconSmall : 
                                (downloadBtnAnimatedFirstTime ? styles.animatedButtonIcon : {})
                          )
                        }}
                      />
                      <Typography variant="body1" 
                        sx={{ 
                          ...(downloadBtnSmall ? 
                                styles.animatedButtonTextSmall : 
                                (downloadBtnAnimatedFirstTime ? styles.animatedButtonText : {})
                          ),
                          color: "#1C5866", 
                          fontSize: "15px", 
                          whiteSpace: 'nowrap' 
                        }}>
                        Descargar datos
                      </Typography>
                    </Box>
                  </Link>
                </Box>
            </Box>
            <StatisticsCards />
            <StatisticsFilter setStatus={setStatus} loading={loading}/>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: window.innerWidth - 50, margin: "auto" }}>
            {status === 'localizado' || status === '' ? (
              <>
                <GraphLocatedComponent />
                <BarChart
                  subTitle={"Por año de localización, sexo y condición de localización"}
                  legend={LEGEND}
                  data={anioData}
                  MaxGender
                  text={day}
                  xLegend={"Año de localización"}
                  yLegend={"Personas localizadas"}
                />
                <BarChart
                  subTitle={"Por rango de edad, sexo y condición de localización"}
                  legend={LEGEND}
                  data={ageData}
                  MaxGender
                  text={day}
                  xLegend={"Rango de edad"}
                  yLegend={"Personas localizadas"}
                />
                <BarChart
                  subTitle={"Por año de localización, sexo y si fueron víctimas de delito o no"}
                  legend={LEGEND_BY_CRIME}
                  data={crimeData}
                  MaxGender
                  text={day}
                  xLegend={"Año de localización"}
                  yLegend={"Personas localizadas"}
                  endLegend
                />
              </>
            ) : '' }
            {status === 'desaparecido' || status === '' ? (
              <>
                <GraphMissingComponent />
                <BarChart
                subTitle={"Por rango de edad, sexo y clasificación inicial de los hechos"}
                legend={LEGEND_MISSING_CRIME}
                data={ageMissingData}
                MaxGender
                text={`Personas reportadas como desaparecidas al ${onlyDay}`}
                xLegend={"Rango de edad"}
                yLegend={"Personas desaparecidas"}
                endLegendMissing={"* Se consideran sólo las personas que a la fecha de corte contaban con denuncia por desaparición ante la Fiscalía Especial en Personas Desaparecidas."}
                />
                <BarChart
                subTitle={"Por año de reporte, tipo de clasificación inicial del hecho y sexo"}
                legend={LEGEND_MISSING_CRIME}
                data={crimeMissingData}
                MaxGender
                text={`Personas reportadas como desaparecidas al ${onlyDay}`}
                xLegend={"Año de reporte"}
                yLegend={"Personas desaparecidas"}
                endLegendMissing={"* Se consideran sólo las personas que a la fecha de corte contaban con denuncia por desaparición ante la Fiscalía Especial en Personas Desaparecidas."}
                />
              </>
            ) : ''}
            </Box>
          </Grid>     
        </Grid>
      </Container>
    </Box>
  );
};

export default Estadisticas;
