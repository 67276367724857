const styles = {

  container: {
    px: 2
  },
  title: {
    textAlign: 'left',
    color: '#1C5866',
    fontSize: '45px',
    marginBottom: '56px',
    lineHeight: '1.2'
  },
  text: {
    textAlign: 'left',
    color: '#1C5866 !important',
    fontSize: '22px',
    fontFamily: 'Geologica Regular !important'
  },
  index: {
    backgroundColor: '#C0E4D9', 
    borderRadius: 100,
    height: 60, 
    width: 60,
    color: '#1C5866',
    fontWeight: 'bold',
    fontSize: '28px',
    textAlign: 'center',
    margin: 'auto',
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
}

export default styles;
