import React from "react";

import { TemplatePageContextProvider } from 'Context/AppContext'

const ContextProvider = ({ children }) => {
    return (
        <TemplatePageContextProvider>
            {children}
        </TemplatePageContextProvider>
    )
}

export default ContextProvider;