const styles = {
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: 2
    },
    titleText: {
        width: {xs: '100%', md: '75%'},
        color: '#C0E4D9',
        textAlign: 'center',
        fontSize: { xs: 40, md: 58 },
        lineHeight: 1.2,
        fontFamily: 'Geologica Bold',
        mx: {xs: 2, md: 0},
        mb: {xs: 8, md: 4}  
    }
}

export default styles;