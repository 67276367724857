const styles = {
  container: {
    width: '100%',
    margin: 'auto',
    px: '5rem',
    py: '3rem',
    display: 'grid',
    flexWrap: 'wrap',
    "@media (max-width: 767px)": {
      padding: '.25rem',
    }
  },

  svgContainer: {
    overflow: 'auto'
  },

  title: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#1C5866',
    opacity: 1,
    fontSize: '44px'
  },
  legend: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 'auto',
    flexWrap: 'wrap',
    width: "100%",
  },
  item: {
    display: "flex",
    alignItems: "start",
    lineHeight: "1.20rem",
    marginLeft: ".75rem",
  },
  colorBox: {
    width: "24px",
    height: "24px",
    marginRight: "8px",
    marginTop: "8px"
  },
  textColor: {
    color: '#717177',
    width: '110px'
  },

  Customcontainer: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    padding: '1rem',
    display: 'grid',
  },

  legendContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 'auto',
    flexWrap: 'wrap',
    marginLeft: '2.5rem'
  },

  colorCircle: {
    width: "18px",
    height: "18px",
    marginRight: "8px",
    borderRadius: '50%',
  },

  textLegend: {
    color: '#717177',
    width: '120px'
  },
  legendx: {
    textAlign: 'center',
    letterSpacing: '0px',
    fontSize: '14px',
    fontFamily: 'Geologica Regular !important',
  },
  legendy: {
    display: 'flex',
    textAlign: 'center',
    letterSpacing: '0px',
    fontSize: '14px',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    height: '50%',
    justifyContent: 'center',
    width: '0%',
    fontFamily: 'Geologica Regular !important',
  }
}

export default styles;
