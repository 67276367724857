import React, { useEffect, useState } from 'react';
import styles from './styles.js';
import { Box, Typography, Container, Grid } from '@mui/material';
import { INTERSEXUAL_ICON_FONDO, HOMBRE_ICON, PERSONAS_LOCALIZADAS_ICON, MUJER_ICON, PERSONAS_DESAPARECIDAS_ICON, MUJER_RED_ICON, HOMBRE_RED_ICON, HAND_POINTER_ICON, SEIGNORA_ICON_FONDO, SEIGNORA_RED_ICON_FONDO, INTERSEXUAL_RED_ICON_FONDO } from '../../../assets/index.js';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../context/GlobalContext.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import BaseButton from '../BaseButton.js';
import ChartMap from './ChartMap.js';
import Services from '../../../services';
import CustomBarChart from '../BarChart/CustomBarChart.js';
import { LEGEND_BY_GENDER } from "../../../utils/constants.js";

const { mapService } = Services;

const Map = () => {
    const navigate = useNavigate();
    const { fullDateFormat } = useGlobalContext();
    const [data, setData] = useState({});
    const [dataBar, setDataBar] = useState([]);

    const navigateToEstadisticas = () => {
        navigate('/estadisticas');
    };

    const getMap = async () => {
        mapService
            .getMapData()
            .then((result) => {
                setData(result.data);

                let dataBar = [
                    { label: 'Con vida', values: [result?.data?.["PERSONA LOCALIZADA"]?.CONDICION["CON VIDA"]?.MUJER || 0, result?.data?.["PERSONA LOCALIZADA"]?.CONDICION["CON VIDA"]?.HOMBRE || 0, result?.data?.["PERSONA LOCALIZADA"]?.CONDICION["CON VIDA"]?.INTERSEXUAL || 0 , result?.data?.["PERSONA LOCALIZADA"]?.CONDICION["CON VIDA"]?.["SE IGNORA"] || 0] },
                    { label: 'Sin vida', values: [result?.data?.["PERSONA LOCALIZADA"]?.CONDICION["SIN VIDA"]?.MUJER || 0, result?.data?.["PERSONA LOCALIZADA"]?.CONDICION["SIN VIDA"]?.HOMBRE || 0, result?.data?.["PERSONA LOCALIZADA"]?.CONDICION["SIN VIDA"]?.INTERSEXUAL || 0 , result?.data?.["PERSONA LOCALIZADA"]?.CONDICION["SIN VIDA"]?.["SE IGNORA"] || 0] },
                ]
                setDataBar(dataBar);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getMap();
    }, []); 

    return (
        <Box component="main">
            <Container maxWidth="xl" sx={{ px: 0, my: { xs: 4, sm: 6 } }}>
                <Grid container spacing={0} sx={styles.grid}>
                    <Grid item xs={12} sx={styles.gridBox}>
                        <Box sx={{ ...styles.box, display: 'flex' }}>
                            <Typography sx={{ ...styles.titleText, color: '#1C5866', textAlign: 'center', fontSize: { xs: 40, sm: 58 } }}>Personas localizadas y desaparecidas en el Estado de Jalisco</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ ...styles.gridBox, mt: 4, mb: 10, display: 'flex' }}>
                        <BaseButton onClick={navigateToEstadisticas} minwidth={410}>{'Ir a estadísticas'}</BaseButton>
                    </Grid>
                    <Grid item md={5} sx={styles.gridBox}>
                        <Box sx={styles.boxMap}>
                            <Card sx={styles.card}>
                                <CardMedia sx={styles.imageCard} image={PERSONAS_LOCALIZADAS_ICON} alt="Icono Personas" />
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.cardText}>{data["PERSONA LOCALIZADA"]?.TOTAL || 0} personas localizadas desde el 1 de diciembre de 2018 al {fullDateFormat}</Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ ...styles.card, width: { xs: '100%', sm: '48.5%' } }}>
                                <CardMedia sx={styles.imageCard} image={MUJER_ICON} alt="Icono Personas" />
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.cardText}>{data?.["PERSONA LOCALIZADA"]?.["POR SEXO"]?.MUJER || 0} Mujeres</Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ ...styles.card, width: { xs: '100%', sm: '48.5%' } }}>
                                <CardMedia sx={styles.imageCard} image={HOMBRE_ICON} alt="Icono Personas" />
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.cardText}>{data?.["PERSONA LOCALIZADA"]?.["POR SEXO"]?.HOMBRE || 0} Hombres</Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ ...styles.card, width: { xs: '100%', sm: '48.5%' } }}>
                                <CardMedia sx={styles.imageCard} image={INTERSEXUAL_ICON_FONDO} alt="Icono Personas" />
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.cardText}>{data?.["PERSONA LOCALIZADA"]?.["POR SEXO"]?.INTERSEXUAL || 0} Intersexuales</Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ ...styles.card, width: { xs: '100%', sm: '48.5%' } }}>
                                <CardMedia sx={styles.imageCard} image={SEIGNORA_ICON_FONDO} alt="Icono Personas" />
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.cardText}>{data?.["PERSONA LOCALIZADA"]?.["POR SEXO"]?.["SE IGNORA"] || 0} Se ignora</Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ ...styles.card, justifyContent: 'center', }}>
                                <CardContent sx={{ ...styles.cardContent, minHeight: 100 }}>
                                    <CustomBarChart legend={LEGEND_BY_GENDER} data={dataBar} />
                                </CardContent>
                            </Card>
                            <Card sx={{ ...styles.card, borderColor: '#DE5E60' }}>
                                <CardMedia sx={styles.imageCard} image={PERSONAS_DESAPARECIDAS_ICON} alt="Icono Personas" />
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.cardText}>{data["PERSONA DESAPARECIDA"]?.TOTAL || 0} personas desaparecidas</Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ ...styles.card, width: { xs: '100%', sm: '48.5%' }, borderColor: '#DE5E60ed' }}>
                                <CardMedia sx={styles.imageCard} image={MUJER_RED_ICON} alt="Icono Personas" />
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.cardText}>{data?.["PERSONA DESAPARECIDA"]?.["POR SEXO"]?.MUJER || 0} Mujeres</Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ ...styles.card, width: { xs: '100%', sm: '48.5%' }, borderColor: '#DE5E60' }}>
                                <CardMedia sx={styles.imageCard} image={HOMBRE_RED_ICON} alt="Icono Personas" />
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.cardText}>{data?.["PERSONA DESAPARECIDA"]?.["POR SEXO"]?.HOMBRE || 0} Hombres</Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ ...styles.card, width: { xs: '100%', sm: '48.5%' }, borderColor: '#DE5E60' }}>
                                <CardMedia sx={styles.imageCard} image={INTERSEXUAL_RED_ICON_FONDO} alt="Icono Personas" />
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.cardText}>{data?.["PERSONA DESAPARECIDA"]?.["POR SEXO"]?.INTERSEXUAL || 0} Intersexuales</Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ ...styles.card, width: { xs: '100%', sm: '48.5%' }, borderColor: '#DE5E60' }}>
                                <CardMedia sx={styles.imageCard} image={SEIGNORA_RED_ICON_FONDO} alt="Icono Personas" />
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.cardText}>{data?.["PERSONA DESAPARECIDA"]?.["POR SEXO"]?.["SE IGNORA"] || 0} Se ignora</Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7} sx={{ ...styles.gridBox, flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <Box sx={{ ...styles.boxHelper, pt: 0 }}>
                            <Card sx={{ ...styles.helperCard, pl: 3 }}>
                                <CardMedia sx={styles.imageIcon} image={HAND_POINTER_ICON} alt="Icono de seleccion" />
                                <CardContent sx={{ ...styles.cardContent, pl: 2 }}>
                                    <Typography sx={{ ...styles.cardText, color: '#1C5866', ml: 1, mb: 2, width: { xs: '95%', md: '100%' }, fontSize: 18 }}>Pulsa sobre el mapa para ver información específica de cada municipio</Typography>
                                    <Typography sx={{ ...styles.cardText, fontFamily: 'Geologica Regular !important', fontSize: 16, color: '#1C5866', ml: 1, mt: 1, fontWeight: '200 !important' }}>{`*Toda la información mostrada en este mapa está actualizada a la fecha de corte al ${fullDateFormat}`}</Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box sx={styles.gridBox}>
                            <ChartMap />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Map;
