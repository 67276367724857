import React from 'react';
import { Box, Modal, Typography, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import styles from './styles';
import { ERROR_ICON, OK_ICON } from '../../../assets';

const Alert = ({ type, open, onClose, title, description }) => {

    return (
        <Modal open={open} sx={styles.modal} onClose={onClose}>
            <Box sx={styles.alertContainer}>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        color: 'gray',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={styles.itemContainer}>
                    <img src={type === "ok" ? OK_ICON : ERROR_ICON} style={styles.img} alt={'Ok-error icon'} />
                    <Box>
                        <Typography sx={styles.text}>
                            <b>{title}</b>
                        </Typography>
                        <Typography sx={styles.text}>
                            {description}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default Alert;
