import React, { createContext, useContext, useState } from 'react';
import { testConnection } from 'Services/apiTemplate';
import {getMunicipalities, getStates, getTypesOfSex} from "Api/searchFilter.api"

const TemplatePageContext = createContext(null);
const modalContext = createContext();
const modalToggleContext = createContext();

export function useModalContext() {
    return useContext(modalContext);
}

export function useModalToggleContext() {
    return useContext(modalToggleContext);
}

let isLoadingMunicipalities = false;

const TemplatePageContextProvider = ({ children }) => {    
    const [testText, setTestText] = useState({
        status: 'Sin realizar prueba',
        text: ''
    })
    const [viewModal, setviewModal] = useState(true);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [states, setStates] = useState([]);
    const [municipalities, setMunicipalities] = useState({});
    const [typesOfSex, setTypesOfSex] = useState([]);

    const changeModal = () => {
        if (viewModal) {
            setviewModal(false);
        } else {
            setviewModal(true);
        }
    }

    const handleSetTestText = (text) => setTestText(text)

    const handleTestConnectionEndpoint = () => {
        const credentialsLogin =  JSON.stringify({
            username: 'prueba',
            password: '0000',
        })

        testConnection(credentialsLogin).then((result) => {
            console.info("testConnection[Success]", result)
            handleSetTestText({
                status: 'Comunicacion con API',
                text: JSON.stringify(result)
            })
        }).catch((error) => {
            console.info("testConnection[Error]", error?.response?.data)
            handleSetTestText({
                status: 'Comunicacion con API',
                text: error?.response?.data?.detail
            })
        })
    }

    const cleanStateTemplateContext = () => {
        handleSetTestText({
            status: 'Sin realizar prueba',
            text: ''
        })
    }

    const loadStates = async () => {
        if (states.length) return;
        const response = await getStates();
        if (response.results) {
            const filteredStates = response.results.filter(state => state.descripcion !== 'SE IGNORA' && state.descripcion !== 'NO APLICA');
            
            setStates(filteredStates);
        }
    }

    const loadMunicipalities = async (stateId) => {
        if (municipalities[stateId] || isLoadingMunicipalities) return;

        isLoadingMunicipalities = true;

        try {
            const response = await getMunicipalities(stateId, 600);
            if (response.results) {
                const filteredMunicipalities = response.results.filter(municipality => municipality.descripcion !== 'SE IGNORA');
                setMunicipalities({ ...municipalities, [stateId]: filteredMunicipalities });
            }
            isLoadingMunicipalities = false;
        } catch (error) {
            isLoadingMunicipalities = false;
        }
    }

    const loadTypesOfSex = async () => {
        if (typesOfSex.length) return;

        const response = await getTypesOfSex();
        if (response.results) {
            const filteredTypesOfSex = response.results.filter(type => type.descripcion !== 'SE IGNORA');
            setTypesOfSex(filteredTypesOfSex);
        }
    }

    const forContextData = {
        cleanStateTemplateContext, 
        handleTestConnectionEndpoint, 
        testText,
        handleSetTestText,
        viewModal,
        changeModal,
        mobileMenuOpen,
        setMobileMenuOpen,
        loadStates,
        loadMunicipalities,
        loadTypesOfSex,
        states,
        municipalities,
        typesOfSex
    }

    return (
        <TemplatePageContext.Provider value={forContextData} children={children} />
    )
}

TemplatePageContext.displayName = 'TemplatePageContext';

const useTemplateContext = () => {
    const context = useContext(TemplatePageContext);
    if (!context) {
        throw new Error('useTemplateContext debe usarse dentro de un TemplatePageContextProvider');
    }
    return context;
};

export { TemplatePageContextProvider, TemplatePageContext, useTemplateContext };