import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.white,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            boxShadow: theme.shadows[4],
            fontSize: 16,
            lineHeight: 1.2,
            color: '#1C5866', 
            fontFamily: 'Geologica Bold',
            borderRadius: '25px',
            padding: '16px',
        },
    }
));

export default LightTooltip;