import ReactGA from "react-ga4";
import { saveAs } from 'file-saver';
import { pdf, Page, Font, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import GeologicaBold from 'Assets/fonts/Geologica-Bold.ttf';
import GeologicaRegular from 'Assets/fonts/Geologica-Regular.ttf';
import NutmegUltraBlack from 'Assets/fonts/Nutmeg-Ultra-Black.otf';
import NutmegBold from 'Assets/fonts/Nutmeg-Bold.otf';
import MontserratRegular from 'Assets/fonts/Montserrat-Regular.ttf';
import { COMISION_BUSQUEDA_GRAY_PNG, MARCA_AGUA_PNG } from "Assets/index.js";

const getTagData = (status) => {
    switch (status) {
        case "CON VIDA": 
            return { styles: styles.tagConVida, text: "LOCALIZADA\nCON VIDA" }
        case "SIN VIDA":
            return { styles: styles.tagSinVida, text: "LOCALIZADA\nSIN VIDA" }
        default:
            return { styles: styles.tagDesaparecido, text: "PERSONA\nDESAPARECIDA" }
    }
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 10,
        position: 'relative'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    container: {
        backgroundColor: 'pink',
        width: 536,
        display: 'inline-flex'
    },
    tag: {
        width: 187,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        display: 'inline-flex',
        alignItems: 'center',
        color: '#FFFFFF',
        flexWrap: 'wrap',
        padding: 8,
    },
    tagText: {
        fontFamily: 'Geologica Bold',
        letterSpacing: 0.8,
        fontSize: 18
    },
    tagConVida: {
        backgroundColor: '#1CA37A'
    },
    tagSinVida: {
        backgroundColor: '#626262'
    },
    tagDesaparecido: {
        backgroundColor: '#FE9523'
    },
    nameBox: {
        position: 'relative',
        overflowX: 'hidden',
        background: '#FAFAFA'
    },
    watermark: {
        maxWidth: 330,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: "auto",
        zIndex: 1,
        opacity: 0.8
    },
    tableStyles: {
        position: 'relative',
        borderRadius: 25,
        paddingBottom: 10
    },
    tableRow: { verticalAlign: 'top' },
    celStyle: {
        padding: 0,
        border: 0
    },
    celStyleW1: {
        padding: 0,
        border: 0,
        width: 1,
        whiteSpace: 'nowrap'
    },
    table: {
        boxShadow: 0
    },
    row: {
        display: 'flex',
    },
    valueTextName: {
        textAlign: 'left',
        font: 'bold',
        letterSpacing: 0,
        color: '#3B4348',
        fontSize: 8,
        marginRight: 5,
    },
    valueText: {
        textAlign: 'left',
        letterSpacing: 0,
        color: '#3B4348',
        fontSize: 8,
        fontFamily: 'Geologica Regular',
        fontWeight: 'bold'
    },
    footerCard: {
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    card1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        background: '#FDC503',
        padding: 1,
        width: 160
    },
    card2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        background: '#02ADD8',
        padding: 1
    },
    card3: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingTop: 1
    },
    titleWhite: {
        textAlign: 'left',
        fontSize: 10,
        fontFamily: 'Nutmeg Ultra Black',
        letterSpacing: 0,
        color: '#665F4A',
        marginBottom: 5
    },
    subTitle1: {
        textAlign: 'left',
        fontSize: 10,
        fontFamily: 'Nutmeg Ultra Black',
        letterSpacing: 0,
        color: '#FFFFFF',
        backgroundColor: '#02ADD8',
        width: 100,
        paddingLeft: 2,
        paddingRight: 2
    },
    subTitle2: {
        textAlign: 'left',
        fontSize: 10,
        fontFamily: 'Geologica Bold',
        letterSpacing: 0,
        color: '#FFFFFF',
        backgroundColor: '#02ADD8',
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2
    },
    whitePlaceholder: {
        textAlign: 'left',
        fontSize: 6,
        fontFamily: 'Montserrat Regular',
        letterSpacing: 0,
        color: '#665F4A',
        marginTop: 5
    },
    phoneText: {
        textAlign: 'left',
        letterSpacing: 0,
        color: '#FFFFFF',
        opacity: 1
    },
    textFooter: {
        textAlign: 'left',
        fontSize: 10,
        fontFamily: 'Geologica Bold',
        letterSpacing: 0,
        color: '#FFFFFF',
    },
    emailText: {
        textAlign: 'left',
        fontFamily: 'Geologica Bold',
        letterSpacing: 0,
        fontSize: 8,
        color: '#FFFFFF',
        wordBreak: 'break-all'
    },
    img: {
        margin: 'auto',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: 0
    },
    qrCode: {
        width: 30,
        height: 30,
        backgroundColor: 'blue'
    },
    textRow: {
        display: 'flex',
        flexDirection: 'row',
        width: 350
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 5,
        width: 175,
        maxWidth: 175
    },
    label: {
        width: 50,
        fontFamily: 'Geologica Regular',
        color: '#7B8991'
    },
    text1: {
        width: 125,
        fontFamily: 'Geologica Bold',
    },
    label2: {
        width: 65,
        fontFamily: 'Geologica Regular',
        color: '#7B8991'
    },  
    text2: {
        width: 110,
        fontFamily: 'Geologica Bold',
    },
    label3: {
        width: 100,
        fontFamily: 'Geologica Regular',
        color: '#7B8991'
    },
    text3: {
        width: 250,
        fontFamily: 'Geologica Regular'
    },
});

const MissingPersonCard = ({ personItem, qrCodeString }) => {
    const {
        condicion_localizacion,
        municipio,
        estado,
        nombre_completo,
        edad_momento_desaparicion,
        genero,
        sexo,
        complexion,
        estatura,
        tez,
        cabello,
        ojos_color,
        fecha_desaparicion,
        ruta_foto,
        descripcion_sena_particular,
        descripcion_vestimenta,
    } = personItem;

    Font.register({ family: 'Geologica Bold', src: GeologicaBold });
    Font.register({ family: 'Geologica Regular', src: GeologicaRegular });
    Font.register({ family: 'Nutmeg Ultra Black', src: NutmegUltraBlack });
    Font.register({ family: 'Nutmeg Bold', src: NutmegBold });
    Font.register({ family: 'Montserrat Regular', src: MontserratRegular });
    
    const tagData = getTagData(condicion_localizacion);
    const muniEsta = `${municipio !== 'None' ? `${municipio}, ` : ''} ${estado !== 'None' ? estado : ''}`
    const hasInfoField = (info) => info && info !== 'None' ? info : "SIN DATO";
    const formatDate = (inputDate) => {
        if (inputDate === null || inputDate === undefined || inputDate  === 'None' ) {
            return 'SIN DATO';
        }
        const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
        const formattedDate = new Date(inputDate).toLocaleDateString('es-MX', options);
        return formattedDate;
    };
    const verifyAge = (age) => 
        age === null || age === undefined || age < 0 || age  === 'None' 
            ? 'SIN DATO'
            : `${age} AÑOS`;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.tableStyles}>
                    <View style={{ ...styles.tag, ...(tagData.styles ?? {}) }}>
                        <Text style={styles.tagText}>
                            {tagData.text}
                        </Text>
                    </View>
                </View>  
                <View 
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'flex-start', 
                        alignItems: 'flex-start', 
                        marginTop: 10, 
                        backgroundColor: "#FAFAFA",
                        borderTopRightRadius: 10,
                        position: 'relative'
                    }}
                >
                    <View style={{ width: 180 }}>
                        <Image src={ruta_foto} style={{ width: 180, borderTopLeftRadius: 10 }} />
                    </View>
                    <View style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start', 
                        alignItems: 'flex-start', 
                        fontSize: 8, 
                        paddingLeft: 10, 
                        borderTopRightRadius: 10
                    }}>
                        <View style={{ display: 'flex', flexDirection: 'column', paddingTop: 8, borderTopRightRadius: 10 }}>
                            <View style={{ ...styles.textContainer, width: 350, maxWidth: 350, borderTopRightRadius: 10 }}>
                                <Text style={styles.label}>NOMBRE:</Text>
                                <Text style={{ ...styles.text1, width: 250 }}>{nombre_completo}</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column', borderTopRightRadius: 10 }}>
                            <View style={{ ...styles.textContainer, width: 350, maxWidth: 350, borderTopRightRadius: 10 }}>
                                <Text 
                                    style={{
                                        width: 250,
                                        fontFamily: 'Geologica Regular',
                                        color: '#7B8991'
                                    }}
                                    children={"EDAD AL MOMENTO DE DESAPARICIÓN:"}
                                />
                                <Text style={{ ...styles.text1, width: 250 }}>{verifyAge(edad_momento_desaparicion)}</Text>
                            </View>
                        </View>
                        <View style={styles.textRow}>
                            <View style={styles.textContainer}>
                                <Text style={styles.label2}>SEXO:</Text>
                                <Text style={styles.text2}>{hasInfoField(sexo)}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label}>GENERO:</Text>
                                <Text style={styles.text1}>{hasInfoField(genero)}</Text>
                            </View>
                        </View>
                        <View style={styles.textRow}>
                            <View style={styles.textContainer}>
                                <Text style={styles.label2}>COMPLEXIÓN:</Text>
                                <Text style={styles.text2}>{hasInfoField(complexion)}</Text>
                            </View>
                            <View style={styles.textContainer}>
                                <Text style={styles.label}>ESTATURA:</Text>
                                <Text style={styles.text1}>{hasInfoField(estatura)}</Text>
                            </View>
                        </View>
                        <View style={styles.textRow}>
                            <View style={styles.textContainer}>
                                <Text style={styles.label2}>TEZ:</Text>
                                <Text style={styles.text2}>{hasInfoField(tez)}</Text>
                            </View>
                            <View style={{ ...styles.textContainer, }}>
                                <Text style={{ ...styles.label }}>CABELLO: </Text>
                                <Text style={{ ...styles.text1 }}>{hasInfoField(cabello)}</Text>
                            </View>
                        </View>
                        <View style={styles.textRow}>
                            <View style={styles.textContainer}>
                                <Text style={styles.label2}>OJOS COLOR:</Text>
                                <Text style={styles.text2}>{hasInfoField(ojos_color)}</Text>
                            </View>
                        </View>
                        <View style={{ ...styles.textContainer, width: 350, maxWidth: 350 }}>
                            <Text style={{...styles.label3}}>FECHA DESAPARICIÓN:</Text>
                            <Text style={{ ...styles.text3 }}>{formatDate(fecha_desaparicion)}</Text>
                        </View>
                        <View style={{ ...styles.textContainer, width: 350, maxWidth: 350 }}>
                            <Text style={{ ...styles.label3}}>LUGAR DESAPARICIÓN:</Text>
                            <Text style={{ ...styles.text3 }}>{hasInfoField(muniEsta)}</Text>
                        </View>
                        <View style={{ ...styles.textContainer, width: 350, maxWidth: 350 }}>
                            <Text  style={{ ...styles.label3 }}>VESTIMENTA:</Text>
                            <View style={{ display: 'flex', flexDirection: 'column' }}>
                                {descripcion_vestimenta.length === 0 
                                    ? <Text style={{ ...styles.text3 }}>{hasInfoField('None')}</Text>
                                    : descripcion_vestimenta.map((item, index) => (
                                        <Text style={{ ...styles.text3 }} key={index}>{hasInfoField(item.descripcion)}</Text>
                                ))}
                            </View>
                        </View>
                        <View style={{ ...styles.textContainer, width: 350, maxWidth: 350 }}>
                            <Text style={styles.label3}>SEÑAS PARTICULARES:</Text>
                            <View style={{ display: 'flex', flexDirection: 'column' }}>
                                {descripcion_sena_particular.length === 0 
                                    ? <Text style={{ ...styles.text3 }}>{hasInfoField('None')}</Text>
                                    : descripcion_sena_particular.map((item, index) => (
                                        <Text style={{ ...styles.text3 }} key={index}>{hasInfoField(item.descripcion)}</Text>
                                ))}
                            </View> 
                        </View>
                    </View>
                    

                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', backgroundColor: "#FAFAFA", borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}>
                    <View style={{ width: 180, height: 85, backgroundColor: '#FDC503', padding: 10, borderBottomLeftRadius: 10 }}>
                        <Text style={styles.titleWhite}>PERSONA DESAPARECIDA</Text>
                        <Text style={styles.subTitle1}>¿La has visto?</Text>
                        <Text style={styles.subTitle2}>Ayúdanos a encontrarla</Text>
                        <Text style={styles.whitePlaceholder}>* Está cédula es publicada con la autorización por escrito de los familiares.</Text>
                    </View>
                    <View style={{ width: 180, height: 85, backgroundColor: '#02ADD8', padding: 10 }}>
                        <Text style={styles.textFooter}>Reporta cualquier </Text>
                        <Text style={styles.textFooter}>información sobre la persona: 33 3145 6314</Text>
                        <Text style={styles.emailText}>comisiondebusqueda@jalisco.gob.mx</Text>
                    </View>
                    <View style={{ width: 90, height: 85, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                        <Image src={qrCodeString} style={{ width: 70, height: 80 }} />
                    </View>
                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, height: 85 }}>
                        <Image src={COMISION_BUSQUEDA_GRAY_PNG} style={{ width: 90, height: 30 }} />
                    </View>
                </View>
                <Image src={MARCA_AGUA_PNG} style={{ width: 300, height: 250, position: 'absolute', left: 150, top: 70, bottom: 0, right: 0 }} />
            </Page>
        </Document>
    )
}

export const downloadPdf = async (personItem, qrCodeString) => {

    ReactGA.event({
        category: 'Cedulas',
        action: 'Descarga cédula de búsqueda',
        label: 'Descargar cedula de persona desaparecida'
    });

    let formatName = personItem.nombre_completo.split(" ").join("_");
    const blob = await pdf((
        <MissingPersonCard
            title='My PDF'
            personItem={personItem}
            qrCodeString={qrCodeString}
        />
    )).toBlob();
    saveAs(blob, `cedula_${formatName.toLowerCase()}.pdf`);
}