import React, {  } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext.js';
import { useStatisticsContext } from '../../../context/StatisticsPageContext.js';
import {
    INTERSEXUAL_ICON_FONDO, HOMBRE_ICON, PERSONAS_LOCALIZADAS_ICON,
    MUJER_ICON, PERSONAS_DESAPARECIDAS_ICON,
    MUJER_RED_ICON, HOMBRE_RED_ICON, SEIGNORA_ICON_FONDO,
    SEIGNORA_RED_ICON_FONDO, INTERSEXUAL_RED_ICON_FONDO
} from '../../../assets/index.js';
import styles from './styles.js';
import { 
    Box, Typography, Container, Card, CardContent, 
    CardMedia, CircularProgress
} from '@mui/material';


const StatisticsCards = () => {
    const { fullDateFormat } = useGlobalContext();
    const { cardData, isLoadingStatistics } = useStatisticsContext();

    const dataLocated = [{
            image: PERSONAS_LOCALIZADAS_ICON,
            text: `${cardData["PERSONA LOCALIZADA"]?.TOTAL || 0} personas localizadas desde el 1 de diciembre de 2018 al ${fullDateFormat}`,
            condition: true
        }, {
            image: MUJER_ICON,
            text: `${cardData?.["PERSONA LOCALIZADA"]?.["POR SEXO"]?.MUJER || 0} Mujeres`,
        }, {
            image: HOMBRE_ICON,
            text: `${cardData?.["PERSONA LOCALIZADA"]?.["POR SEXO"]?.HOMBRE || 0} Hombres`,
        }, {
            image: INTERSEXUAL_ICON_FONDO,
            text: `${cardData?.["PERSONA LOCALIZADA"]?.["POR SEXO"]?.INTERSEXUAL || 0} Intersexuales`,
        }, {
            image: SEIGNORA_ICON_FONDO,
            text: `${cardData?.["PERSONA LOCALIZADA"]?.["POR SEXO"]?.["SE IGNORA"] || 0} Se ignora`,
        }, ]

    const dataNotLocated = [{
            image: PERSONAS_DESAPARECIDAS_ICON,
            text: `${cardData["PERSONA DESAPARECIDA"]?.TOTAL || 0} personas desaparecidas`,
            condition: true
        }, {
            image: MUJER_RED_ICON,
            text: `${cardData?.["PERSONA DESAPARECIDA"]?.["POR SEXO"]?.MUJER || 0} Mujeres`,
        }, {
            image: HOMBRE_RED_ICON,
            text: `${cardData?.["PERSONA DESAPARECIDA"]?.["POR SEXO"]?.HOMBRE || 0} Hombres`,
        }, {
            image: INTERSEXUAL_RED_ICON_FONDO,
            text: `${cardData?.["PERSONA DESAPARECIDA"]?.["POR SEXO"]?.INTERSEXUAL || 0} Intersexuales`,
        }, {
            image: SEIGNORA_RED_ICON_FONDO,
            text: `${cardData?.["PERSONA DESAPARECIDA"]?.["POR SEXO"]?.["SE IGNORA"] || 0} Se ignora`,
    }]

    const CardText = ({children}) => 
        <CardContent 
            sx={{ 
                display: "flex", 
                justifyContent: "center",
                flexWrap: {xs: "wrap", md: "nowrap"},
                width: '100%' 
            }}
        >{
            isLoadingStatistics.cards 
                ?   <CircularProgress>
                        <Typography sx={styles.cardText} children={children} />
                    </CircularProgress>
                :   <Typography sx={styles.cardText} children={children} /> 
        }</CardContent>

    return (
        <Box component="main">
            <Container maxWidth="xl" sx={{ px: 0, my: { xs: 4, sm: 6 } }}>
                <Box sx={styles.cardContainer}>{
                    dataLocated.map((field) => 
                        <Card 
                            key={field.text}
                            sx={{
                                ...styles.card, 
                                ...(field.condition || { width: { xs: '100%', sm: '28%' } })
                            }}
                        >
                            <CardMedia sx={styles.imageCard} image={field.image} alt="icono localizado"/>
                            <CardText children={field.text} />
                        </Card>
                    )
                }</Box>
                <Box sx={styles.cardContainer}>{
                    dataNotLocated.map((field) => 
                        <Card 
                            key={field.text}
                            sx={{ 
                                ...styles.card, 
                                ...(field.condition || { width: { xs: '100%', sm: '28%' } }),
                                borderColor: '#DE5E60' 
                            }}
                        >
                            <CardMedia sx={styles.imageCard} image={field.image} alt="icono no localizado" />
                            <CardText children={field.text} />
                        </Card>
                    )
                }</Box>
            </Container>
        </Box>
    );
};

export default StatisticsCards;
