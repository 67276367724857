import React from 'react';
import styles from './styles.js';
import { Box, Typography, Container, Grid } from '@mui/material';
import { CEDULA_BANNER_LOGO } from '../../../assets/index.js';

const CedulaBanner = () => {
    return (
        <Box component="main" sx={{backgroundColor: 'primary.main'}}>
            <Container maxWidth="xl" sx={{ px: 0, pb: 8, my: { xs: 4, sm: 6 } }}>
                <Grid container spacing={0} sx={styles.grid}>
                    <Grid item xs={12} sx={styles.gridBox}>
                        <Box sx={{...styles.box, width: '100%'}}>
                            <Typography sx={styles.cedulaTitle}>Cédulas de Búsqueda de Personas Desaparecidas</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{...styles.gridBox, my: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={CEDULA_BANNER_LOGO} alt="Imagen Cedula Personas" />
                    </Grid>
                    <Grid item xs={12} md={7} sx={{...styles.gridBox, justifyContent: {xs: 'center', md: 'flex-start'}, pl: {xs: 0, md: 3}}}>
                        <Box sx={{...styles.box, width: '100%', justifyContent: 'center', height: '100%', flexDirection: 'column' }}>
                            <Typography sx={{...styles.subtitleText}}>Las cédulas de búsqueda son una herramienta para <b>difundir la información de las personas que han sido reportadas como desaparecidas y solicitar el apoyo de la sociedad para su búsqueda y localización.</b><br></br>Aquí puedes consultar las cédulas que ha emitido la Comisión de Búsqueda de Personas del Estado de Jalisco.<br></br><b>Si cuentas con información que pueda ayudar a la localización</b> de alguna de ellas, <b>podrás enviarla de manera directa y ágil</b> a las autoridades mediante el buzón que hemos habilitado.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default CedulaBanner;
