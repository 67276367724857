'use client'

import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/es';

export default function LProvider({ children }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
            {children}
        </LocalizationProvider>
    );
}