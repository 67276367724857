import React, { useRef, useEffect } from "react";
import { select, scaleLinear, scaleBand, max, axisLeft, axisBottom } from "d3";
import { Box, Typography } from "@mui/material";
import styles from "./styles";
import { isMobile } from "../../../utils/constants";
import * as d3 from 'd3'
import { coloresGrafica as color }  from "../../../utils/constants";

function Legend({ colors }) {
  return (
    <Box sx={styles.legendContainer}>
      {colors.map((color, index) => (
        <Box key={index} sx={styles.item}>
          <Box sx={{ ...styles.colorBox, backgroundColor: color.color }} />
          <span style={styles.textColor}>{color.label}</span>
        </Box>
      ))}
    </Box>
  );
}

function VerticalBarChart({ title = null, subTitle = null, legend, data, text = null, xLegend = null, yLegend = null}) {
  const svgRef = useRef();
  const yRef = useRef();
  const elements = data.length;
  const margin = { top: 10, right: isMobile ? 30 : 50, bottom: 20, left: 50 };
  const width = isMobile ? (300 + (elements * 10)) : 900;
  const height = 400 - margin.top - margin.bottom;

  const drawToolTip = (d, tooltipMap, color = '#FFFF', genero = 'No Aplica') => {
    tooltipMap.html(`
                <div style='font-size: 12px; line-height: 18px;'>
                <table>
                <tr>
                  <td><span style='height: 18px; width: 18px; background-color: ${color}; border-radius: 50%; display: inline-block;'></span></td>
                  <td><span>${d || 0}</span><br><span>${genero}</span></td>
                </tr>
                </table>
                </div>
                `);
    return tooltipMap;
  };

  const positionToolTip = (e, tooltipMap) => {
    if (isMobile) {
      if (e.pageX > 290) {
          tooltipMap.style('left', (e.pageX-80) + 'px').style('top', (e.pageY+5) + 'px')
      } else {
          tooltipMap.style('left', (e.pageX+10) + 'px').style('top', (e.pageY+5) + 'px')
      }
  }
    return tooltipMap
  };

  useEffect(() => {
    const svg = select(svgRef.current);
    const svg2 = select(yRef.current);

    let tooltipMap = d3.select('#tooltip')
        .append("div")
        .attr("id", "tooltip")
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("background-color", "white")
        .style("border-radius", "5px")
        .style("box-shadow", "1px 1px 1px 1px #eeeff5")
        .style("padding", "4px");

    const xScale = scaleBand()
      .domain(data.map((d) => d.label))
      .range([0, width])
      .padding(.35);

    const yScale = scaleLinear()
      .domain([0, max(data, (d) => (d.value1 + d.value2 + d.value3 + d.value4))])
      .range([height, 0]);

    const xAxis = axisBottom(xScale);
    svg.select(".x-axis")
      .style("transform", `translate(0, ${height}px)`)
      .call(xAxis);

    const yAxis = axisLeft(yScale);
    svg2.select(".y-axis").call(yAxis);

    svg.selectAll(".bar1")
      .data(data)
      .join("rect")
      .attr("class", "bar1")
      .attr("x", (d) => xScale(d.label))
      .attr("y", (d) => yScale(d.value1))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => yScale(0) - yScale(d.value1 || 0))
      .style("fill", color[0].color)
      .on('mouseover', (e, d) => {
        tooltipMap.style("visibility", "visible")
        drawToolTip(d.value1, tooltipMap, color[0].color, color[0].label);
      })
      .on('mousemove', function(e, d) {
        positionToolTip(e, tooltipMap);
      })
      .on('mouseout', (e, d) => {
        tooltipMap.style("visibility", "hidden")
      });

    svg.selectAll(".bar2")
      .data(data)
      .join("rect")
      .attr("class", "bar2")
      .attr("x", (d) => xScale(d.label))
      .attr("y", (d) => yScale(d?.value1 + d?.value2))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => yScale(0) - yScale(d.value2 || 0))
      .style("fill", color[1].color)
      .on('mouseover', (e, d) => {
        tooltipMap.style("visibility", "visible")
        drawToolTip(d.value2, tooltipMap, color[1].color, color[1].label);
      })
      .on('mousemove', function(e, d) {
        positionToolTip(e, tooltipMap);
      })
      .on('mouseout', (e, d) => {
        tooltipMap.style("visibility", "hidden")
      });

      svg.selectAll(".bar3")
      .data(data)
      .join("rect")
      .attr("class", "bar3")
      .attr("x", (d) => xScale(d.label))
      .attr("y", (d) => yScale(d?.value1 + d?.value2 + d?.value3))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => yScale(0) - yScale(d?.value3 || 0))
      .style("fill", color[2].color)
      .on('mouseover', (e, d) => {
        tooltipMap.style("visibility", "visible")
        drawToolTip(d.value3, tooltipMap, color[2].color, color[2].label);
      })
      .on('mousemove', function(e, d) {
        positionToolTip(e, tooltipMap);
      })
      .on('mouseout', (e, d) => {
        tooltipMap.style("visibility", "hidden")
      });

      svg.selectAll(".bar4")
      .data(data)
      .join("rect")
      .attr("class", "bar4")
      .attr("x", (d) => xScale(d.label))
      .attr("y", (d) => yScale(d?.value1 + d?.value2 + d?.value3 + d?.value4))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => yScale(0) - yScale(d?.value4 || 0))
      .style("fill", color[3].color)
      .on('mouseover', (e, d) => {
        tooltipMap.style("visibility", "visible")
        drawToolTip(d.value4, tooltipMap, color[3].color, color[3].label);
      })
      .on('mousemove', function(e, d) {
        positionToolTip(e, tooltipMap);
      })
      .on('mouseout', (e, d) => {
        tooltipMap.style("visibility", "hidden")
      });

      svg.select(".x-axis").selectAll('text')
      .style("text-anchor", "center")
      .attr("dx", "-5em")
      .attr("dy", "-.5em")
      .attr("transform", function(d) {
        return "rotate(-90)" 
        });

  }, [data, width, height]);

  return (
    <Box sx={{...styles.container}}>
      {title != null && (
      <Typography sx={styles.title}>{title}</Typography>
      )}
      {text != null && (
        <Typography sx={{...styles.title, fontSize: '12px', mb: 5 }}>{text}</Typography>
      )}
      {subTitle != null && (
      <Typography sx={{...styles.title, fontSize: '18px', mb: 5 }}>{subTitle}</Typography>
      )}
      <Legend colors={legend} />
      <Box sx={styles.svgContainer}>
        <div id={'tooltip'}></div>
          <div>
          {yLegend && (
            <Box sx={{display: 'flex', width: '1%', position: 'absolute'}}>
              <Typography sx={styles.legendy}>{yLegend}</Typography>
            </Box>
          )}
            <svg
              ref={yRef}
              width={'1px'}
              height={height + margin.top + margin.bottom}
              style={{ overflow: "visible", position: "absolute", backgroundColor: '#FAFAFA' }}
            >
              <g className="y-axis" style={{ color: "#717177", fontSize: 12, fontWeight: 'bold'}} />
            </svg>
          </div>
        <div>
          <svg
            ref={svgRef}
            width={width + margin.left}
            height={height + margin.top + margin.bottom}
            style={{ overflow: "visible" }}
          >
            <g className="x-axis" style={{ color: "#717177", fontSize: 10, fontWeight: 'bold'}} />
          </svg>
        </div>
      </Box>
      {xLegend && (
        <>
          <Box>
            <Typography sx={styles.legendx}>{xLegend}</Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default VerticalBarChart;
