import React from 'react';
import styles from './styles.js';
import {
    Box,
    Typography
} from '@mui/material';
import { MAP_ICON, PHONE_ICON } from '../../../assets/index.js';

const GoToComponent = ({title}) => {

    return (
        <Box sx={styles.container}>
            <Typography sx={styles.title}>{ title || "Acude a:" }</Typography>
            <Box sx={styles.cardContainer}>
                <Box sx={styles.card}>
                    <Typography sx={styles.subTitle}>Comisión de Búsqueda de Personas del Estado de Jalisco</Typography>
                    <Box sx={{ display: 'flex' }}>
                        <img src={MAP_ICON} width={'22px'} height={'27px'} style={{ margin: '10px 18px' }} alt={'Icono mapa'} />
                        <Typography sx={styles.lightText}>Calle Libertad 1955, Colonia Americana Guadalajara, Jalisco</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', marginTop: '30px' }}>
                        <img src={PHONE_ICON} width={'22px'} height={'27px'} style={{ margin: '10px 18px' }} alt={'Icono telefono'} />
                        <Box>
                            <Typography sx={styles.text}>33 1514 5422</Typography>
                            <Typography sx={styles.text}>33 3145 6314</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={styles.card}>
                    <Typography sx={styles.subTitle}>Fiscalía Especial en Personas Desaparecidas</Typography>
                    <Box sx={{ display: 'flex' }}>
                        <img src={MAP_ICON} width={'22px'} height={'27px'} style={{ margin: '10px 18px' }} alt={'Icono mapa'} />
                        <Typography sx={styles.lightText}>Calzada Independencia Norte 778, segundo piso Colonia La Perla Guadalajara, Jalisco</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', marginTop: '30px' }}>
                        <img src={PHONE_ICON} width={'22px'} height={'27px'} style={{ margin: '10px 18px' }} alt={'Icono telefono'} />
                        <Box>
                            <Typography sx={styles.text}>33 3837 6000 extensiones 12115 y 12116</Typography>
                        </Box>
                    </Box>
                    <a href="https://fiscaliaenpersonasdesaparecidas.jalisco.gob.mx/" target="_blank" rel="noreferrer" style={{ textDecorationColor: 'none' }} >
                        <Typography sx={styles.underlineText}>fiscaliaenpersonasdesaparecidas.jalisco.gob.mx</Typography>
                    </a>
                </Box>
            </Box>
            <Typography sx={styles.title}>Para más información, visita el sitio</Typography>
            <a href="https://estamosbuscando.jalisco.gob.mx/" target="_blank" rel="noreferrer" style={{ textDecorationColor: 'none' }} >
                <Typography sx={styles.email}>estamosbuscando.jalisco.gob.mx</Typography>
            </a>
        </Box>
    );
};

export default GoToComponent;
