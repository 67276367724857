import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import GeologicaRegular from 'Assets/fonts/Geologica-Regular.ttf';
import GeologicaBold from 'Assets/fonts/Geologica-Bold.ttf';
import NutmegUltraBlack from 'Assets/fonts/Nutmeg-Ultra-Black.otf';
import NutmegRegular from 'Assets/fonts/Nutmeg-Regular.otf';
import NutmegBold from 'Assets/fonts/Nutmeg-Bold.otf';
import NutmegBlack from 'Assets/fonts/Nutmeg-Black.otf';
import MontserratRegular from 'Assets/fonts/Montserrat-Regular.ttf';

export const generateTheme = () => {
    let theme = createTheme({
        palette: {
            primary: {
                main: '#1C5866'
            },
            secondary: {
                main: '#F2F2F2'
            },
            danger: {
                light: '#F7CFD0',
                main: '#DE5E60',
                dark: '#DE5E60',
                contrastText: '#fff'
            },
            tonalOffset: 0.03,
        },
        typography: {
            htmlFontSize: 16,
            fontFamily: `'Geologica Bold', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif' !important`,
            body1: {
                fontSize: '1rem',
            },
            body2: {
                fontSize: '0.875rem',
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    html: [
                        { 
                            '@font-face': {
                                fontFamily: 'Geologica Regular',
                                src: `url(${GeologicaRegular}) format('truetype')`,
                                fontWeight: 'normal',
                                fontStyle: 'normal'
                            }
                        },
                        {
                            '@font-face': {
                                fontFamily: 'Geologica Bold',
                                src: `url(${GeologicaBold}) format('truetype')`,
                                fontWeight: 500,
                                fontStyle: 'normal'
                            }
                        },
                        {
                            '@font-face': {
                                fontFamily: 'Nutmeg Ultra Black',
                                src: `url(${NutmegUltraBlack}) format('truetype')`,
                                fontWeight: 500,
                                fontStyle: 'normal'
                            }
                        },
                        {
                            '@font-face': {
                                fontFamily: 'Nutmeg Bold',
                                src: `url(${NutmegBold}) format('truetype')`,
                                fontWeight: 500,
                                fontStyle: 'normal'
                            }
                        },
                        {
                            '@font-face': {
                                fontFamily: 'Nutmeg Black',
                                src: `url(${NutmegBlack}) format('truetype')`,
                                fontWeight: 500,
                                fontStyle: 'normal'
                            }
                        },
                        {
                            '@font-face': {
                                fontFamily: 'Nutmeg Regular',
                                src: `url(${NutmegRegular}) format('truetype')`,
                                fontWeight: 500,
                                fontStyle: 'normal'
                            }
                        },
                        {
                            '@font-face': {
                                fontFamily: 'Montserrat Regular',
                                src: `url(${MontserratRegular}) format('truetype')`,
                                fontWeight: 500,
                                fontStyle: 'normal'
                            }
                        }
                    ],
                    body: {
                        fontFamily: `'Geologica Regular', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif' !important;`
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: '#1C5866',
                        fontWeight: 'normal',
                        fontFamily: 'Geologica Regular !important',
                    }
                }
            }
        }
    })

    theme = responsiveFontSizes(theme);
    return theme;
};