import { api } from "./api";

export const identityCardsOfMissingPersons = (params) => {
  const { estado, municipio, edad_momento_desaparicion, nombre, fecha_desaparicion, sexo, id_cedula_busqueda, limit, page } = params;
  const queryParams = new URLSearchParams();

  if (estado) {
    queryParams.append('estado', estado);
  }

  if (municipio) {
    queryParams.append('municipio', municipio);
  }
  if (edad_momento_desaparicion) {
    queryParams.append('edad_momento_desaparicion',  edad_momento_desaparicion);
  }
  if (nombre) {
    queryParams.append('nombre', nombre);
  }
  if (sexo) {
    queryParams.append('sexo', sexo);
  }
  if (fecha_desaparicion) {
    queryParams.append('fecha_desaparicion', fecha_desaparicion);
  }
  if(id_cedula_busqueda){
    queryParams.append('id_cedula_busqueda', id_cedula_busqueda);
  }
  if(limit){
    queryParams.append('limit', limit);
  }
  if(page){
    queryParams.append('page', page);
  }

  const queryString = queryParams.toString();

  return api
    .get(`/version_publica/repd-version-publica-cedulas-busqueda/?${queryString}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error("Error en la solicitud: " + error.message);
    });
};
