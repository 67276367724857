import React, { useEffect, useState, useCallback } from 'react';

// Context
import { useTemplateContext } from 'Context/AppContext';
import { useCedulasContext } from "Context/CedulasPageContext";

// Utils
import { isMobile } from 'Utils/constants';
import dayjs from 'dayjs';

// Assets
import { SEARCH_ICON } from '../../../assets/index.js';
import styles from './styles.js';

// Components
import MissingPersonCard from '../../moleculas/MissingPersonCard/MissingPersonCard.js';
import AlertSmallTop from 'Components/moleculas/AlertSmallTop';
import PaginationFinder from './PaginationFinder.jsx';
import StateFilter from './StateFilter.jsx';
import MunicipalityFilter from './MunicipalityFilter.jsx';
import CloseIconSelect from 'Utils/CloseIconSelect.jsx';

// MUI
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { 
    Box, FormControl, IconButton, Container,
    InputLabel, List, ListItem, ListItemText, MenuItem, Select, 
    TextField, Typography, Grid, CircularProgress
} from '@mui/material';

const PersonsFinder = ({ onPress, cardResultsInfo, onPressReport, defaultValues, totalValues, loading }) => {
    const { typesOfSex } = useTemplateContext()
    const { 
        selectedState, selectedMunicipality, handleSelectedState,
        handleSelectedMunicipality, selectedStateID, selectedMunicipalityID,
        perPage
    } = useCedulasContext();

    const PickerContainer = styled(FormControl)(() => (styles.formControl));
    const [date, setDate] = useState(null);
    const [sex, setSex] = useState('');
    const [age, setAge] = useState('');
    const [name, setName] = useState('');
    const [didPerformSearch, setDidPerformSearch] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [ageError, setAgeError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
    }, [didPerformSearch])

    const LegendToTakeIntoAccount = () => {
        return (
            <Container maxWidth="lg" >
                <Box sx={styles.legendContainer}>
                    <Typography sx={{ ...styles.redTitle, textAlign: 'left' }}>Ten en cuenta que…</Typography>
                    <List>
                        <ListItem>
                            <div style={styles.listItemCircleStyle}></div>
                            <ListItemText
                                primary={
                                    <Typography sx={styles.listText}>
                                        Sólo se publican las cédulas de personas cuyas{' '}
                                        <span style={styles.boldTextStyle}>familias autorizan que se haga pública la información</span>
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <div style={styles.listItemCircleStyle}></div>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" sx={styles.listText}>
                                        Una familia puede <span style={styles.boldTextStyle}>solicitar que se suspenda la difusión de la cédula de su familiar en cualquier momento</span>
                                    </Typography>
                                }
                            />
                        </ListItem>
                        {
                            (didPerformSearch === false || (cardResultsInfo && cardResultsInfo.length > 0)) &&
                            <ListItem>
                                <div style={styles.listItemCircleStyle}></div>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={styles.listText}>
                                            Las cédulas emitidas en este sitio <span style={styles.boldTextStyle}>cuentan con un código QR para verificar su validez.</span>
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        }
                    </List>
                </Box>
            </Container>
        )
    }

    const copyURLToClipboard = (id_cedula_busqueda) => {
        const personURL = `${window.location.protocol}//${window.location.host}/cedulas-de-busqueda?id_cedula_busqueda=${id_cedula_busqueda}`;
        navigator.clipboard.writeText(personURL).then(function () {
            setAlertOpen(true)
        }).catch(function (error) {
            console.error('Error al copiar la URL: ' + error);
        });
    };

    const handleSearch = useCallback(() => {
        setDidPerformSearch(true);
        onPress({ 
            estado: selectedStateID,
            municipio: selectedMunicipalityID || "",
            fecha_desaparicion: date?.format('YYYY-MM-DD') ?? null, 
            sexo: sex, 
            edad_momento_desaparicion: age, 
            nombre: name,
            limit: perPage,
            page: 1
        })
        setPage(1);
        // eslint-disable-next-line
    }, [selectedMunicipality, date, sex, age, name, selectedState, onPress]);

    useEffect(() => {
        handleSelectedMunicipality("")
        handleSelectedState("")
        setDate(defaultValues?.fecha_desaparicion ? dayjs(defaultValues.fecha_desaparicion) : null)
        setSex(defaultValues?.sexo ?? '')
        setAge(defaultValues?.edad_momento_desaparicion ?? '')
        setName(defaultValues?.nombre ?? '')
        // eslint-disable-next-line
    }, [defaultValues]);

    const handleChange = (_event, page) => {
        setPage(page);
        onPress({
            estado: selectedStateID,
            municipio: selectedMunicipalityID || "",
            fecha_desaparicion: date?.format('YYYY-MM-DD') ?? null, 
            sexo: sex, 
            edad_momento_desaparicion: age, 
            nombre: name,
            limit: perPage,
            page: page
        })
        let element = document.getElementById("container");
        element.scrollIntoView();
    };
    
    return (
        <>
            <AlertSmallTop 
                type={'ok'}
                open={alertOpen}
                title={'Copiado en el portapapeles'}
                onClose={() => setAlertOpen(false)}
            />
            <Box id={'container'} sx={styles.container}>
                {!isMobile && <Typography sx={styles.redTitle}>Utiliza los filtros para encontrar la cédula de la persona que buscas</Typography>}
                <Box sx={styles.box}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={11} sx={{ py: 1 }}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <StateFilter />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <MunicipalityFilter />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <DatePicker
                                            label="Fecha de desaparición" 
                                            format="YYYY-MM-DD"
                                            size='small'
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                            slotProps={{ textField: { size: 'small' }, actionBar: {actions: ['clear']}, field: { clearable: true } }}
                                            sx={styles.formControl}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <PickerContainer size="small" sx={{ width: '100%' }}>
                                        <InputLabel id="demo-select-small-label">Sexo</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={sex}
                                            label="Sexo"
                                            onChange={(event) => setSex(event.target.value)}
                                            endAdornment={<CloseIconSelect dataCondition={sex} dataFuntion={() => setSex('')}/>}
                                        >
                                            {typesOfSex && typesOfSex.map((option, index) => (
                                                <MenuItem key={`sexo_finder_${index}`} value={option.id}>
                                                    {option.descripcion}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </PickerContainer>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            error={ageError}
                                            type="number"
                                            label="Edad al momento de desaparición"
                                            InputProps={{ inputProps: {min: 0, max: 125} }}
                                            value={age}
                                            helperText={errorText}
                                            onChange={(event) => {
                                                event.target.value = Math.max(0, parseInt(event.target.value) ).toString().slice(0,3);
                                                setAge(event.target.value);
                                                if (parseInt(event.target.value) > 125) {
                                                    setAgeError(true);
                                                    setErrorText('La edad debe ser menor a 125')
                                                } else {
                                                    setAgeError(false);
                                                    setErrorText('')
                                                }
                                            }}
                                            size="small"
                                            sx={styles.formControl}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            value={name}
                                            onChange={(event) => {
                                                setName(event.target.value);
                                            }}
                                            size="small"
                                            sx={styles.formControl}
                                            label="Nombre"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={1} sx={{ p: 1, display: 'flex', alignItems: 'flex-end', justifyContent: { xs: 'center', md: 'flex-start' } }} >
                            <IconButton 
                                sx={{
                                    ...styles.button,
                                    width: { xs: '100%', md: 'auto' }
                                }} 
                                onClick={() => {
                                    handleSearch()
                                }}
                            >
                            {loading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <>
                                {!isMobile && <img src={SEARCH_ICON} width={20} alt={'Icono de busqueda'}/>}
                                <Typography
                                    variant={'h6'}
                                    sx={{
                                    color: '#FFFFFF',
                                    fontFamily: 'Geologica Bold !important',
                                    ml: 1,
                                    display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    BUSCAR
                                </Typography>
                                </>
                            )}
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            
            <Box sx={{ height: 'auto', overflowY: 'auto', width: '100%', marginTop: '-70px' }}>
                {cardResultsInfo && (
                    <>
                        <PaginationFinder 
                            totalValues={totalValues} 
                            page={page} 
                            handleChange={handleChange} 
                            perPage={perPage}
                        />
                        <Grid container spacing={0}>
                            {cardResultsInfo.filter(i => i.autorizacion_informacion_publica === "SI").map((personItem, index) => (
                                <Grid item key={`cedula_finder_${index}`} xs={12} md={6} lg={4} sx={{ p: 1 }}>
                                    <MissingPersonCard  
                                        personItem={personItem} 
                                        onPressShare={copyURLToClipboard}
                                        onPressReport={onPressReport} 
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <PaginationFinder 
                            totalValues={totalValues} 
                            page={page} 
                            handleChange={handleChange} 
                            perPage={perPage}
                        />
                    </>
                )}
            </Box>
            {
                (cardResultsInfo && cardResultsInfo.length === 0) && 
                <Box sx={styles.notFoundAlert}>
                    <Typography variant={'h6'}>Lo sentimos, no encontramos ninguna cédula que cumpla con los criterios especificados.</Typography>
                </Box>
            }
            <Box paddingTop={10}>
                <LegendToTakeIntoAccount />
            </Box>
        </>
    );
};

export default PersonsFinder;
