import React from "react"
import {Box, Typography} from "@mui/material"
import Container from "@mui/material/Container"
import PersonsFinder from "../components/organisms/PersonsFinder/PersonsFinder"
import {useEffect, useState} from "react"
import {identityCardsOfMissingPersons} from "../api/identityCardsOfMissingPersons.api"
import Alert from "../components/moleculas/Alert/Alert"
import {useSearchParams} from 'react-router-dom';
import CitizenMilBoxForm from "../components/moleculas/CitizenMilBoxForm/CitizenMilBoxForm"
import styles from "./CedulaStyles"
import GoToComponent from "../components/moleculas/GoToComponent/GoToComponent"

const Cedula = () => {
    const [searchParams] = useSearchParams();
    const [cardResultsInfo, setCardResultsInfo] = useState();
    const [cardResultsPaginate, setCardResultsPaginate] = useState();
    const [showCitizenMilBoxForm, setShowCitizenMilBoxForm] = useState(false);
    const [personName, setPersonName] = useState("");
    const [defaultSex, setDefaultSex]  = useState("");
    const [personId, setPersonId] = useState("");
    const [filterDefaultValues, setFilterDefaultValues] = useState({});
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertType, setAlertType] = React.useState('ok');
    const [alertTitle, setAlertTitle] = React.useState('');
    const [alertDescription, setAlertDescription] = React.useState('');
    const [loading, setLoading] = useState(false);

    const handleFormOnSubmit = (success) => {
        setAlertType(success ? 'ok' : 'error')
        setAlertTitle(success ? 'Tu mensaje se envió de forma exitosa.' : 'No fue posible enviar tu mensaje.');
        setAlertDescription(success ? 'Gracias por compartir información para la búsqueda de personas.' : 'Por favor inténtalo nuevamente.');
        setAlertOpen(true);
    };

    useEffect(() => {
        if (searchParams.id_cedula_busqueda) {
            searchPeople({ id_cedula_busqueda: searchParams.id_cedula_busqueda });
            return
        }

        const params = {}
        searchParams.forEach((value, key) => {
            params[key] = value;
        });

        setFilterDefaultValues(params);
        if (Object.keys(params).length > 0) {
            searchPeople(params);
        }

        const formDefaultValuesAux = {};
        if (searchParams.nombre) {
            formDefaultValuesAux.name = searchParams.nombre;
        }
    }, [searchParams]);

    const searchPeople = (params) => {
        setLoading(true);
        identityCardsOfMissingPersons(params).then((response) => {
            let resultados = response.results;
            resultados.forEach((e) => {
                if (e.ruta_foto === null || e.ruta_foto?.includes('tiff')) {
                    e.ruta_foto = "./no_image.png";
                }
            });
            setCardResultsInfo(resultados);
            setCardResultsPaginate(response);
            setLoading(false);
        }).catch((error) => {
            console.error("Error al obtener información", error);
            setLoading(false);
        });
    }

    const onPressReport = (name, id_cedula_busqueda, sexo) => {
        setPersonName(name);
        setPersonId(id_cedula_busqueda);
        setDefaultSex(sexo)
        setShowCitizenMilBoxForm(true);
    };

    const closeCitizenMilBoxForm = () => {
        setShowCitizenMilBoxForm(false);
    };
    
    return (
        <Box sx={{ overflowX: "hidden" }}>
            <Alert 
                type={alertType}
                open={alertOpen}
                title={alertTitle}
                description={alertDescription}
                onClose={() => setAlertOpen(false)}
            />
            {showCitizenMilBoxForm && (
                <Box 
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(69, 69, 69, 0.77)',
                        backdropFilter: 'blur(8px)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000
                    }}
                    onClick={() => setShowCitizenMilBoxForm(false)}
                >
                    <CitizenMilBoxForm
                        defaultName={personName}
                        id_cedula_busqueda={personId}
                        defaultSex={defaultSex}
                        onFormDidSubmit={(success) => {
                            handleFormOnSubmit(success);
                            setShowCitizenMilBoxForm(false);
                        }}
                        onClose={closeCitizenMilBoxForm}
                    />
                </Box>
            )} 
            
            <Box backgroundColor="#1C5866">
                <Container maxWidth="xl" sx={{ px: 0, pt: 16,  pb: {xs: 5, sm: 20}}}>
                    <Box sx={styles.titleBox}>
                        <Typography sx={styles.titleText}>Cédulas de Búsqueda de Personas Desaparecidas</Typography>
                        <Typography sx={{ color: '#FFF', fontSize: '1.2rem', mt: 2, mb: { xs: 8, sm: 4 }, textAlign: 'center' }}>
                            Aquí puedes consultar las cédulas emitidas por la Comisión de Búsqueda de Personas del Estado de Jalisco. Puedes utilizar los filtros para encontrar la cédula de una persona y el botón “Reportar” si tienes información que pueda ayudar a localizarla.
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Box>
                <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mb: 5}}>
                    <PersonsFinder 
                        defaultValues={filterDefaultValues}
                        onPress={searchPeople}
                        cardResultsInfo={cardResultsInfo}
                        onPressReport={onPressReport}
                        totalValues={cardResultsPaginate}
                        loading={loading}
                    /> 
                     <GoToComponent title={"¿Qué hago si alguna persona de mi familia o con quien tengo una relación cercana está desaparecida? Acude a:"}/>
                </Container>
            </Box>
        </Box>
    )
}

export default Cedula
