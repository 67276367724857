import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import styles from './styles.js';
import { 
    Box, Button, IconButton, FormControl, InputLabel, InputAdornment, 
    MenuItem, Select, TextField, Typography, CircularProgress, Stack,
    Autocomplete
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTemplateContext } from 'Context/AppContext'
import {postMailBox} from "Api/citizenMailBox.api"
import Alert from "Components/moleculas/Alert/Alert"

const CitizenMilBoxForm = ({ onFormDidSubmit, onError, defaultName, onClose, id_cedula_busqueda, defaultSex }) => {
  const { loadMunicipalities, states, municipalities, typesOfSex } = useTemplateContext();
  const [name, setName] = useState(defaultName ?? '');
  const [state, setState] = useState(14);
  const [lastSighting, setLastSighting] = useState('ACATIC');
  const [sex, setSex] = useState(defaultSex || '');
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [contact, setContact] = useState('');
  const [isValidContact, setIsValidContact] = useState(true);
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('ok');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertDescription, setAlertDescription] = useState('');

  useEffect(() => {
    setSex(defaultSex || '');
  }, [defaultSex]);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = newEmail === '' || emailRegex.test(newEmail);
    
    setEmail(newEmail);
    setIsValidEmail(isValid);
  };

  const handleContactChange = (event) => {
    const newContact = event.target.value;
    const phoneRegex = /^\d{8,15}$/;
    const isValid = newContact === '' || phoneRegex.test(newContact);
    
    setContact(newContact);
    setIsValidContact(isValid);
  };

  const handleFormOnSubmit = (success) => {
      setAlertType(success ? 'ok' : 'error')
      setAlertTitle(success ? 'Tu mensaje se envió de forma exitosa.' : 'No fue posible enviar tu mensaje.');
      setAlertDescription(success ? 'Gracias por compartir información para la búsqueda de personas.' : 'Por favor inténtalo nuevamente.');
      setAlertOpen(true);
      
      if (onFormDidSubmit) onFormDidSubmit(success);
  };

  const handleOnError = () => {
      setAlertType('error')
      setAlertTitle('No fue posible enviar tu mensaje');
      setAlertDescription('Por favor verifica que hayas completado todos los campos requeridos correctamente.');
      setAlertOpen(true);

      if (onError) onError();
  }

  const handleClose = () => {
    setName('');
    setContact('');
    setEmail('');
    setMessage('');
    setState(14);
    setLastSighting('');
    setSex('');
    setLoading(false)
    if (onClose) onClose();
  }

  const verifyRecaptchaCallback = useCallback((token) => {
    setToken(token)
  }, []);

  const handleSubmit = () => {
    if (
      !name || name === '' ||
      !message || message === ''
    ) {
      handleOnError();
      return;
    }

    setLoading(true)

    if (token) {
      const formData = {
        nombre: name ?? "Sin especificar",
        estado_ultimo_avistamiento: "Sin especificar",
        municipio_ultimo_avistamiento: "Sin especificar",
        ...(contact && { telefono_contacto: contact }),
        ...(email && { correo_electronico: email }),
        mensaje: message ?? "",
        recaptcha_token: token,
        id_cedula_busqueda: id_cedula_busqueda || ''
      };

      if (state) {
        const found = states.find((st) => st.id === parseInt(state));
        formData.estado_ultimo_avistamiento = found?.descripcion ?? "Sin especificar";
      }

      if (lastSighting) {
        const found = municipalities[state].find((municipality) => municipality.descripcion === lastSighting);
        formData.municipio_ultimo_avistamiento = found?.descripcion ?? "Sin especificar";
      }

      postMailBox(formData).then((_response) => {
        ReactGA.event({
          category: 'Cedulas',
          action: 'Uso de buzon cuidadano',
          label: 'Se esta reportando algun dato de persona desaparecida'
        });

        setName('');
        setContact('');
        setEmail('');
        setMessage('');
        setState(14);
        setLastSighting('');
        handleFormOnSubmit(true);
        setIsValidEmail(true);
        setIsValidContact(true);
        setSex('');
      }).catch((error) => {
        handleFormOnSubmit(false);
      }).finally(() => 
        setLoading(false)
      );
    } else {
      alert('No fue posible enviar el mensaje en este momento, inténtelo nuevamente más tarde.');
      setLoading(false)
    };
  }

  useEffect(() => {
    if (!municipalities[state]) return;
    setLastSighting('')
  }, [state, municipalities])

  return (
    <Box sx={styles.container} onClick={(e) => e.stopPropagation()}>
      {
        isLoading 
          ? <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <CircularProgress />
              <Typography sx={styles.text}>
                Enviando correo ...
              </Typography>
            </Stack>
          : <>
            <Alert 
              type={alertType}
              open={alertOpen}
              title={alertTitle}
              description={alertDescription}
              onClose={() => setAlertOpen(false)}
            />
            {
              onClose &&
              <IconButton 
                sx={{ position: 'absolute', top: 15, right: 15 }}
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            }
            <Typography sx={styles.title}>Buzón ciudadano</Typography>
            <Typography sx={styles.text}>
              <b>¿Tienes información sobre una persona desaparecida?</b> Déjanos un mensaje:
            </Typography>
            <FormControl size="small" sx={styles.formControl}>
              <TextField
                required
                label='Nombre de la persona desaparecida'
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                fullWidth
                size='small'
                sx={styles.input}
              />
            </FormControl>
            <FormControl size="small" sx={styles.formControl}>
              <InputLabel>Sexo de la persona desaparecida</InputLabel>
              <Select
                fullWidth
                displayEmpty
                size='small'
                value={sex}
                onChange={(event) => setSex(event.target.value)}
                sx={{ ...styles.input, borderRadius: 32 }}
                endAdornment={
                  <InputAdornment position="end">
                      {
                          sex &&
                          <Close sx={{ mr: 2, fontSize: '20px', cursor: 'pointer' }} onClick={() => setSex('')} />
                      }
                  </InputAdornment>
                }
              >
                  <MenuItem key={"SE IGNORA"} value={"SE IGNORA"}>
                    Se ignora
                  </MenuItem>
                {typesOfSex && typesOfSex.map((option, index) => (
                  <MenuItem key={`mailbox_sexo_${index}`} value={option.descripcion}>
                    {option.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={styles.formControl}>
              <InputLabel>Estado donde se vió</InputLabel>
              <Select
                required
                fullWidth
                displayEmpty
                size='small'
                value={state}
                onChange={(event) => {
                  setState(event.target.value)
                  loadMunicipalities(event.target.value)
                }}
                sx={{ ...styles.input, borderRadius: 32 }}
              >
                {states && states.map((option, index) => (
                  <MenuItem key={`mailbox_municipio_${index}`} value={option.id}>
                    {option.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={styles.formControl}>
              <Autocomplete
                disablePortal
                fullWidth
                size='small'
                value={lastSighting}
                onChange={(event, newValue) => {
                  setLastSighting(newValue)
                }}
                isOptionEqualToValue={(option) => option !== ""}
                options={municipalities[state]?.map((option) => option.descripcion) ?? []}
                sx={{ ...styles.input, borderRadius: 32 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Municipio donde se vió"
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl size="small" sx={styles.formControl}>
              <TextField
                fullWidth
                label='Teléfono de contacto'
                error={!isValidContact}
                helperText={!isValidContact ? 'Numero de contacto no válido' : ''}
                value={contact}
                onChange={handleContactChange}
                size='small'
                sx={styles.input}
              />
            </FormControl>
            
            <FormControl size="small" sx={styles.formControl}>
              <TextField
                fullWidth
                error={!isValidEmail}
                label='Correo electrónico'
                helperText={!isValidEmail ? 'Correo electrónico no válido' : ''}
                value={email}
                onChange={handleEmailChange}
                size='small'
                sx={styles.input}
              />
            </FormControl>
            
            <FormControl size="small" sx={styles.formControl}>
              <TextField
                required
                fullWidth
                label='Tu mensaje'
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
                multiline
                rows={3}
                size='small'
                sx={styles.input}
                InputProps={{ sx: { px: 2 } }}
              />
            </FormControl>
            
            <Box sx={styles.recaptchaContainer}>
              <GoogleReCaptcha
                onVerify={verifyRecaptchaCallback}
              />
              <Button
                disabled={!isValidEmail || !isValidContact || !name || !message}
                variant='contained'
                size='large'
                onClick={handleSubmit}
                sx={{
                  ...styles.button,
                  '&.MuiButtonBase-root:hover': {
                    backgroundColor: '#DE5E60',
                  },
                }}
              >
                <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Enviar
                </Typography>
              </Button>
            </Box>
          </>
      }
    </Box>
  );
};

export default CitizenMilBoxForm;
