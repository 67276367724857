import axios from "axios";

const apiUrl = process.env.REACT_APP_BACKEND_API_HOST;

const getMapData = async () => {
    try {
      return await axios.get(apiUrl + `version_publica/datos_para_home/`);
    } catch (e) {
      throw e;
    }
};

const mapService = {
    getMapData
};

export default mapService;