const styles = {

  container: {
    backgroundColor: 'pink',
    width: '536px',
    display: 'inline-flex'
  },
  tag: {
    backgroundColor: '#1CA37A',
    width: {xs: 187, md: 165},
    borderRadius: '0px 10px 10px 0px',
    display: 'inline-flex',
    alignItems: 'center',
    color: '#FFFFFF',
    flexWrap: 'wrap',
    padding: '8px'
  },
  tagText: {
    fontFamily: 'Geologica Black',
    letterSpacing: '0.8px',
    fontSize: '18px',
    lineHeight: '20px'
  },
  tagConVida: {
    backgroundColor: '#1CA37A'
  },
  tagSinVida: {
    backgroundColor: '#626262'
  },
  tagDesaparecido: {
    backgroundColor: '#FE9523'
  },
  nameBox: {
    position: 'relative',
    padding: "10px 5px", 
    borderRadius: '0px 10px 0px 0px',
    overflowX: 'hidden',
    background: '#FAFAFA'
  },
  watermark: {
    maxWidth: '330px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    zIndex: 1,
    opacity: 0.8
  },
  tableStyles: {
    position: 'relative',
    width: '100%',
    boxShadow: '0px 5px 24px #4A4B4B1A',
    borderRadius: '25px',
    "@media (maxWidth: 767px)": {
      width: '95% !important',
      margin: 'auto'
    },
    pb: '10px'
  },
  tableRow: { verticalAlign: 'top' },
  celStyle: {
    padding: '0px !important',
    border: '0px'
  },
  celStyleW1: {
    padding: '0px !important',
    border: '0px',
    width: '1px',
    whiteSpace: 'nowrap'
  },
  table: {
    boxShadow: 0
  },
  row: {
    display: 'flex',
  },
  label: {
    textAlign: 'left',
    font: 'regular',
    letterSpacing: '0px',
    color: '#7B8991',
    textTransform: 'uppercase',
    marginRight: '5px',
    verticalAlign: 'top',
    fontSize: '8px'
  },
  valueTextName: {
    textAlign: 'left',
    font: 'bold',
    letterSpacing: '0px',
    color: '#3B4348',
    fontSize: '8px',
    marginRight: '5px',
  },
  valueText: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#3B4348',
    fontSize: '8px',
    fontFamily: 'Geologica Regular !important',
    fontWeight: 'bold',
    px: '2px'
  },
  footerCard: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  card1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    background: '#FDC503',
    padding: 1,
    width: '160px'
  },
  card2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    background: '#02ADD8',
    padding: 1
  },
  card3: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    pt: { xs: 1, sm: 0 },
  },
  titleWhite: {
    textAlign: 'left',
    fontSize: '12px',
    fontFamily: 'Nutmeg Ultra Black !important',
    letterSpacing: '0px',
    color: '#665F4A',
    opacity: 1,
  },
  subTitle1: {
    textAlign: 'left',
    fontSize: '12px',
    fontFamily: 'Nutmeg Ultra Black !important',
    letterSpacing: '0px',
    color: '#FFFFFF',
    backgroundColor: '#02ADD8',
    width: '100px',
    px: '2px'
  },
  subTitle2: {
    textAlign: 'left',
    fontSize: '10px',
    fontFamily: 'Nutmeg Bold,  !important',
    letterSpacing: '0px',
    color: '#FFFFFF',
    backgroundColor: '#02ADD8',
    px: '2px'
  },
  whitePlaceholder: {
    textAlign: 'left',
    font: 'normal normal normal 6px/9px Montserrat',
    fontFamily: 'Montserrat Regular !important',
    letterSpacing: '0px',
    color: '#665F4A',
    mt: '2px'
  },
  phoneText: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 1
  },
  text: {
    textAlign: 'left',
    fontSize: '12px',
    fontFamily: 'Nutmeg Bold, !important',
    letterSpacing: '0px',
    color: '#FFFFFF',
  },
  emailText: {
    textAlign: 'left',
    letterSpacing: '0px',
    fontSize: '8px',
    color: '#FFFFFF',
    wordBreak: 'break-all'
  },
  img: {
    margin: 'auto',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '0px auto'
  },
  actionItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: { xs: '100%', sm: 'auto' }
  },
  actionImg: {
    width: '42px',
    height: '42px',
    marginRight: '11px'
  },
  actionText: {
    textAlign: 'left',
    fontFamily: 'Geologica Regular !important',
    fontSize: '16px',
    letterSpacing: '0px',
    color: '#7B8991',
    opacity: 1,
    textTransform: 'capitalize'
  },
  qrCode: {
    position: 'absolute',
    right: 12,
    top: 2,
    width: 50,
    height: 50
  },
  imgPerson: {
    minWidth: '155px',
    "@media (maxWidth: 767px)": {
      minWidth: '160px',
    },
  }
}

export default styles;
