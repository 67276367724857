import { useEffect, useRef } from "react";
import { axisBottom, axisLeft, scaleBand, scaleLinear, select } from "d3";
import { Box, Typography, Grid } from "@mui/material";
import styles from "./styles";
import { colorGrafica4 } from "../../../utils/constants";
import * as d3 from 'd3';

function AxisBottom({ scale, transform }) {
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            select(ref.current).call(axisBottom(scale));
        }
    }, [scale]);

    return <g ref={ref} transform={transform} style={{ fontSize: '13px', fontWeight: 400, color: 'grey' }}/>;
}

function AxisLeft({ scale }) {
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            select(ref.current).call(axisLeft(scale));
        }
    }, [scale]);

    return <g ref={ref} />;
}

function Bars({ data, height, scaleX, scaleY }) {
    return (
        <>
            {data.map(({ label, values }, i) => (
                <g key={`bar-group-${label}`} className={`bar-group-${i}`}>
                    {values.map((value, index) => (
                        <rect
                            key={`bar-${label}-${index}`}
                            x={scaleX(label) + scaleX.bandwidth() * (index / 5)}
                            y={scaleY(value)}
                            width={18}
                            height={height - scaleY(value)}
                            fill={colorGrafica4[index]}
                        />
                    ))}
                </g>
            ))}
        </>
    );
}

function Legend({ colors }) {
    return (
        <Grid container spacing={0}>
            <Box sx={styles.legendContainer}>
                {colors.map((color, index) => (
                    <Grid key={index} item xs={6} sx={styles.gridBox}>
                        <Box key={index} sx={styles.item}>
                            <Box sx={{ ...styles.colorCircle, backgroundColor: color.color }} />
                            <span style={styles.textLegend}>{color.label}</span>
                        </Box>
                    </Grid>
                ))}
            </Box>
        </Grid>
    );
}

function CustomBarChart({ title = null, legend, data }) {
    const svgRef = useRef()
    const isMobile = window.innerWidth <= 768;
    const margin = { top: 5, right: isMobile ? 20 : 100, bottom: 20, left: isMobile ? 50 : 35 };
    const width = isMobile ? 400 : 525;
    const height = 150;
    const padding = isMobile ? 0.15 : 0.38;
    const align = isMobile ? 0.15 : 0.20;

    const scaleX = scaleBand()
        .domain(data.map(({ label }) => label))
        .range([0, width])
        .align(align)
        .padding(padding)
        .paddingOuter(1);
    const scaleY = scaleLinear()
        .domain([0, Math.max(...data.flatMap(({ values }) => values))])
        .nice()
        .range([height, 0]);

    useEffect(() => {
        const svg = select(svgRef.current);
    
        svg.selectAll(`.bar-group-0, .bar-group-1`)
        .data(data);
    
        let tooltipMap = d3.select('#tooltip')
            .append("div")
            .attr("id", "tooltip")
            .style("position", "absolute")
            .style("visibility", "hidden")
            .style("background-color", "white")
            .style("border-radius", "5px")
            .style("box-shadow", "1px 1px 1px 1px #eeeff5")
            .style("padding", "4px");
    
            const drawToolTip = (d, tooltipMap) => {
            tooltipMap.html(`
                        <div style='font-size: 12px; line-height: 15px;'>
                        <table>
                        <tr>
                          <td><span style='height: 18px; width: 18px; background-color: ${colorGrafica4[0]}; border-radius: 50%; display: inline-block;'></span></td>
                          <td><span>${d?.values?.[0] || 0}</span><br><span>Mujeres</span></td>
                        </tr>
                        <tr>
                          <td><span style='height: 18px; width: 18px; background-color: ${colorGrafica4[1]}; border-radius: 50%; display: inline-block;'></span></td>
                          <td><span>${d?.values?.[1] || 0}</span><br><span>Hombres</span></td>
                        </tr>
                        <tr>
                          <td><span style='height: 18px; width: 18px; background-color: ${colorGrafica4[2]}; border-radius: 50%; display: inline-block;'></span></td>
                          <td><span>${d?.values?.[2] || 0}</span><br><span>Intersexual</span></td>
                        </tr>
                        <tr>
                          <td><span style='height: 18px; width: 18px; background-color: ${colorGrafica4[3]}; border-radius: 50%; display: inline-block;'></span></td>
                          <td><span>${d?.values?.[3] || 0}</span><br><span>Se ignora</span></td>
                        </tr>
                        </table>
                        </div>
                        `);
            return tooltipMap };
    
            d3.selectAll(`.bar-group-0, .bar-group-1`)
            .on('mouseover', (e, d) => {
                tooltipMap.style("visibility", "visible")
                drawToolTip(d, tooltipMap);
            })
            .on('mousemove', function(e, d) {
                tooltipMap.style('left', (e.pageX+10) + 'px').style('top', (e.pageY+5) + 'px')
            })
            .on('mouseout', (e, d) => {
                tooltipMap.style("visibility", "hidden")
            });
    
        }, [data])

    return (
        <Box sx={styles.Customcontainer}>
        {title != null && (
            <Typography sx={styles.title}>{title}</Typography>
        )}
            <svg
                ref={svgRef}
                width={isMobile ? '100%' : '80%'}
                height={height + margin.top + margin.bottom}
            >
                <g transform={`translate(${margin.left}, ${margin.top})`} style={{ color: '#717177', overflowX: 'hidden', fontSize: '20px'}}>
                    <AxisBottom scale={scaleX} transform={`translate(-10, ${height})`} />
                    <AxisLeft scale={scaleY} />
                    <Bars data={data} height={height} scaleX={scaleX} scaleY={scaleY} />
                </g>
            </svg>
            <Legend colors={legend} />
        </Box>
    );
}

export default CustomBarChart;
