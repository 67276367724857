import React, { useEffect } from "react";
import { useCedulasContext } from "Context/CedulasPageContext";
import { useGlobalContext } from "Context/GlobalContext.js"; 
import LightTooltip from 'Utils/LightTooltip.jsx'
import CloseIconSelect from 'Utils/CloseIconSelect.jsx';
import styles from './styles.js';
import { styled } from '@mui/system';
import { InputLabel, MenuItem, Select, Fade, FormControl } from '@mui/material';

const StateFilter = () => {
    const { isSmallScreen } = useGlobalContext()
    const { 
        statesWithCedulas, isLoadingCedulas, handleStateMunicipalityWithCedulas,
        selectedState, handleSelectedState, handleSelectedMunicipality
    } = useCedulasContext();
    
    const PickerContainer = styled(FormControl)(() => (styles.formControl));

    const handleCleanFilters = (_event) => {
        handleSelectedMunicipality("")
        handleSelectedState("")
    }

    useEffect(() => {
        handleStateMunicipalityWithCedulas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PickerContainer size="small" sx={{ width: '100%' }}>
            <InputLabel id="seleccionar-estado-filtro">
                Estado de desaparición
            </InputLabel>
            <LightTooltip 
                title="
                    Estados donde se han reportado desapariciones, 
                    según se registra en las cédulas de búsqueda emitidas por la Comisión Local de Búsqueda 
                    del Estado de Jalisco. 
                " 
                placement={isSmallScreen ? "top" : "right"} 
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                arrow
            >
                <Select
                    labelId="seleccionar-estado-filtro"
                    id="seleccionar-estado-filtro"
                    value={selectedState}
                    label="Estado de desaparición"
                    onChange={(event) => {
                        handleSelectedMunicipality('')
                        handleSelectedState(event.target.value)
                    }}
                    endAdornment={<CloseIconSelect dataCondition={selectedState} dataFuntion={handleCleanFilters}/>}
                > {
                    !isLoadingCedulas.state && statesWithCedulas.map((state) => (
                        <MenuItem key={state} value={state}>{state}</MenuItem>
                    ))
                }</Select>
            </LightTooltip>
        </PickerContainer>
    )
}

export default StateFilter;