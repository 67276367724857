import React from 'react';
import styles from './styles.js';
import { Box, Typography, Container, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { LEYES_PROTOCOLOS_LOGO, LEY_CARD_IMAGE } from '../../../assets/index.js';
import { LAWS_AND_PROTOCOLS_LINK } from '../../../utils/constants.js';

const LeyesBanner = () => {
    return (
        <Container maxWidth="false" disableGutters sx={styles.container}>
            <Grid container spacing={2} sx={styles.grid}>
                <Grid item xs={12} sx={{ ...styles.gridBox }}>
                    <Box>
                        <img src={LEYES_PROTOCOLOS_LOGO} alt="Banner leyes y protocolos" style={styles.img} />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={styles.gridBox}>
                    <Typography sx={styles.titleText}>Leyes y protocolos</Typography>
                </Grid>
                {LAWS_AND_PROTOCOLS_LINK.map((card, index) => (
                    <Grid item key={`protocolos_card_${index}`} xs={12} sm={6} md={4} sx={{ ...styles.gridBox, justifyContent: 'space-evenly', flexDirection: { md: 'row' }, mb: { xs: 0, md: 1 } }}>
                        <Card  sx={styles.card}>
                            <CardMedia sx={styles.imageCard} image={LEY_CARD_IMAGE} alt="Icono ley" />
                            <CardContent sx={styles.cardContent}>
                                <a href={card.link} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#1C5866' }}>
                                    <Typography sx={styles.cardText}>{card.title}</Typography>
                                </a>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default LeyesBanner;
