import React from 'react';
import styles from './styles.js';
import { Box, Typography, Container, Grid } from '@mui/material';
import { QUE_ES_LOGO, VIDEO_PLACEHOLDER } from '../../../assets/index.js';
import BaseButton from '../BaseButton.js';
import { useNavigate } from 'react-router-dom';

const ConoceMasBanner = () => {
    const navigate = useNavigate();

    const navigateToEstadisticas = () => {
      navigate('/conoce-mas');
    };

    return (
        <Box component="main">
            <Container maxWidth="xl" sx={{ px: 0, my: { xs: 4, sm: 6 } }}>
                <Grid container spacing={0} sx={styles.grid}>
                    <Grid item xs={12} md={6} sx={styles.gridBox}>
                        <Box>
                            <img src={QUE_ES_LOGO} alt="Que_es_logo" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{...styles.gridBox, justifyContent: {xs: 'center', md: 'left'}}}>
                        <Box sx={{...styles.box, mx: {xs: 2, md: 0}, width: {xs: '96%', md: '85%', xl: '80%'}, justifyContent: 'center' }}>
                            <Typography sx={{...styles.titleText, color: '#1C5866', fontSize: {xs: 40, md: 58}}}>¿Qué es el registro Estatal de Personas Desaparecidas?</Typography>
                            <Typography sx={{...styles.subtitleText, color: '#1C5866', mt: 3, marginBottom: {xs: 3}}}>Es un sistema informático que concentra la información de las personas que han sido reportadas como desaparecidas ante las autoridades del estado de Jalisco. <br></br><b>Su administración y operación está a cargo de la Comisión de Búsqueda de Personas del Estado de Jalisco.</b></Typography>
                            <BaseButton onClick={navigateToEstadisticas}>Conoce más</BaseButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{...styles.gridBox, mt: 6 }}>
                        {false && <Box>
                            <img src={VIDEO_PLACEHOLDER} alt="Cedula Personas" style={styles.imgVideo}/>
                        </Box>}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ConoceMasBanner;
