import { keyframes } from '@emotion/react';

const transformEffect = keyframes`
    0% {
        width: 50px;
        padding-left: 0px;
        padding-right: 0px;
    }
    100% {
        width: 250px;
    }
`;

const transitionEffectSmall = keyframes`
    0% {
        width: 250px;
    }
    100% {
        width: 50px;
        padding-left: 0px;
        padding-right: 0px;
    }
`;

const transitionEffectIcon = keyframes`
    0% {
        transform: translateX(15px);
    }
    100% {
        transform: translateX(0px);
    }
`;

const transitionEffectIconSmall = keyframes`
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(15px);
    }
`;

const transitionEffectText = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const transitionEffectTextSmall = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;



const styles = {
    animatedButton: {
        animation: `${transformEffect} 0.2s ease-in-out 1 forwards`,
    },
    animatedButtonSmall: {
        animation: `${transitionEffectSmall} 0.2s ease-in-out 1 forwards`,
    },
    animatedButtonIcon: {
        animation: `${transitionEffectIcon} 0.2s ease-in-out 1 forwards`,
    },
    animatedButtonIconSmall: {
        animation: `${transitionEffectIconSmall} 0.2s ease-in-out 1 forwards`,
    },
    animatedButtonText: {
        animation: `${transitionEffectText} 0.2s ease-in-out 1 forwards`,
    },
    animatedButtonTextSmall: {
        animation: `${transitionEffectTextSmall} 0.2s ease-in-out 1 forwards`,
    }
}

export default styles;