const styles = {

  container: {
    width: '100%',
    px: '5rem',
    py: '3rem',
    display: 'grid',
    "@media (max-width: 767px)": {
      padding: '.25rem',
    }
  },

  svgContainer: {
    width: '100%',
    display: 'flex',
    paddingTop: '.45rem',
    justifyContent: 'flex-start',
    height: "530px",
    paddingLeft: '25px',
    margin: 'auto',
    overflowX: "scroll",
  },

  title: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#1C5866',
    opacity: 1,
    fontSize: '44px',
  },
  legendContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: '20px',
    marginLeft: '20px',
    width: '80%'
  },
  item: {
    display: "flex",
    alignItems: "center",
    marginBottom: {xs: 1, md:0}
  },
  colorBox: {
    width: "24px",
    height: "24px",
    marginRight: "8px",
  },
  textColor: {
    color: '#717177',
    width: '170px'
  },
  legendx: {
    marginTop: '1rem',
    textAlign: 'center',
    letterSpacing: '0px',
    fontSize: '14px',
    fontFamily: 'Geologica Regular !important',
  },
  legendy: {
    position: 'absolute',
    right: '42px',
    textAlign: 'center',
    letterSpacing: '0px',
    fontSize: '14px',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    height: "400px",
    justifyContent: 'center',
    width: '0%',
    fontFamily: 'Geologica Regular !important',
  }
}

export default styles;
