import React from "react";
import { useGlobalContext } from "Context/GlobalContext.js"; 
import { useCedulasContext } from "Context/CedulasPageContext";
import LightTooltip from 'Utils/LightTooltip.jsx'
import styles from './styles.js';
import { 
    FormControl, Autocomplete, TextField,    
    Fade
} from '@mui/material';

const MunicipalityFilter = () => {
    const { isSmallScreen } = useGlobalContext()
    const { 
        isLoadingCedulas,
        municipalitiesWithCedulas, selectedMunicipality, handleSelectedMunicipality
    } = useCedulasContext();

    return (
        <FormControl size="small" sx={{ ...styles.formControl, width: '100%' }}>
            <Autocomplete
                disablePortal
                fullWidth
                size='small'
                value={selectedMunicipality}
                onChange={(_event, newValue) => handleSelectedMunicipality(newValue)}
                options={municipalitiesWithCedulas || []}
                isOptionEqualToValue={(option) => option !== ""}
                getOptionLabel={(option) => option || ""}
                noOptionsText={'Sin opciones'}
                sx={{ ...styles.input, borderRadius: 32 }}
                loading={isLoadingCedulas.state}
                loadingText={"Obteniendo resultados..."}
                renderInput={(params) => (
                    <LightTooltip 
                        title="
                            Municipios donde se han reportado desapariciones, 
                            según se registra en las cédulas de búsqueda emitidas por 
                            la Comisión Local de Búsqueda del Estado de Jalisco.
                        "
                        placement={isSmallScreen ? "top" : "right"} 
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow
                    >
                        <TextField 
                            {...params} 
                            label="Municipio de desaparición" 
                        />
                    </LightTooltip>
                )}
            />
        </FormControl>
               
    )
}

export default MunicipalityFilter;