import React, { useRef, useEffect, useState } from 'react';
import styles from './styles.js';
import { Box, Container, Grid } from '@mui/material';
import * as d3 from 'd3'
import { MAN_ICON, WOMAN_ICON, INTERSEXUAL_ICON, SIN_GENERO_ICON } from '../../../assets/index.js';
import { isMobileMap, isMobile } from '../../../utils/constants.js';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ChartMap = () => {
    const svgRef = useRef();
    const width = isMobileMap ? (window.innerWidth - 90) : 652;
    const height = isMobileMap ? (window.innerWidth - 90) : 670;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [municipio, setMunicipio] = useState('');
    const [selectData, setSelectData] = useState([]);
    const [open, SetOpen] = useState(false);

    const drawToolTipMap = (d, tooltipMap) => {
        tooltipMap.html(`<span style='font-size: 16px; font-weight: bold;'>${d?.properties?.NAME_2}</span></br>
                    <div style='color: #1C5866; font-family: "Geologica Regular"; font-size: 16px;'>
                    <div style='position: relative; background: #c0e4d9; border-radius: 50%; width: 16px; height: 16px; display: inline-block; top: 2px;'></div>
                    <b>${d?.properties?.values?.totalLocalizado || 0}</b><b style='margin-left: 10px;'>Personas Localizadas</b></div>
                    <hr style='border: 0; height: 0; border-top: 1px solid rgba(0, 0, 0, 0.1); border-bottom: 1px solid rgba(255, 255, 255, 0.1);'></hr>
                    <div style='font-size: 12px; line-height: 15px;'>
                    <table>
                    <tr>
                    <td><span style='height: 8px; width: 8px; background-color: #1C5866; border-radius: 50%; display: inline-block; margin-left: 5px'></span></td>
                    <td style='color: #1C5866;'><b style='font-weight: 900;'>${d?.properties?.values?.totalConVida || 0}</b></br>Con Vida</td>
                    <td><span style='height: 8px; width: 8px; background-color: #de5e60; border-radius: 50%; display: inline-block; margin-left: 5px'></span></td>
                    <td style='color: red;'><b>${d?.properties?.values?.totalSinVida || 0}</b></br>Sin Vida</td>
                    </tr>
                    <tr>
                        <td><img src=${WOMAN_ICON} alt="Mujer icono" style='width: 75%;' /></td>
                        <td><b>${d?.properties?.values?.mujerLocalizada || 0}</b></br>Mujeres</td>
                        <td><img src=${MAN_ICON} alt="Hombre icono" style='width: 65%;' /></td>
                        <td><b>${d?.properties?.values?.hombreLocalizado || 0}</b></br>Hombres</td>
                    </tr>
                    <tr>
                        <td><img src=${INTERSEXUAL_ICON} alt="Intersexual icono" style='width: 70%;' /></td>
                        <td><b>${d?.properties?.values?.intersexualLocalizado || 0}</b></br>Intersexuales</td>
                        <td><img src=${SIN_GENERO_ICON} alt="Sin genero icono" style='width: 70%;' /></td>
                        <td><b>${d?.properties?.values?.seIgnoraLocalizada || 0}</b></br>Se ignora</td>
                    </tr>
                    </table>
                    </div>
                    <hr style='border: 0; height: 0; border-top: 1px solid rgba(0, 0, 0, 0.1); border-bottom: 1px solid rgba(255, 255, 255, 0.3);'></hr>
                    <div style='color: #de5e60; font-family: "Geologica Regular"; font-size: 16px; margin-bottom: 10px;'>
                    <div style='position: relative; background: #f8d4d4; border-radius: 50%; width: 16px; height: 16px; display: inline-block; top: 2px;'></div>
                    <b>${d?.properties?.values?.totalDesaparecido || 0}</b><b style='margin-left: 10px;'>Personas desaparecidas</b></div>
                    <div style='font-size: 12px; line-height: 15px;'>
                    <table>
                    <tr>
                        <td><img src=${WOMAN_ICON} alt="Mujer icono" style='width: 75%;' /></td>
                        <td><b>${d?.properties?.values?.mujerDesaparecido || 0}</b></br>Mujeres</td>
                        <td><img src=${MAN_ICON} alt="Hombre icono" style='width: 65%;' /></td>
                        <td><b>${d?.properties?.values?.hombreDesaparecido || 0}</b></br>Hombres</td>
                    </tr>
                    <tr>
                        <td><img src=${INTERSEXUAL_ICON} alt="Intersexual icono" style='width: 70%;' /></td>
                        <td><b>${d?.properties?.values?.intersexualDesaparecido || 0}</b></br>Intersexuales</td>
                        <td><img src=${SIN_GENERO_ICON} alt="Sin genero icono" style='width: 70%;' /></td>
                        <td><b>${d?.properties?.values?.seIgnoraDesaparecido || 0}</b></br>Se ignora</td>
                    </tr>
                    </table>
                    </div>
                    `);
        return tooltipMap;
      };

    useEffect(() => {
        const scale = isMobileMap ? 4000 : 8600;
        const center = isMobileMap ? [-98.85, 19.6] : [-102.61, 21.3];
        const svg = d3.select(svgRef.current);
        const apiUrl = process.env.REACT_APP_BACKEND_API_HOST;

        let projection = d3.geoMercator()
        .center(center)
        .scale(scale);
        
        let data = new Map();
        let dataValues = new Map();

        Promise.all([
            d3.json('./jalisco.json'),
            d3.json(apiUrl + 'version_publica/datos_para_mapa/')
            ]).then(function([loadData, loadData2]){
                let topo = loadData;
                setSelectData(topo.features);
                let mapData = loadData2;
                let hombreLocalizado = 0;
                let hombreDesaparecido = 0;
                let intersexualLocalizado = 0;
                let intersexualDesaparecido = 0;
                let mujerLocalizada = 0;
                let mujerDesaparecido = 0;
                let seIgnoraLocalizada = 0;
                let seIgnoraDesaparecido = 0;
                let total = 0;
                let scale = [];

                Object.keys(mapData).forEach(municipio => {
                    if (mapData[municipio].hasOwnProperty('HOMBRE')) {
                        if (mapData[municipio].HOMBRE["PERSONA LOCALIZADA"]) {
                            hombreLocalizado += mapData[municipio].HOMBRE["PERSONA LOCALIZADA"];
                            total += mapData[municipio].HOMBRE["PERSONA LOCALIZADA"];
                        }
                        if (mapData[municipio].HOMBRE["PERSONA DESAPARECIDA"]) {
                            hombreDesaparecido += mapData[municipio].HOMBRE["PERSONA DESAPARECIDA"];
                            total += mapData[municipio].HOMBRE["PERSONA DESAPARECIDA"];
                        }
                    }
                    if (mapData[municipio].hasOwnProperty('INTERSEXUAL')) {
                        if (mapData[municipio].INTERSEXUAL["PERSONA LOCALIZADA"]) {
                            intersexualLocalizado += mapData[municipio].INTERSEXUAL["PERSONA LOCALIZADA"];
                            total += mapData[municipio].INTERSEXUAL["PERSONA LOCALIZADA"];
                        }
                        if (mapData[municipio].INTERSEXUAL["PERSONA DESAPARECIDA"]) {
                            intersexualDesaparecido += mapData[municipio].INTERSEXUAL["PERSONA DESAPARECIDA"];
                            total += mapData[municipio].INTERSEXUAL["PERSONA DESAPARECIDA"];
                        }
                    }
                    if (mapData[municipio].hasOwnProperty('MUJER')) {
                        if (mapData[municipio].MUJER["PERSONA LOCALIZADA"]) {
                            mujerLocalizada += mapData[municipio].MUJER["PERSONA LOCALIZADA"];
                            total += mapData[municipio].MUJER["PERSONA LOCALIZADA"];
                        }
                        if (mapData[municipio].MUJER["PERSONA DESAPARECIDA"]) {
                            mujerDesaparecido += mapData[municipio].MUJER["PERSONA DESAPARECIDA"];
                            total += mapData[municipio].MUJER["PERSONA DESAPARECIDA"];
                        }
                    }
                    if (mapData[municipio].hasOwnProperty('SE IGNORA')) {
                        if (mapData[municipio]["SE IGNORA"]["PERSONA LOCALIZADA"]) {
                            seIgnoraLocalizada += mapData[municipio]["SE IGNORA"]["PERSONA LOCALIZADA"];
                            total += mapData[municipio]["SE IGNORA"]["PERSONA LOCALIZADA"];
                        }
                        if (mapData[municipio]["SE IGNORA"]["PERSONA DESAPARECIDA"]) {
                            seIgnoraDesaparecido += mapData[municipio]["SE IGNORA"]["PERSONA DESAPARECIDA"];
                            total += mapData[municipio]["SE IGNORA"]["PERSONA DESAPARECIDA"];
                        }
                    }
                    data.set(mapData[municipio].clave_geoestadistica_municipal, total);
                    dataValues.set(mapData[municipio].clave_geoestadistica_municipal, 
                        {hombreLocalizado: hombreLocalizado, 
                        hombreDesaparecido: hombreDesaparecido,
                        intersexualLocalizado: intersexualLocalizado,
                        intersexualDesaparecido: intersexualDesaparecido,
                        mujerLocalizada: mujerLocalizada,
                        mujerDesaparecido: mujerDesaparecido,
                        seIgnoraLocalizada: seIgnoraLocalizada,
                        seIgnoraDesaparecido: seIgnoraDesaparecido,
                        totalLocalizado: mapData[municipio]["TOTAL CASOS"]["PERSONA LOCALIZADA"], 
                        totalDesaparecido: mapData[municipio]["TOTAL CASOS"]["PERSONA DESAPARECIDA"],
                        totalConVida: mapData[municipio]["PERSONAS LOCALIZADAS"]["CON VIDA"],
                        totalSinVida: mapData[municipio]["PERSONAS LOCALIZADAS"]["SIN VIDA"],
                    })
                    scale.push(total);
                    total = 0;
                    hombreLocalizado = 0;
                    hombreDesaparecido = 0;
                    intersexualLocalizado = 0;
                    intersexualDesaparecido = 0;
                    mujerLocalizada = 0;
                    mujerDesaparecido = 0;
                    seIgnoraLocalizada = 0;
                    seIgnoraDesaparecido = 0;
                });
                data.set(250, +'0');

                let colorScale = d3.scaleLinear()
                    .domain([0, d3.max(scale)])
                    .range(["#e9edef", "#454747"]);
                    
                Object.keys(topo.features).forEach(path => {
                    topo.features[path].properties.values = dataValues.get(topo.features[path].id) || 0;
                });

                let tooltipMap = d3.select('#tooltip')
                    .append("div")
                    .attr("id", "tooltip")
                    .style("position", "absolute")
                    .style("visibility", "hidden")
                    .style("background-color", "white")
                    .style("border-radius", "14px")
                    .style("box-shadow", "2px 2px 2px 2px #eeeff5")
                    .style("padding", "10px");

                svg.append("g")
                    .selectAll("path")
                    .data(topo.features)
                    .join("path")
                    .attr("d", d3.geoPath()
                        .projection(projection)
                    )
                    .attr("fill", function (d) {
                        if (!d.hasOwnProperty('id')) {
                            d.id = 250;
                        }
                        d.total = data.get(d.id) || 0;
                        return colorScale(d.total);
                    })
                    .style("stroke-opacity", .3)
                    .attr("stroke", "#000000")
                    .on('mouseover', (e, d) => {
                        if (isMobileMap) {
                            tooltipMap.style("visibility", "hidden")
                        } else {
                            tooltipMap.style("visibility", "visible")
                            drawToolTipMap(d, tooltipMap);
                        }
                    })
                    .on('mousemove', function(e, d) {
                        if (isMobile) {
                            if (e.pageX > 550 && e.pageY > 2376) {
                                tooltipMap.style('left', (e.pageX-230) + 'px').style('top', (e.pageY+5) + 'px')
                            } else {
                                tooltipMap.style('left', (e.pageX+10) + 'px').style('top', (e.pageY+5) + 'px')
                            }
                        } else {
                            if (e.pageX > 1200 && e.pageY > 1190) {
                                tooltipMap.style('left', (e.pageX-230) + 'px').style('top', (e.pageY+5) + 'px')
                            } else {
                                tooltipMap.style('left', (e.pageX+10) + 'px').style('top', (e.pageY+5) + 'px')
                            }
                        }
                    })
                    .on('mouseout', (e, d) => {
                        tooltipMap.style("visibility", "hidden")
                    })
                    .on('click', (e, d) => {
                        if (isMobileMap) {
                            setModalData(d);
                            setModalOpen(true);
                        } 
                    })
            })
    }, []);

    const handleChange = (event) => {
        setMunicipio(event.target.value);
        SetOpen(true);
      };

    function ModalMap(props) {
        const { modalOpen, setModalOpen, data = null} = props;

        function handleClose(e, reason) {
            if (reason === `backdropClick`) {
                setModalOpen(false);
                SetOpen(false);
                return false;
            }
            setModalOpen(false);

            return true;
        };
    
        return (
            <Dialog fullWidth maxWidth={'xs'} open={modalOpen} onClose={handleClose} scroll='paper' PaperProps={{ sx: { borderRadius: "1.25rem"}}}>
            <DialogTitle>
              {data?.properties?.NAME_2 ?? "Municipio"}
            </DialogTitle>
                    <DialogContent>
                        <div style={{color: '#1C5866', fontFamily: 'Geologica Regular', fontSize: '16px'}}>
                            <div style={{...styles.circleWithIcon, backgroundColor: '#c0e4d9'}}>
                                <div style={{position: 'absolute', transform: 'rotate(45deg) translate(-50%, -50%)', left: '28%', top: '43%', width: '4px', height: '8px', borderBottom: '1px solid black', borderRight: '1px solid black'}}></div>
                            </div>
                        <b>{data?.properties?.values?.totalLocalizado || 0}</b><b style={{marginLeft: '5px'}}>Personas Localizadas</b>
                        </div>
                        <hr style={styles.hrStyle}></hr>
                        <div style={{fontSize: '12px', lineHeight: '15px'}}>
                            <table>
                            <tbody>
                                <tr>
                                    <td><span style={{...styles.circle, backgroundColor: '#1C5866'}}></span></td>
                                    <td style={{color: '#1C5866'}}><b style={{fontWeight: 900}}>{data?.properties?.values?.totalConVida || 0}</b><br></br>Con Vida</td>
                                    <td><span style={{...styles.circle, backgroundColor: '#de5e60'}}></span></td>
                                    <td style={{color: 'red'}}><b>{data?.properties?.values?.totalSinVida || 0}</b><br></br>Sin Vida</td>
                                </tr>
                                <tr>
                                    <td><img src={WOMAN_ICON} alt="Mujer icono" style={{width: '75%'}} /></td>
                                    <td><b>{data?.properties?.values?.mujerLocalizada || 0}</b><br></br>Mujeres</td>
                                    <td><img src={MAN_ICON} alt="Hombre icono" style={{width: '65%'}} /></td>
                                    <td><b>{data?.properties?.values?.hombreLocalizado || 0}</b><br></br>Hombres</td>
                                </tr>
                                <tr>
                                    <td><img src={INTERSEXUAL_ICON} alt="Intersexual icono" style={styles.iconSize} /></td>
                                    <td><b>{data?.properties?.values?.intersexualLocalizado || 0}</b><br></br>Intersexuales</td>
                                    <td><img src={SIN_GENERO_ICON} alt="Sin genero icono" style={styles.iconSize} /></td>
                                    <td><b>{data?.properties?.values?.seIgnoraLocalizada || 0}</b><br></br>Se ignora</td>
                                </tr>
                            </tbody>
                            </table>
                    </div>
                    <hr style={styles.hrStyle}></hr>
                    <div style={{color: '#de5e60', fontFamily: 'Geologica Regular', fontSize: '16px', marginBottom: '10px'}}>
                        <div style={{...styles.circleWithIcon, backgroundColor: '#f8d4d4'}}>
                            <div style={{fontSize: '18px', fontWeight: 600, position: 'absolute', top: '-40%', left: '8%'}}>&#x2A2F;</div>
                        </div>
                        <b>{data?.properties?.values?.totalDesaparecido || 0}</b><b style={{marginLeft: '5px'}}>Personas desaparecidas</b></div>
                        <div style={{fontSize: '12px', lineHeight: '15px'}}>
                            <table>
                            <tbody>
                                <tr>
                                    <td><img src={WOMAN_ICON} alt="Mujer icono" style={{width: '75%'}} /></td>
                                    <td><b>{data?.properties?.values?.mujerDesaparecido || 0}</b><br></br>Mujeres</td>
                                    <td><img src={MAN_ICON} alt="Hombre icono" style={{width: '65%'}} /></td>
                                    <td><b>{data?.properties?.values?.hombreDesaparecido || 0}</b><br></br>Hombres</td>
                                </tr>
                                <tr>
                                    <td><img src={INTERSEXUAL_ICON} alt="Intersexual icono" style={styles.iconSize} /></td>
                                    <td><b>{data?.properties?.values?.intersexualDesaparecido || 0}</b><br></br>Intersexuales</td>
                                    <td><img src={SIN_GENERO_ICON} alt="Sin genero icono" style={styles.iconSize} /></td>
                                    <td><b>{data?.properties?.values?.seIgnoraDesaparecido || 0}</b><br></br>Se ignora</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </DialogContent>
          </Dialog>
        )
    }

    useEffect(() => {
        if (municipio && open ) {
            setModalData(municipio);
            setModalOpen(true);
        } else {
            setModalOpen(false);
        }
        
    }, [municipio, open]);

    return (
        <Container maxWidth="false" disableGutters sx={{backgroundColor: 'white' }}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    {isMobileMap && (
                        <FormControl fullWidth>
                            <InputLabel>Selecciona un municipio</InputLabel>
                                <Select value={municipio} label="Selecciona un municipio" onChange={handleChange} sx={{borderRadius: '50px'}} >
                                    {selectData.map((data, index) => (
                                        <MenuItem key={index} value={data}>{data.properties.NAME_2}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                    )}
                    <Box>
                        <div id={'tooltip'}></div>
                        <svg ref={svgRef} width={width} height={height} style={styles.imgMap}></svg>
                    </Box>
                </Grid>
            </Grid>
            <ModalMap modalOpen={modalOpen} setModalOpen={setModalOpen} data={modalData} />
        </Container>
    );
};

export default ChartMap;