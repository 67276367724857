import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { LOGO_JAL, PERSONAS_DESAPARECIDAS_LOGO } from '../../../assets';
import styles from './styles.js';
import { useTemplateContext } from 'Context/AppContext'
import { isMobile } from '../../../utils/constants';

function ResponsiveAppBar() {
  const { mobileMenuOpen, setMobileMenuOpen } = useTemplateContext()

  const pages = [
    { title: 'Inicio', path: '/' },
    { title: 'Estadísticas', path: '/estadisticas' },
    { title: 'Cédulas de Búsqueda', path: '/cedulas-de-busqueda' },
    { title: 'Conoce Más', path: '/conoce-mas' },
  ];

  return (
    <AppBar position="fixed" sx={styles.customAppBar} >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={styles.container}>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <img src={PERSONAS_DESAPARECIDAS_LOGO} style={styles.img} alt='Personas Desaparecidas Logo' />
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'contents'} }}>
            {pages.map((page, index) => (
              <Link key={`menuitem_${index}`} style={styles.text} to={page.path}
                onClick={() => {
                  setMobileMenuOpen(false)
                  window.scrollTo(0, 0)
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = '#DE5E60';
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = 'white';
                }}
              >{page.title}</Link>
            ))}
          </Box>
          { 
            !isMobile &&
            <Box sx={{ flexGrow: 0, display: { xs: 'none', sm: 'flex'}, ml: 4 }}>
              <img src={LOGO_JAL} style={{...styles.img}} width={50} alt='Jalisco Logo'/>
            </Box>
          }
          {
            isMobile &&
            <MenuIcon 
              sx={{ color: 'white', fontSize: '40px', marginRight: '10px', cursor: 'pointer' }}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)} 
            />
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
